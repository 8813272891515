import React, { } from "react"
import './HandwritingNumberCircle.scss';
import { childClassClosure, ClassableProps, baseClassAnd } from "@jcharlesworthuk/your-mum-core/dist/functions";
import DrawnCircleSvg from '@jcharlesworthuk/your-mum-frontend/svg/DrawnCircleSvg';

const baseClass = 'handwriting-number-circle';
const childClass = childClassClosure(baseClass);

type Props = ClassableProps & {
    number: number
}

const HandwritingNumberCircle: React.FunctionComponent<Props> = (props: Props) => {

    return <div className={baseClassAnd(baseClass, props)}>
        <span className={childClass('number')}>{props.number}</span>
        <DrawnCircleSvg />
    </div>
}

export default HandwritingNumberCircle;