import { Metric } from "./Metric";
import { LikertMetricResultDto } from "@Dtos";
import { DiscreteLikertResult } from "./DiscreteLikertResult";
import { LikertResult } from "./LikertResult";

export class LikertMetricResult extends LikertResult {
    constructor(public metric: Metric, counts: {[score: number]: number}) {
        super(counts);
    }

    get positiveDivergence() {
        return (this.counts[2] + this.counts[1] + (this.counts[0] / 2));
    }
    get negativeDivergence() {
        return (this.counts[-2] + this.counts[-1] + (this.counts[0] / 2));
    }

    get divergenceFromZero() {
        return (this.negativeDivergence > this.positiveDivergence ? this.negativeDivergence : this.positiveDivergence) * 2;
    }

    static fromDto(dto: LikertMetricResultDto, metric: Metric) {
        return new LikertMetricResult(metric, dto.counts);
    }
}
