import React from "react"
import "./_CommonShared.scss";
import "./ArticleListPage.scss";
import { graphql, Link } from "gatsby"
import { FileConnection, Site } from "@GraphQLModels";
import { childClassClosureMultiple, childClassClosure } from "@jcharlesworthuk/your-mum-core/dist/functions";
import MarketingSiteHeader from "@StaticQueryComponents/MarketingSiteHeader";
import MarketingSiteFooter from "@StaticQueryComponents/MarketingSiteFooter";
import ArticleList from "@jcharlesworthuk/your-mum-frontend/components/lists/ArticleList";
import PageMeta from "@StaticQueryComponents/PageMeta";
import { mapMdxToArticle, mapMetadataToSocialSites } from "@GraphQLMappers";
import ArticleAsideBox from "../components/marketing/ArticleAsideBox";
import LatestArticles from "@StaticQueryComponents/LatestArticles";

const baseClass = 'article-list-page';
const childClass = childClassClosure(baseClass);

export type Props = {
  pageContext: {
  };
  data: {
    site: Site
  }
}

const ArticleListPage: React.FunctionComponent<Props> = (props: Props) => {
  return (
    <div className={baseClass}>
            <PageMeta
                title='Our Blog'
                route='/blog'>
            </PageMeta>
        <MarketingSiteHeader className={childClass('header')} />
      <div className={childClass('hero')}>
        <div className={childClass('container')}>
          <h1 className={childClass('hero-title')}>Latest Articles from Acorde</h1>
        </div>
      </div>
      <section className={childClass('section')}>
        <div className={childClass('main-container')}>
          <LatestArticles className={childClass('list')} />
          <ArticleAsideBox className={childClass('rhs-aside')} siteMetadata={props.data.site.siteMetadata} />
        </div>
      </section>
      <MarketingSiteFooter />
    </div>
  )
}

export default ArticleListPage;

//TODO move this query to the article list component so it is shared across pages
export const pageQuery = graphql`
  query ArticleListPageQuery {
      site {
            siteMetadata {
                callToAction
                shortDescription
                social {
                  twitter {
                      handle,
                      url
                  }
                  facebook {
                      handle,
                      url
                  }
                }
            }
      }
  }
  `

