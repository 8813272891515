import { LikertMetricResult } from "./LikertMetricResult";
import { SurveyResultsDto } from "@Dtos";
import { Metric } from "./Metric";
import { TeamScoresResult } from "./TeamScoresResult";
import { createBlankLikertMetricResultDto } from "./DiscreteLikertResult";

export class SurveyResults {
    constructor(public id: string, public date: Date, public metrics: { [name: string]: LikertMetricResult}, public teamScores: { [teamId: string]: TeamScoresResult}, public participationCount: number, public invitedCount: number) {
    }

    get metricsArray() {
        return Object.keys(this.metrics).map(key => this.metrics[key]);
    }

    get teamScoresArray() {
        return Object.keys(this.teamScores).map(key => this.teamScores[key]);
    }

    get teamScoresArrayWithCombined() {
        return [this.combinedTeamScores, ...this.teamScoresArray];
    }

    get combinedTeamScores() {
        return this.teamScoresArray.reduce((prev, curr) => {
            for(let key in curr.counts) {
                prev.counts[key] += curr.counts[key];
            }
            return prev;
        }, TeamScoresResult.fromDto({ ...createBlankLikertMetricResultDto(), comments: [] }))
    }

    get averageScoreAcrossAllTeams() {
        const allTeamScores = this.combinedTeamScores;
        return allTeamScores.score;
    }

    get allComments() {
        const result: string[] = [];
        for(let teamId in this.teamScores) {
            for(let comment of this.teamScores[teamId].comments) {
                result.push(comment);
            }
        }
        return result;
    }


    static fromDto(dto: SurveyResultsDto, metrics: { [metricId: string]: Metric}) {
        const metricObjects: { [name: string]: LikertMetricResult} = {};
        for(let metricId in dto.likertMetrics) {
            const metric = metrics[metricId];
            if (!metric) continue; // Maybe this metric was deleted
            const obj = LikertMetricResult.fromDto(dto.likertMetrics[metricId], metric);
            metricObjects[metric.id] = obj;
        }
        const teamScores: { [teamId: string]: TeamScoresResult} = {};
        for(let teamId in dto.teamScores) {
            teamScores[teamId] = TeamScoresResult.fromDto(dto.teamScores[teamId]);
        }
        return new SurveyResults(dto.id, new Date(dto.date),  metricObjects, teamScores, dto.participationCount, dto.invitedCount);
    }
}

