import * as React from "react";
import { StaticQuery, graphql, Link } from "gatsby"
import { childClassClosure, ClassableProps, baseClassAnd } from "@jcharlesworthuk/your-mum-core/dist/functions";
import { mapMdxToArticle, mapMetadataToSocialSites } from "@GraphQLMappers";
import { FileConnection, MdxConnection } from "@GraphQLModels";
import ArticleList from "@jcharlesworthuk/your-mum-frontend/components/lists/ArticleList";

type Props = ClassableProps & {
    excludeRoute?: string
};

interface StaticQueryData {
    latestArticles?: MdxConnection
}

// NOTE this can't be used for latest article pages inside MDX.  For that you need to add this query to the layout and create a <BlogArticlesContextProvider />
// See LandingPageLayout.tsx for example
const LatestArticles: React.FunctionComponent<Props> = (props: Props) => {
    return <StaticQuery
        query={latestArticlesQuery}
        render={({ latestArticles }: StaticQueryData) => {
            const articles = latestArticles ? latestArticles.edges
                .filter(x => x.node.fields.route != props.excludeRoute)
                .map(edge => mapMdxToArticle(edge.node))
                : [];

            return <ArticleList className={props.className} articles={articles} linkComponent={Link} />
        }}
    />
}

export default LatestArticles;

export const latestArticlesQuery = graphql`
      query LatestArticlesQuery {
        latestArticles: allMdx(filter: { 
            frontmatter: {
                author: {ne: null} 
                unlisted: {ne: true} 
            }
        }
        sort: { 
          fields: [frontmatter___date]
          order: DESC 
          }
        limit: 6) {
        edges {
          node {
            fields {
              route
            }
            frontmatter {
              title
              author
              mainImage
              date
              standfirst
            }
            timeToRead
          }
        }
      }
    }
  `