import { BasicSubscriptionInfoDto } from "@Dtos";
import { SubscriptionStatus } from "./SubscriptionStatus";

export class BasicSubscriptionInfo {
    constructor(
        public planId: string,
        public description: string,
        public price: string,
        public status: SubscriptionStatus) {
    }

    public static fromDto(dto: BasicSubscriptionInfoDto) {
        return new BasicSubscriptionInfo(dto.planId, dto.description, dto.price, dto.status);
    }
}