import * as React from 'react';
import './PeopleSetupWindow.scss';
import { childClassClosure, toggleOnClick } from '@jcharlesworthuk/your-mum-core/dist/functions';
import { unsetToken } from '@jcharlesworthuk/your-mum-core/dist/constants/tokens';
import TeamSetupPanel from '../tables/TeamSetupPanel';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import Drawer from '@material-ui/core/Drawer';
import { useState, useContext } from 'react';
import { LoggedInContext, OrganisationManagerContext, EmployeeManagerContext, EmployeeManagerActionType, OrganisationManagerActionType } from "@Contexts";
import { Employee } from '@Models/Employee';
import CreateTeamHintArrow from '../user-hints/CreateTeamHintArrow';
import { Button } from '@material-ui/core';
import UpsertTeamForm from '../forms/UpsertTeamForm';
import { PrivateApiClient } from '../../ApiClient';
import { Team } from '@Models/Team';
import { EmployeeStatus } from '@Models/EmployeeStatus';
import { useDeleteEmployee } from '@Hooks';

const baseClass = 'people-setup-window';
const childClass = childClassClosure(baseClass);


type Props = {};

// I'm copying the style from GCP's IAM roles window
const PeopleSetupWindow: React.FunctionComponent<Props> = () => {
    const { employees, dispatch: dispatchEmployee } = useContext(EmployeeManagerContext);
    const { currentUser } = useContext(LoggedInContext);
    const { organisation, dispatch: dispatchOrganisation } = useContext(OrganisationManagerContext);
    const [newTeamWindowOpen, setNewTeamWindowOpen] = useState(false);
    const deleteEmployee = useDeleteEmployee();

    const handleDeleteUser = (employee: Employee) => {
        deleteEmployee.performDelete(employee);
    }

    const handleDeleteTeam = (team: Team) => {
        async function deleteTeam() {
            const client = new PrivateApiClient().withUser(currentUser);
            const employeesToDelete = [... employees.filter(x => x.teamId === team.id)];
            for(let employee of employeesToDelete) {
                await client.deleteEmployee({ email: employee.email, userId: employee.sub });
                dispatchEmployee({ type: EmployeeManagerActionType.EmployeeDeleted, employee })
            }
            const manager = employees.find(x => x.sub === organisation.teams[team.id].managerId);
            if (manager && manager.email !== currentUser.email && organisation.teamsManagedBy(manager.sub).length === 1) {
                manager.status = EmployeeStatus.Disabled;
                await client.updateEmployee({ email: manager.email, userId: manager.sub, status: EmployeeStatus.Disabled });
                dispatchEmployee({ type: EmployeeManagerActionType.EmployeeEdited, employee: manager })
            }
            await client.updateOrganisation({
                teams: {
                    [team.id]: {
                        name: unsetToken,
                        managerId: unsetToken
                    }
                }
            })
            dispatchOrganisation({ type: OrganisationManagerActionType.TeamDeleted, teamId: team.id })
        }
        deleteTeam();
    }

    if (employees.length === 0) return <></>; //todo move inside the component or something

    //const teamMembers = employees.filter(x => !!x.teamId);

    return <section className={baseClass}>

        <h1 className={childClass('title')}>Your Teams</h1>
        { Object.keys(organisation.teams).map(teamId => 
        <TeamSetupPanel 
            key={teamId}
            team={organisation.teams[teamId]}
            currentUser={currentUser}
            members={employees.filter(x => x.teamId === teamId)}
            manager={employees.find(x => x.sub === organisation.teams[teamId].managerId)}
            possibleManagers={employees.filter(x => !!x.sub && x.teamId !== teamId)}
            className={childClass('table')}
            onRequestDeleteUser={handleDeleteUser}
            onRequestDeleteTeam={handleDeleteTeam} />) }
        <div className={childClass('buttons-row')}>
            <Button size="large" type="button" onClick={toggleOnClick(setNewTeamWindowOpen, true)}><AddIcon /> Create Team</Button>
        </div>
        { Object.keys(organisation.teams).length === 0 && <CreateTeamHintArrow /> }
        <Drawer anchor="right" open={newTeamWindowOpen} onClose={toggleOnClick(setNewTeamWindowOpen, false)} PaperProps={{ className: childClass('draw-paper') }}>
            <UpsertTeamForm
                onCancel={() => setNewTeamWindowOpen(false)}
                onConfirmed={() => setNewTeamWindowOpen(false)}
                possibleManagers={employees}
            />
        </Drawer>
    </section>
}

export default PeopleSetupWindow;