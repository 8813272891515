import React from "react";
import './InitialCircle.scss';
import { childClassClosure, ClassableProps, baseClassAnd } from "@jcharlesworthuk/your-mum-core/dist/functions";
import { IHasProfileImageCircle } from "@Models/IHasProfileImageCircle";

const baseClass = 'initial-circle';
const childClass = childClassClosure(baseClass);

type Props = ClassableProps & {
    user: IHasProfileImageCircle
}

const InitialCircle: React.FunctionComponent<Props> = props => {
    const words = (props.user.fullName || '').split(' ');
    const initials = words.length >= 2 ? `${words[0][0].toUpperCase()}${words[words.length - 1][0].toUpperCase()}`
    : words.length == 1 ? words[0][0].toUpperCase()
    : '';
    const bgCss = `hsl(${props.user.indicatorHue || 0}, 70%, 70%)`;
    return <div className={baseClassAnd(baseClass, props)} style={{backgroundColor: bgCss}}>
        {initials}
    </div>
  }
  export default InitialCircle;
