import * as React from "react";
import "./SubscriptionSelector.scss";
import { ClassableProps, baseClassAnd, childClassClosure } from "@jcharlesworthuk/your-mum-core/dist/functions";
import PriceOptionBox from "./PriceOptionBox";
import { BillingPlanIdentitiers } from "@Models/BillingPlanIdentitiers";

const baseClass = 'subscription-selector';
const childClass = childClassClosure(baseClass);

type Props = ClassableProps & {
  /** Here because the front door layout doesn't actually have routes, it's all just one route */
  onSelect?: (subscriptionId: keyof BillingPlanIdentitiers | 'free') => boolean;

  companyName: string;
};

const SubscriptionSelectorSpecialPlans: React.FunctionComponent<Props> = (props: Props) => {
  return <ul className={baseClassAnd(baseClass, props)}>
    <li>
      <PriceOptionBox price='FREE' title='Special Plan' linkTo={`/account/register?plan=free`} onSelect={() => props.onSelect ? props.onSelect('free') : false}>
        <p>{props.companyName} Unlimited</p>
      </PriceOptionBox>
    </li>
    <li>
      <PriceOptionBox price='FREE' title='Special Plan' linkTo={`/account/register?plan=free`} onSelect={() => props.onSelect ? props.onSelect('free') : false}>
        <p>{props.companyName} Unlimited</p>
      </PriceOptionBox>
    </li>
    <li>
      <PriceOptionBox price='FREE' title='Special Plan' linkTo={`/account/register?plan=free`} onSelect={() => props.onSelect ? props.onSelect('free') : false}>
        <p>{props.companyName} Unlimited</p>
      </PriceOptionBox>
    </li>
  </ul>
}

export default SubscriptionSelectorSpecialPlans;
