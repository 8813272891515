import * as React from "react";
import "./PriceOptionBox.scss";
import { ClassableProps, baseClassAnd, childClassClosure } from "@jcharlesworthuk/your-mum-core/dist/functions";
import LinkButton from "../buttons/LinkButton";
import { Link } from "gatsby";
import ActionButton from "@jcharlesworthuk/your-mum-frontend/components/buttons/ActionButton";
import { ButtonState } from "@jcharlesworthuk/your-mum-frontend/models";

const baseClass = 'price-option-box';
const childClass = childClassClosure(baseClass);

type Props = ClassableProps & {
  linkTo: string;
  price: 'FREE' | string;
  title: string;
  children: JSX.Element[] | JSX.Element;
  /** Here because the front door layout doesn't actually have routes, it's all just one route */
  onSelect?: () => boolean;
};

const PriceOptionBox: React.FunctionComponent<Props> = (props: Props) => {
  return <Link to={props.linkTo} className={baseClassAnd(baseClass, props)} onClick={e => { if (props.onSelect) { if (props.onSelect()) e.preventDefault(); } } }>
      <h4 className={childClass('title')}>{props.title}</h4>
      <p className={childClass('price')} >{props.price}{props.price != 'FREE' && <span className={childClass('month')}>/ month</span>}</p>
      <div className={childClass('content')} >{props.children}</div>
      <ActionButton state={ButtonState.Enabled} size='large' className={childClass('cta')} label='Select' onClick={() => {}} />
    </Link>
}
export default PriceOptionBox;
