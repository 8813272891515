import * as React from 'react';
import './DashboardWindow.scss';
import { Link } from 'gatsby';
import { childClassClosure } from '@jcharlesworthuk/your-mum-core/dist/functions';
import SmallArticleCard from '@jcharlesworthuk/your-mum-frontend/components/cards/SmallArticleCard';
import { Article } from '@jcharlesworthuk/your-mum-frontend/models';
import axios from 'axios';
import { useEffect, useState, useContext } from 'react';
import { Props as LandingPageProps } from '../../layouts/LandingPageLayout';
import { mapMdxToArticle } from '@GraphQLMappers';
import { HistoricalSurveyResultsSet } from '@Models/HistoricalSurveyResultsSet';
import { HistoricalDataAnalysisContextProvider, LoggedInContext, QuestionsAndMetricsContext, SurveyFrequencySettingsContext, EmployeeManagerContext, LatestSurveyResultContext, SurveyManagerContext } from '@Contexts';
import Spinner from '@jcharlesworthuk/your-mum-frontend/components/indicators/Spinner';
import { PrivateApiClient } from '../../ApiClient';
import LatestSurveyResults from '../resultsviews/LatestSurveyResults';
import SingleSurveyParticipantTotal from '../resultsviews/SingleSurveyParticipantTotal';
import MenuDescriptionCard from '../user-hints/MenuDescriptionCard';
import { useCookie } from "@use-hook/use-cookie";
import CountdownClock from '../indicators/CountdownClock';
import GuidedSetupCards from '../user-hints/GuidedSetupCards';
import ArticleList from '@jcharlesworthuk/your-mum-frontend/components/lists/ArticleList';
import { useLocalStorageSetting } from '@Hooks';

const baseClass = 'dashboard-window';
const childClass = childClassClosure(baseClass);

type Props = {};

const DashboardArticleList: React.FunctionComponent<{ articles: Article[] }> = props => {
  return <><h2 className={childClass('title', 'big-gap')} >Guides &amp; Articles</h2>
      <ArticleList className={childClass('article-list')} articles={props.articles} linkComponent={Link} />
      </>
}
 

const DashboardWindow: React.FunctionComponent<Props> = () => {
  const { employees } = useContext(EmployeeManagerContext);
  const { frequencySettings } = useContext(SurveyFrequencySettingsContext);
  const { latestResult } = useContext(LatestSurveyResultContext);
  const { template } = useContext(SurveyManagerContext);
  const [articles, setArticle] = useState<Article[]>([]);
  const [splashCookie, setSplashCookie] = useLocalStorageSetting("splash", "0");

  useEffect(() => {
    async function loadGatsbyData() {
      const json = await axios.get('/page-data/pricing/page-data.json', {})
      const articlePageProps = json.data.result as LandingPageProps;
      const articles = articlePageProps.data.latestArticles.edges.map(x => mapMdxToArticle(x.node));
      setArticle(articles)
    }
    loadGatsbyData();
    if (splashCookie === "0") {
      setTimeout(() => setSplashCookie("1"), 1000);
    } else if (splashCookie === "1") {
      setTimeout(() => setSplashCookie("2"), 1000);
    }
  }, [])

  const unviewedSurvey = true;
  return <section className={baseClass}>

    {frequencySettings.currentlyOpenUntil
      ? <>
        <h1 className={childClass('title', splashCookie === '0' ? 'animate' : '')}>Current survey closes in...</h1>
        <div className={childClass('welcome-container')}>
          <div className={childClass('narrow-container')}>
            <CountdownClock targetDate={frequencySettings.currentlyOpenUntil} />
          </div>
          <p className={childClass('isolated-p')}>Your survey is currently open and accepting responses from your employees.  It will close at {frequencySettings.currentlyOpenUntil.toLocaleTimeString()} on {frequencySettings.currentlyOpenUntil.toLocaleDateString()} then you will be able to view the results here.  To adjust the amount of time your surveys stay open for head over to the <Link to='/office/settings'>Settings Page</Link> and change the setting "Employee Answer Time"</p>
        </div>
        <DashboardArticleList articles={articles} />
      </>
      : latestResult
        ? latestResult.result.participationCount > 0
          ?
          <>
            <div className={childClass('title-block')}>
              {unviewedSurvey ? <h1 className={childClass('title', splashCookie === '1' ? 'animate' : '')}>Your Survey Results Are Ready!</h1> : <h1 className={childClass('title')}>Your Dashboard</h1>}
            </div>
            <div className={childClass('survey-result-container')}>
              <HistoricalDataAnalysisContextProvider initialState={{ historicalData: new HistoricalSurveyResultsSet(latestResult ? [latestResult.result] : []) }}>

                <LatestSurveyResults
                  className={childClass('survey-result-inner', 'big')}
                  titleClass={childClass('survey-result-panel-title')}
                  subtitleClass={childClass('survey-result-panel-subtitle')}
                  paperClass={childClass('survey-result-paper')}
                  includeCtaLink='/office/analysis'
                />

                <SingleSurveyParticipantTotal
                  className={childClass('survey-result-inner', 'small')}
                  titleClass={childClass('survey-result-panel-title')}
                  subtitleClass={childClass('survey-result-panel-subtitle')}
                  paperClass={childClass('survey-result-paper')}
                  includeCtaLink='/office/people'
                />

              </HistoricalDataAnalysisContextProvider>
            </div>
            <DashboardArticleList articles={articles} />
          </>
          : template.unansweredSurveyCount < 3
          ?<>
            <h1 className={childClass('title')}>Your Dashboard</h1>
            <p className={childClass('isolated-p')}>Unfortunately none of your employees repsonded to your latest survey.</p>
            <p className={childClass('isolated-p')}>There are many reasons why your employees may not fill in their survey, perhaps we are sending them out too regularly?  You can edit the frequency with which we send out a survey by visiting the <Link to='/office/settings'>Settings Page</Link></p>
            <DashboardArticleList articles={articles} />
          </>
          : <>
          <h1 className={childClass('title')}>Your Dashboard</h1>
          <p className={childClass('isolated-p')}>Your last {template.unansweredSurveyCount} surveys went unanswered so we have stopped sending them for now.</p>
          <p className={childClass('isolated-p')}><Link to='/contact-us'>Get in touch</Link> if you want to resume sending surveys to your team.</p>
          <DashboardArticleList articles={articles} />
        </>
        : <>
          <h1 className={childClass('title', splashCookie === '0' ? 'animate' : '')}>Welcome to Acorde</h1>
          <p className={childClass('free-paragraph')}>This is your organisation dashboard.  From here you can see your survey results as they come in, as well as configure your users and organisation settings.  Follow the steps to get started.</p>
          <GuidedSetupCards className={childClass('setup-cards')} />
          {/* <div className={childClass('welcome-container')}>
            <p>This is your organisation dashboard.  From here you can see your survey results as they come in, as well as configure your users and organisation settings.  Use the menu on the left to get started.</p>
            {employees.length > 0 && <MenuDescriptionCard className={childClass('welcome-item')} title="All Set!" iconUrl="/img/thumb-very-up.svg" permanentHighlight={true}>
              <p>Your first survey will begin on <strong>{frequencySettings.nextDate.toLocaleDateString()}</strong></p>
            </MenuDescriptionCard>}
            <MenuDescriptionCard className={childClass('welcome-item')} title="People" iconUrl="/img/icons/team.svg">
              <p>Add your employees using the "People" tab.  Each employee will be sent a feedback survey link to the email address you provide.</p>
            </MenuDescriptionCard>
            <MenuDescriptionCard className={childClass('welcome-item')} title="Surveys" iconUrl="/img/icons/questions.svg">
              <p>The "Surveys" page allows you to fine-tune which questions will be on your survey. We have set you up with some recommended defaults to get you started.</p>
            </MenuDescriptionCard>
            <MenuDescriptionCard className={childClass('welcome-item')} title="Settings" iconUrl="/img/icons/settings.svg">
              <p>Upload your organisation's logo and change your survey frequency on the "Settings" page.</p>
            </MenuDescriptionCard>
            <MenuDescriptionCard className={childClass('welcome-item')} title="Help" iconUrl="/img/icons/question-mark.svg">
              <p>For more tips and advice see our <Link to='/using-acorde-as-a-manager/'>Getting Started Guide</Link>.</p>
            </MenuDescriptionCard>
          </div> */}
          <DashboardArticleList articles={articles} />
        </>}

  </section>
}

export default DashboardWindow;

