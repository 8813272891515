// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-layouts-acorde-landing-page-tsx": () => import("./../../../src/layouts/AcordeLandingPage.tsx" /* webpackChunkName: "component---src-layouts-acorde-landing-page-tsx" */),
  "component---src-layouts-article-list-page-tsx": () => import("./../../../src/layouts/ArticleListPage.tsx" /* webpackChunkName: "component---src-layouts-article-list-page-tsx" */),
  "component---src-layouts-article-page-layout-tsx": () => import("./../../../src/layouts/ArticlePageLayout.tsx" /* webpackChunkName: "component---src-layouts-article-page-layout-tsx" */),
  "component---src-layouts-back-office-app-page-tsx": () => import("./../../../src/layouts/BackOfficeAppPage.tsx" /* webpackChunkName: "component---src-layouts-back-office-app-page-tsx" */),
  "component---src-layouts-front-door-layout-tsx": () => import("./../../../src/layouts/FrontDoorLayout.tsx" /* webpackChunkName: "component---src-layouts-front-door-layout-tsx" */),
  "component---src-layouts-landing-page-layout-tsx": () => import("./../../../src/layouts/LandingPageLayout.tsx" /* webpackChunkName: "component---src-layouts-landing-page-layout-tsx" */),
  "component---src-layouts-personalised-landing-page-layout-tsx": () => import("./../../../src/layouts/PersonalisedLandingPageLayout.tsx" /* webpackChunkName: "component---src-layouts-personalised-landing-page-layout-tsx" */),
  "component---src-layouts-static-page-layout-tsx": () => import("./../../../src/layouts/StaticPageLayout.tsx" /* webpackChunkName: "component---src-layouts-static-page-layout-tsx" */),
  "component---src-layouts-survey-completion-layout-tsx": () => import("./../../../src/layouts/SurveyCompletionLayout.tsx" /* webpackChunkName: "component---src-layouts-survey-completion-layout-tsx" */)
}

