import * as React from 'react';
import './OfficeDashboardMenu.scss';
import { Link } from 'gatsby';
import { childClassClosure, baseClassAnd, ClassableProps } from '@jcharlesworthuk/your-mum-core/dist/functions';
import { HelpIndicatorsContext } from '@Contexts';
import { useContext } from 'react';

const baseClass = 'office-dashboard-menu';
const childClass = childClassClosure(baseClass);


type Props = ClassableProps & {
};

const OfficeDashboardMenuItem: React.FunctionComponent<{iconUrl: string, label: string, route: string, highlight: boolean}> = props => {

    return <Link className={childClass('item', props.highlight ? 'highlight' : '')} to={props.route}>
        <img src={props.iconUrl} alt={props.label} />
        <span className={childClass('item-label')}>{props.label}</span>
    </Link>
}


const OfficeDashboardMenu: React.FunctionComponent<Props> = props => {
    const { highlightedMenu } = useContext(HelpIndicatorsContext);

    return <nav className={baseClassAnd(baseClass, props)}>
        <OfficeDashboardMenuItem 
                    highlight={highlightedMenu === 'Dashboard'}
                    route={'/office/dashboard'} 
                    label="Dashboard" 
                    iconUrl="/img/icons/dashboard.svg" />
        <OfficeDashboardMenuItem 
                    highlight={highlightedMenu === 'Analysis'}
                    route={'/office/analysis'} 
                    label="Analysis" 
                    iconUrl="/img/icons/results.svg" />
        <OfficeDashboardMenuItem 
                    highlight={highlightedMenu === 'Surveys'}
                    route={'/office/surveys'} 
                    label="Surveys" 
                    iconUrl="/img/icons/questions.svg" />
        <OfficeDashboardMenuItem 
                    highlight={highlightedMenu === 'Teams'}
                    route={'/office/people'} 
                    label="Teams" 
                    iconUrl="/img/icons/team.svg" />
        <OfficeDashboardMenuItem 
                    highlight={highlightedMenu === 'Settings'}
                    route={'/office/settings'} 
                    label="Settings" 
                    iconUrl="/img/icons/settings.svg" />
    </nav>
}

export default OfficeDashboardMenu;