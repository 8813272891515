import * as React from 'react';
import './EditTeamInfoForm.scss';
import { childClassClosure, ClassableProps, baseClassAnd } from '@jcharlesworthuk/your-mum-core/dist/functions';
import { Employee } from '@Models/Employee';
import { TextField, InputAdornment, ButtonGroup, Button, FormControlLabel, Checkbox } from '@material-ui/core';
import { useUpsertEmployee, useUpsertTeam } from '@Hooks';
import Autocomplete from '@material-ui/lab/Autocomplete';
import AlternateEmail from '@material-ui/icons/AlternateEmail';
import { ButtonState } from '@jcharlesworthuk/your-mum-frontend/models';
import { useContext, useState } from 'react';
import { OrganisationManagerContext } from '@Contexts';
import Alert from '@material-ui/lab/Alert';
import { Team } from '@Models/Team';

const baseClass = 'edit-team-info';
const childClass = childClassClosure(baseClass);


type Props = ClassableProps & {
  onCancel: () => void;
  onConfirmed: () => void;
  team: Team;
};

const EditTeamInfoForm: React.FunctionComponent<Props> = (props) => {
  const teamForm = useUpsertTeam(props.team);
  const inputVariant = 'outlined';

  return <form className={baseClassAnd(baseClass, props)} noValidate autoComplete="off" onSubmit={teamForm.handleSubmitForm(props.onConfirmed)}>
    <h2 className={childClass('title')}>Edit Team</h2>
    <TextField id="temName"
      className={childClass('full-name')}
      label="Team Name"
      margin="normal"
      fullWidth
      variant={inputVariant}
      value={teamForm.fields.teamName || ''}
      onChange={teamForm.registerInputChangeHandler('teamName')} />
    {teamForm.error && <p className={childClass('error-message')}>{teamForm.error}</p>}
    <div className={childClass('buttons')}>
      <Button size="large" onClick={() => props.onCancel()}>Cancel</Button>
      <Button variant="contained" size="large" color="primary" type="submit" disabled={teamForm.buttonState !== ButtonState.Enabled}>Update</Button>
    </div>
  </form>
}

export default EditTeamInfoForm;