import * as React from 'react';
import './LatestSurveyParticipation.scss';
import { ClassableProps, childClassClosure, baseClassAnd } from "@jcharlesworthuk/your-mum-core/dist/functions";
import { Paper } from '@material-ui/core';
import { HistoricalDataAnalysisContext, OrganisationManagerContext, EmployeeManagerContext } from '@Contexts';
import { useContext } from 'react';
import ParticipationGraph from '../graphs/ParticipationGraph';

const baseClass = 'latest-survey-participation';
const childClass = childClassClosure(baseClass);

type Props = ClassableProps & {
    paperClass?: string;
    titleClass?: string;
    subtitleClass?: string;
};

const LatestSurveyParticipation: React.FunctionComponent<Props> = props => {
    const { historicalData } = useContext(HistoricalDataAnalysisContext);
    const { employees } = useContext(EmployeeManagerContext);

    return <div className={baseClassAnd(baseClass, props)}>
        <Paper className={props.paperClass} elevation={1}>
            <h3 className={props.titleClass}>Participation</h3>
            <p className={[props.subtitleClass, childClass('subtitle')].join(' ')}>How many people completed your survey</p>
            <ParticipationGraph className={childClass('chart')} participationHistory={historicalData.surveys} totalUsersCount={employees.length} height={300} />
        </Paper>
    </div>
}

export default LatestSurveyParticipation;