import { SurveyFrequency, SurveyDay } from "./SurveyFrequency";
import { addDays, addMonths } from "@jcharlesworthuk/your-mum-core/dist/functions";
import { SurveyScheduler } from "./SurveyScheduler";
import { OrganisationDto, PublicOrganisationDto } from "@Dtos";

export const defaultTeamNames = ['Development', 'Finance', 'Marketing', 'Sales', 'Customer Relations'];

export class PublicOrganisation {
    constructor(
            public id: string,
            public name: string, 
            public logoUrl: string | undefined) {
    }

    public static fromDto(dto: PublicOrganisationDto) {
        return new PublicOrganisation(dto.id, dto.name, dto.logoUrl);
    }
}

