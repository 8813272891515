import { Metric } from "./Metric";
import { QuestionTemplate } from "./QuestionTemplate";

export class MetricConfiguration {
    constructor(public metric: Metric,
        public questions: { enabled: boolean, template: QuestionTemplate}[],
        public active: boolean) {
    }

    cloneWithActive(active: boolean) {
        return new MetricConfiguration(this.metric, this.questions, active);
    }

    cloneWithQuestionEnabled(index: number, enabled: boolean) {
        const questions = [...this.questions];
        questions[index].enabled = enabled;
        return new MetricConfiguration(this.metric, questions, this.active);
    }

}
