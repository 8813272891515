import * as React from 'react';
import './AccountDropdown.scss';
import { useState, useContext } from 'react';
import { LoggedInContext } from '@Contexts';
import { Link } from 'gatsby';
import { childClassClosure, ClassableProps, baseClassAnd } from '@jcharlesworthuk/your-mum-core/dist/functions';
import UserSvg from '@jcharlesworthuk/your-mum-frontend/svg/UserSvg';
import InitialCircle from '../indicators/InitialCircle';

const baseClass = 'account-dropdown';
const childClass = childClassClosure(baseClass);


type Props = ClassableProps & {
};

const AccountDropdown: React.FunctionComponent<Props> = props => {
    const { currentUser } = useContext(LoggedInContext);
    if (!currentUser) return null;
    const [accountMenuOpen, setAccountMenuOpen] = useState(false);
    const accountMenuClassName = accountMenuOpen ? 'visible' : '';
    const btnAccountClassName = accountMenuOpen ? 'highlighted' : '';
    const toggleMenu = () => setAccountMenuOpen(!accountMenuOpen);
    const hideMenu = () => setAccountMenuOpen(false);

    return <>
        <span onClick={toggleMenu} key='btnAccount' className={baseClassAnd(baseClass, props, btnAccountClassName)}>
            <InitialCircle user={currentUser} /><span className={childClass('user-name')}>{currentUser.shorterDisplayName}
            </span>
        </span>
        <div key='accountScrim' className={childClass('scrim', accountMenuClassName)} onClick={hideMenu}></div>
        <ul className={childClass('dropdown', accountMenuClassName)} key='mnuAccount'>
            <li>
                <Link to={'/office/profile'} activeClassName='active' onClick={hideMenu}>
                    My Profile
                    </Link>
            </li>
            <li>
                <Link to={'/account/logout'} activeClassName='active' onClick={hideMenu}>
                    Log Out
                    </Link>
            </li>
        </ul>
    </>
}

export default AccountDropdown;