import { MetricDto } from "@Dtos";

export class Metric {
    constructor(public id: string, public name: string, public description: string) {
    }

    static createFromDto(dto: MetricDto) {
        return new Metric(dto.id, dto.name, dto.description);
    }
}
