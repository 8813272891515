import * as React from 'react';
import './MarqueeQuestionPreview.scss';
import { childClassClosure, ClassableProps, baseClassAnd } from '@jcharlesworthuk/your-mum-core/dist/functions';
import { useContext, useEffect, useState } from 'react';
import { SurveyManagerContext, SurveyManagerActionType, LoggedInContext } from '@Contexts';
import { ExpansionPanel, ExpansionPanelSummary, FormControlLabel, Switch, ExpansionPanelDetails, RadioGroup, Radio, Checkbox, Paper, Drawer } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useDebounce } from '@Hooks';
import { PrivateApiClient } from '../../ApiClient';
import { SaveState } from '@Models/SaveState';
import ChangeQuestionTextForm from '../forms/ChangeQuestionTextForm';

const baseClass = 'marquee-preview';
const childClass = childClassClosure(baseClass);


type Props = ClassableProps & {
};


const LikertExample: React.FunctionComponent<{ iconPath: string, label: string }> = props => {
  return <div className={childClass('likert-example')}>
    <img src={props.iconPath} alt={props.label} />
  </div>
}

const MarqueeQuestionPreview: React.FunctionComponent<Props> = props => {
  const { template, saveState, dispatch } = useContext(SurveyManagerContext);
  const { currentUser } = useContext(LoggedInContext);
  const [editing, setEditing] = useState(false);

  return <Paper className={baseClassAnd(baseClass, props)}>
    <div className={childClass('text')}>{template.marqueeQuestion}<span className={childClass('edit-text')} onClick={() => setEditing(true)}>Edit</span></div>
    <div className={childClass('likerts')}>
      <LikertExample iconPath="/img/face-rbf.svg" label="Very Unhappy" />
      <LikertExample iconPath="/img/face-sad.svg" label="Unhappy" />
      <LikertExample iconPath="/img/face-neutral.svg" label="Neutral" />
      <LikertExample iconPath="/img/face-happy.svg" label="Happy" />
      <LikertExample iconPath="/img/face-very-happy.svg" label="Very Happy" />
    </div>
    <Drawer anchor="right" open={editing} onClose={() => setEditing(false)} PaperProps={{ className: childClass('draw-paper') }}>
      <ChangeQuestionTextForm
        onCancel={() => setEditing(false)}
        onConfirmed={() => setEditing(false)}
        template={template}
        fieldName="marqueeQuestion" />
    </Drawer>
  </Paper>
}

export default MarqueeQuestionPreview;
