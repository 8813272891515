import * as React from 'react';
import './CompleteAccountCreationForm.scss';
import { useState } from 'react';
import { ButtonState } from "@jcharlesworthuk/your-mum-frontend/models";
import NiceTextField from "@jcharlesworthuk/your-mum-frontend/components/forms/NiceTextField";
import TermsAndConditionsField from '@jcharlesworthuk/your-mum-frontend/components/forms/TermsAndConditionsField';
import ActionButton from "@jcharlesworthuk/your-mum-frontend/components/buttons/ActionButton";
import { childClassClosure, ClassableProps, isNullOrEmpty, baseClassAnd } from '@jcharlesworthuk/your-mum-core/dist/functions';
import { Link } from 'gatsby';
import { createInputChangeHandler, InputChangeDelegate } from '@jcharlesworthuk/your-mum-frontend/helpers/InputDelegates';
import * as Authentication from '@Services/Authentication';
import * as Sentry from '@sentry/browser';
import { LoginCredentials } from '@Models/LoginCredentials';
import { CurrentUser } from '@Models/CurrentUser';
import { useChangePasswordForm } from '@Hooks';

const baseClass = 'complete-account-creation-form';
const childClass = childClassClosure(baseClass);

type Props = ClassableProps & {
    email: string;
    oldPassword: string;
    onComplete: (user: CurrentUser) => void;
};

const CompleteAccountCreationForm: React.FunctionComponent<Props> = props => {
    const passwordChangeForm = useChangePasswordForm(props.email, props.oldPassword);
    const [termsAccepted, setTermsAccepted] = useState(false);
    const termsClick = (value: boolean) => {
        setTermsAccepted(value)
    }
    return <form onSubmit={passwordChangeForm.handleFormSubmit(props.onComplete)} className={baseClassAnd(baseClass, props)}>
        <h1 className={childClass('title')}>Welcome to Acorde</h1>
        <p className={childClass('message')}>Please complete your account setup by choosing a password.</p>
        <h3 className={childClass('subtitle')}>{props.email}</h3>

        <NiceTextField
            className={childClass('text-field')}
            type='password'
            label="Choose A Password"
            value={passwordChangeForm.fields.newPassword}
            onChange={passwordChangeForm.handlePasswordInputChange('newPassword')} />

        <NiceTextField
            className={childClass('text-field')}
            type='password'
            label="Re-Enter Password"
            value={passwordChangeForm.fields.newPasswordCopy}
            onChange={passwordChangeForm.handlePasswordInputChange('newPasswordCopy')} />
        <TermsAndConditionsField
            className={childClass('terms-field')}
            linkComponent={Link}
            name='termsAccepted'
            onChange={(_, v) => termsClick(v)}
            value={termsAccepted} />

        {passwordChangeForm.error && <p className={childClass('error-message')}>{passwordChangeForm.error}</p>}

        <div className={childClass('button-container')}>
            <ActionButton
                type='submit'
                size='large'
                state={termsAccepted ? passwordChangeForm.buttonState : ButtonState.Disabled}
                label='Submit'
            />
        </div>
        <div className={childClass('grow-space')}>&nbsp; </div>

    </form>
}


export default CompleteAccountCreationForm;