import * as React from "react";
import "./SubscriptionSelector.scss";
import { ClassableProps, baseClassAnd, childClassClosure } from "@jcharlesworthuk/your-mum-core/dist/functions";
import PriceOptionBox from "./PriceOptionBox";
import { BillingPlanIdentitiers } from "@Models/BillingPlanIdentitiers";

const baseClass = 'subscription-selector';
const childClass = childClassClosure(baseClass);

type Props = ClassableProps & {
    /** Here because the front door layout doesn't actually have routes, it's all just one route */
    onSelect?: (subscriptionId: keyof BillingPlanIdentitiers | 'free') => boolean;
};

const SubscriptionSelector: React.FunctionComponent<Props> = (props: Props) => {
  return <ul className={baseClassAnd(baseClass, props)}>
      <li>
        <PriceOptionBox price='FREE' title='Get Started For Free' linkTo={`/account/register?plan=free`} onSelect={() => props.onSelect ? props.onSelect('free') : false }>
          <p>Try Acorde with up to <strong>25</strong> employees completely <strong>FREE</strong>, no credit card required.</p>
        </PriceOptionBox>
      </li>
      <li>
        <PriceOptionBox price='$75' title='Enterprise' linkTo={`/account/register?plan=enterprise`} onSelect={() => props.onSelect ? props.onSelect('enterprise') : false }>
          <p>Up to <strong>200</strong> employees. For larger organisations</p>
        </PriceOptionBox>
      </li>
      <li>
        <PriceOptionBox price='$125' title='Unlimited' linkTo={`/account/register?plan=unlimited`} onSelect={() => props.onSelect ? props.onSelect('unlimited') : false }>
          <p>Our unlimited plan, allows you to survey your entire organisation.</p>
        </PriceOptionBox>
      </li>
    </ul>
}

export default SubscriptionSelector;
