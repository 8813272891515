import * as React from 'react';
import { baseClassAnd, ClassableProps } from '@jcharlesworthuk/your-mum-core/dist/functions';
import { useRef, useEffect } from 'react';

const baseClass = 'screen-demo';

type Props = ClassableProps & {
    animate: boolean;
};

const AddUserScreenDemo: React.FunctionComponent<Props> = props => {
    const objectRef = useRef<HTMLObjectElement>(null);

    useEffect(() => {
        if (props.animate && objectRef.current && objectRef.current.contentDocument && isLoaded(objectRef.current.contentDocument)) {
            animateDomElement(objectRef.current.contentDocument);
        } 
    }, [props.animate]);

    return <object width="100%" 
                className={baseClassAnd(baseClass, props)} 
                id="users-page-svg" 
                data="/screen-demos/users-page.svg" 
                ref={objectRef} />
}

export default AddUserScreenDemo;

function isLoaded(svg: Document) {
    return !!svg.getElementById("popover-window");
}

export function animateDomElement(svg: Document) {

    const plusButton = svg.getElementById("plus-button");
    const popoverWindow = svg.getElementById("popover-window");
    const scrim = svg.getElementById("scrim");
    const typingBoxes = [
        svg.getElementById("typed-1"),
        svg.getElementById("typed-2"),
        svg.getElementById("typed-3")
    ];

    //Prepare
    plusButton.style.transition = 'transform 400ms ease';
    plusButton.style.transformOrigin = `100% 100%`;

    popoverWindow.style.transform = 'translate(100px,0px)';
    popoverWindow.style.opacity = '1';

    typingBoxes[0].style.width = '0px';
    typingBoxes[1].style.width = '0px';
    typingBoxes[2].style.width = '0px';

    function clickPlusButton() {
        plusButton.style.transform = 'scale(0.2) translate(0,0)';
        setTimeout(openPopover, 200);
    }

    function openPopover() {
        popoverWindow.style.transition = 'all 500ms ease';
        popoverWindow.style.transform = 'translate(0px,0px)';
        scrim.style.transition = 'opacity 500ms ease';
        scrim.style.opacity = '1';
        setTimeout(enterText, 500);
    }

    function enterText() {
        typingBoxes.forEach(x => x.style.transition = 'width 200ms linear');
        typingBoxes[1].style.transitionDelay = '200ms';
        typingBoxes[2].style.transitionDelay = '400ms';
        typingBoxes[0].style.width = '45px';
        typingBoxes[1].style.width = '65px';
        typingBoxes[2].style.width = '55px';
        setTimeout(closePopover, 1000);
    }

    function closePopover() {
        popoverWindow.style.transform = 'translate(100px,0px)';
        scrim.style.opacity = '0';
        setTimeout(() => {
            plusButton.style.transform = 'scale(1) translate(0,0)';
        }, 200);
    }

    clickPlusButton();
}