import { BillingPlanIdentitiers } from "@Models/BillingPlanIdentitiers";

export type FrontEndConfigShape = {
    billingPlanIds: BillingPlanIdentitiers;
    stripeApiKey: string;
}

function createFrontEndConfig(): FrontEndConfigShape {
    return {
        billingPlanIds: __USE_API__ === 'dev'
            ? {
                startup: 'GumwLjrJz7dykz',
                enterprise: 'GumxdWnltCFplh',
                unlimited: 'GumxitGfVo5fi3'
            } : {
                startup: 'H0SdG0oORGPZIR',
                enterprise: 'H0Ses7btLWOYzR',
                unlimited: 'H0SewC3rY0yG0l'
            },
        stripeApiKey: __USE_API__ === 'dev'
        ? 'pk_test_5XHSFT1iB1poCvIfJLr07KIY00vLdC48W7'
        : 'pk_live_GDzG7ve1OJcwY6XvwbuIAD3400uLHvVrK7',
    }
};


const config = createFrontEndConfig();

export default config;