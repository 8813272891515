import React, { useState } from "react"
import "./SurveyWelcomePage.scss";
import { childClassClosure, ClassableProps, baseClassAnd } from "@jcharlesworthuk/your-mum-core/dist/functions";
import { FaceRbf } from "@jcharlesworthuk/your-mum-frontend/svg/FaceRbf";
import { FaceHappy } from "@jcharlesworthuk/your-mum-frontend/svg/FaceHappy";
import { Link } from "gatsby";
import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const baseClass = 'survey-welcome-page';
const childClass = childClassClosure(baseClass);

type Props = ClassableProps & {
    handleNext: () => void;
    organisationName: string;
}

const SurveyWelcomePage: React.FunctionComponent<Props> = props => {

    return <div className={baseClassAnd(baseClass, props)}>
        <div className={childClass('container')}>
            <h2 className={childClass('title')}>{props.organisationName} Feedback Survey</h2>
            <p className={childClass('text')}>Please take a minute to answer some quick questions about <span className={childClass('org-name')}>{props.organisationName}</span></p>
            <div className={childClass('buttons')}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={props.handleNext}
                    size="large"
                >
                    Begin Survey
      </Button>
            </div>
            <div className={childClass('anon-container')}>
                <img src='/img/spy.svg' className={childClass('spy-image')} alt='Anonymous' />
                <div className={childClass('anon-text')}>
                    <p>Your answers are anonymous</p>
                    <p>Read more about <Link to='/anonymity-on-acorde/'>anonymity on Acorde</Link></p>
                </div>
            </div>

        </div>
    </div>
}

export default SurveyWelcomePage;
