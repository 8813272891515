export enum EmployeeStatus {
    Undefined = 0,
    
    Active = 1,

    Complained = 2,

    Invited = 3,

    Disabled = 4,
}