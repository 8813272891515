import React, { useState } from "react"
import "./ScaleEntryRadios.scss";
import { childClassClosure, ClassableProps, baseClassAnd } from "@jcharlesworthuk/your-mum-core/dist/functions";
import { FaceRbf } from "@jcharlesworthuk/your-mum-frontend/svg/FaceRbf";
import { FaceHappy } from "@jcharlesworthuk/your-mum-frontend/svg/FaceHappy";
import ScaleRadioButton from "./ScaleRadioButton";
import { ScaleLabelSet, ScaleLabelItem } from "@Models/ScaleLabelSet";

const baseClass = 'scale-entry';
const childClass = childClassClosure(baseClass);

type Props = ClassableProps & {
    scale: ScaleLabelItem[];
    value?: number;
    onChange: (newValue: number) => void;
    invert?: boolean;
}


const ScaleEntryRadios: React.FunctionComponent<Props> = props => {
    const { value } = props;
    const modifier = props.invert === true ? -1 : 1;
    const isSomethingSelected = typeof value !== 'undefined';

    return <form className={baseClassAnd(baseClass, props, isSomethingSelected ? 'something-selected' : 'nothing-selected')} onSubmit={e => e.preventDefault()} noValidate autoComplete="off">
        <div className={childClass('container')}>
            {props.scale.map(scale => <ScaleRadioButton key={scale.label} className={childClass('radio')} iconPath={scale.iconPath} value={value} thisRadioValue={modifier * scale.value} label={scale.label} onChange={props.onChange} />)}
        </div>
    </form>
}

export default ScaleEntryRadios;