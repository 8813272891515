import * as React from 'react';
import { baseClassAnd, ClassableProps } from '@jcharlesworthuk/your-mum-core/dist/functions';
import { useRef, useEffect } from 'react';

const baseClass = 'screen-demo';

type Props = ClassableProps & {
    animate: boolean;
};

const AnalysisScreenDemo: React.FunctionComponent<Props> = props => {
    const objectRef = useRef<HTMLObjectElement>(null);

    useEffect(() => {
        if (props.animate && objectRef.current && objectRef.current.contentDocument && isLoaded(objectRef.current.contentDocument)) {
            animateDomElement(objectRef.current.contentDocument);
        }
    }, [props.animate]);

    return <object width="100%"
        className={baseClassAnd(baseClass, props)}
        id="analysis-page-svg"
        data="/screen-demos/analysis-page.svg"
        ref={objectRef} />
}

export default AnalysisScreenDemo;

function isLoaded(svg: Document) {
    return !!svg.getElementById("bars-group");
}

export function animateDomElement(svg: Document) {
    const likertGroups = [
        svg.getElementById("likert-group-1"),
        svg.getElementById("likert-group-2"),
        svg.getElementById("likert-group-3"),
        svg.getElementById("likert-group-4"),
    ]
    const barsGroup = svg.getElementById('bars-group');
    const trendPathA = svg.getElementById('trend-path-1');
    const trendPathB = svg.getElementById('trend-path-2');
    const circleIndicatorA = svg.getElementById('circle-indicator-1');
    const circleIndicatorB = svg.getElementById('circle-indicator-2');

    for (let i = 0; i < likertGroups.length; i++) {
        likertGroups[i].style.transform = 'scale(0,1)';
        likertGroups[i].style.transformOrigin = '114px 100%';
    }
    barsGroup.style.transform = 'scale(1,0)';
    barsGroup.style.transformOrigin = '100% 86px';
    trendPathA.style.strokeDasharray = '40%';
    trendPathA.style.strokeDashoffset = '40%';
    
    trendPathB.style.strokeDasharray = '40%';
    trendPathB.style.strokeDashoffset = '40%';
    

    circleIndicatorA.style.strokeDasharray = '15%';
    circleIndicatorA.style.strokeDashoffset = '15%';
    
    circleIndicatorB.style.strokeDasharray = '15%';
    circleIndicatorB.style.strokeDashoffset = '15%';
    

    function animateLikert() {
        for (let i = 0; i < likertGroups.length; i++) {
            setTimeout(() => {
                likertGroups[i].style.transition = 'transform 250ms ease-out';
                likertGroups[i].style.transform = 'scale(1,1)';
            }, i * 250);
        }
    }

    function animateBars() {
        barsGroup.style.transition = 'transform 500ms ease-out';
        barsGroup.style.transform = 'scale(1,1)';
        setTimeout(animateTrendPaths, 500);
    }

    function animateTrendPaths() {
        circleIndicatorA.style.transition = 'stroke-dashoffset 500ms linear';
        circleIndicatorA.style.strokeDashoffset = '0%';
    
        trendPathA.style.transition = 'stroke-dashoffset 500ms linear';
        trendPathB.style.transition = 'stroke-dashoffset 500ms linear';
        circleIndicatorB.style.transition = 'stroke-dashoffset 500ms linear';

        trendPathA.style.strokeDashoffset = '0%';
        setTimeout(() => {
            circleIndicatorB.style.strokeDashoffset = '0%';
            trendPathB.style.strokeDashoffset = '0%';
        }, 500);
    }

    setTimeout(animateLikert, 100);
    setTimeout(animateBars, 100);
}