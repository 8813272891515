import React, { } from "react"
import './TryExampleSurveyArrow.scss';
import { childClassClosure, ClassableProps, baseClassAnd } from "@jcharlesworthuk/your-mum-core/dist/functions";

const baseClass = 'example-survey-hint-arrow';
const childClass = childClassClosure(baseClass);

type Props = ClassableProps & {
}

const TryExampleSurveyArrow: React.FunctionComponent<Props> = (props: Props) => {

    return <div className={baseClassAnd(baseClass, props)}>
        <h3 className={childClass('title')}>Try an example survey now</h3>
        <img className={childClass('img')} src={'/img/show-arrow.svg'} alt="Example Survey" />
    </div>
}

export default TryExampleSurveyArrow;