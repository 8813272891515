import * as React from 'react';
import './CognitoEmailVerifyForm.scss';
import { useState } from 'react';
import { ButtonState } from "@jcharlesworthuk/your-mum-frontend/models";
import ActionButton from "@jcharlesworthuk/your-mum-frontend/components/buttons/ActionButton";
import { childClassClosure, ClassableProps, isNullOrEmpty, baseClassAnd } from '@jcharlesworthuk/your-mum-core/dist/functions';
import { InputChangeDelegate } from '@jcharlesworthuk/your-mum-frontend/helpers/InputDelegates';
import * as Sentry from '@sentry/browser';
import { CurrentUser } from '@Models/CurrentUser';
import { LoginCredentials } from '@Models/LoginCredentials';
import * as Authentication from '@Services/Authentication';

const baseClass = 'verify-email-form';
const childClass = childClassClosure(baseClass);

type Props = ClassableProps & {
    credentials: LoginCredentials;
    onComplete: (signedInUser: CurrentUser) => void;
};


const CognitoEmailVerifyForm: React.FunctionComponent<Props> = props => {
    const [code, setCode] = useState('');
    const [error, setError] = useState<string | undefined>(undefined);
    const [buttonState, setButtonState] = useState<ButtonState>(ButtonState.Disabled);
    const onChange: InputChangeDelegate = (e) => {
        setCode(e.currentTarget.value);
        setButtonState(isNullOrEmpty(e.currentTarget.value) ? ButtonState.Disabled : ButtonState.Enabled);
    }
    const handleError = e => {
        console.error(e);
        Sentry.captureException(e);
        setError(e.message);
    }

    const handleSubmitConfirmationCode = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const action = async () => {
            setButtonState(ButtonState.Working);
            try {
                await Authentication.confirmRegistration(props.credentials.email, code);
                const user = await Authentication.signInWithPassword(props.credentials.email, props.credentials.password);
                props.onComplete(user);
            } catch (e) {
                handleError(e);
            }
        };
        action();
    }

    return <form onSubmit={handleSubmitConfirmationCode} className={baseClassAnd(baseClass, props)}>
        <h2 className={childClass('title')}>Email Verification</h2>
        <p className={childClass('message')}>We have sent you a confirmation code to <strong>{props.credentials.email}</strong>, please enter it here</p>

        <div className={childClass('text-wrapper')}>
      <input type={'text'}
        className={childClass('input')}
        name='code'
        value={code}
        onChange={onChange}
        required={true} />
    </div>

        {error && <p className={childClass('error-message')}>{error}</p>}
        <div className={childClass('button-container')}>
            <ActionButton
                type='submit'
                size='large'
                state={buttonState}
                label='Continue'
            />
        </div>
    </form>
}


export default CognitoEmailVerifyForm;