import * as React from 'react';
import './OrganisationSetupForm.scss';
import { useState } from 'react';
import { ButtonState } from "@jcharlesworthuk/your-mum-frontend/models";
import ActionButton from "@jcharlesworthuk/your-mum-frontend/components/buttons/ActionButton";
import { childClassClosure, ClassableProps, isNullOrEmpty, generateNewId, baseClassAnd } from '@jcharlesworthuk/your-mum-core/dist/functions';
import { InputChangeDelegate, createInputChangeHandler } from '@jcharlesworthuk/your-mum-frontend/helpers/InputDelegates';
import * as Sentry from '@sentry/browser';
import { CurrentUser } from '@Models/CurrentUser';
import { LoginCredentials } from '@Models/LoginCredentials';
import NiceTextField from '@jcharlesworthuk/your-mum-frontend/components/forms/NiceTextField';
import { Organisation } from '@Models/Organisation';
import { PrivateApiClient } from '../../ApiClient';

const baseClass = 'org-setup-form';
const childClass = childClassClosure(baseClass);

type Props = ClassableProps & {
    onComplete: (organisation: Organisation) => void;
};

export type OrgSetupRequest = {
    orgName: string;
};

const OrganisationSetupForm: React.FunctionComponent<Props> = props => {
    const [inputs, setInputs] = useState<OrgSetupRequest>({ orgName: '' });
    const [error, setError] = useState<string | undefined>(undefined);
    const [buttonState, setButtonState] = useState<ButtonState>(ButtonState.Disabled);

    const registerInputChangeHandler = createInputChangeHandler<OrgSetupRequest>(setInputs, newInputs => {
        const isValid = !isNullOrEmpty(newInputs.orgName);
        setButtonState(isValid ? ButtonState.Enabled : ButtonState.Disabled);
    });

    const handleError = e => {
        console.error(e);
        Sentry.captureException(e);
        setError(e.message);
        setButtonState(ButtonState.Enabled);
    }

    const handleSubmitOrganisation = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const action = async () => {
            setError(undefined);
            setButtonState(ButtonState.Working);
            try {
                const newOrgId = generateNewId();
                const organisation = Organisation.createDefault(newOrgId, inputs.orgName);
                await new PrivateApiClient().createOrganisation({
                    orgId: newOrgId,
                    orgName: inputs.orgName
                });
                props.onComplete(organisation);
            } catch (e) {
                handleError(e);
            }
        };
        action();
    }

    return <form onSubmit={handleSubmitOrganisation} className={baseClassAnd(baseClass, props)}>
        <h2 className={childClass('title')}>Company Setup</h2>
        <p className={childClass('message')}>What is the name of your company?</p>

        <NiceTextField
            className={childClass('text-field')}
            type='text'
            label="Name"
            value={inputs.orgName || ''}
            onChange={registerInputChangeHandler('orgName', inputs)} />


        {error && <p className={childClass('error-message')}>{error}</p>}
        <div className={childClass('button-container')}>
            <ActionButton
                type='submit'
                size='large'
                state={buttonState}
                label='Continue'
            />
        </div>
    </form>
}


export default OrganisationSetupForm;