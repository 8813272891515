
export enum FrontDoorSteps {
    Initialising = 0,
    LogIn = 1,
    RegisterCognito = 2,
    CognitoEmailVerification = 3,
    CreateOrganisation = 4,
    SetupBillingSubscription = 5,
    LoggedOut = 6,
    PasswordResetRequest = 7,
    PasswordResetSetNew = 8,
    SubscriptionCancel = 9,
    ShowMessage = 10,
    SubscriptionChange = 11,
    ManagerSignup = 12
}
