import { CurrentUser } from "@Models/CurrentUser";
import { RegisterByEmaiRequest } from "@Models/RegisterByEmaiRequest";
import AwsAuth, { CognitoHostedUIIdentityProvider, CognitoUser } from '@Plugins/amplify-auth';
import { PrivateApiClient } from "../ApiClient";
import { AcordePermissions } from "@Models/AcordePermissions";

export const isBrowser = () => typeof window !== "undefined";

type CognitoPoolUser = {
    id: string;
    username: string;
    attributes: {
        sub: string;
        email_verified: boolean
        name: string
        'custom:orgid': string | undefined;
        email: string
    }
}

type LocalStorageUser = {
    permissions: string;
    newsletter: number;
    indicatorHue: number;
    teamId?: string;
}

export async function loadUserFromStorage() {
    if (!isBrowser()) return undefined;

    const cognitoUser = await getCognitoUser();

    if (!cognitoUser) return undefined;

    let localStorageUser = getLocalStorageUser();

    if (!localStorageUser && cognitoUser.attributes["custom:orgid"]) {
        const meDto = await new PrivateApiClient().getMe(cognitoUser.attributes["custom:orgid"]);
        localStorageUser = {
            permissions: meDto.permissions,
            indicatorHue: meDto.indicatorHue,
            newsletter: meDto.newsletter
        };
        saveLocalStorageUser(localStorageUser);
    }

    return convertToUserModel(cognitoUser, localStorageUser);
}

export async function signInWithPassword(email: string, password: string) {
    console.log('signing in...');
    await AwsAuth.signIn(email.toLowerCase(), password);
    console.log('User signed in');
    const cognitoUser = await getCognitoUser();
    if (!cognitoUser) throw new Error('Error - logged in ok but cognito not returning the user');
    console.log(cognitoUser);
    if (cognitoUser.attributes["custom:orgid"]) {
        const meDto = await new PrivateApiClient().getMe(cognitoUser.attributes["custom:orgid"]);
        const localStorageUser = {
            permissions: meDto.permissions,
            indicatorHue: meDto.indicatorHue,
            newsletter: meDto.newsletter
        };
        saveLocalStorageUser(localStorageUser);
        const user = await convertToUserModel(cognitoUser, localStorageUser);
        return user;
    } else {
        // If the user has signed up in cognito but not set up their org yet...
        const user = await convertToUserModel(cognitoUser, undefined);
        return user;
    }
}

export async function signInAndChangePassword(email: string, tempPassword: string, newPassword: string) {
    console.log('signing in with temp password...');
    const loggedInUser = await AwsAuth.signIn(email.toLowerCase(), tempPassword);
    if (!loggedInUser) throw new Error('Error - failed to sign in');
    console.log('User signed in');
    console.log(loggedInUser);
    await AwsAuth.completeNewPassword(loggedInUser, newPassword, {});
    const cognitoUser = await AwsAuth.currentUserInfo() as CognitoPoolUser;
    console.log(cognitoUser);
    if (!cognitoUser) throw new Error('Error - password changed ok but cognito not returning the user');
    const meDto = await new PrivateApiClient().getMe(cognitoUser.attributes["custom:orgid"]);
    const localStorageUser = {
        permissions: meDto.permissions,
        indicatorHue: meDto.indicatorHue,
        newsletter: meDto.newsletter
    };
    saveLocalStorageUser(localStorageUser);
    const user = await convertToUserModel(cognitoUser, localStorageUser);
    return user;
}

function convertToUserModel(cognitoUser: CognitoPoolUser, localStorageUser: LocalStorageUser | undefined): CurrentUser {
    return new CurrentUser(cognitoUser.username,
        cognitoUser.attributes.email,
        cognitoUser.attributes.name,
        cognitoUser.attributes["custom:orgid"] || 'PENDING',
        cognitoUser.id,
        localStorageUser ? localStorageUser.indicatorHue : 0,
        localStorageUser ? localStorageUser.newsletter : 0,
        localStorageUser && localStorageUser.permissions ? localStorageUser.permissions.split('|') as AcordePermissions[] : [],
        localStorageUser ? localStorageUser.teamId : undefined);
}

export async function getCognitoUser() {
    try {
        const result = await AwsAuth.currentUserInfo() as CognitoPoolUser;
        return result;
    } catch (e) {
        if (e === "not authenticated") {
            return undefined;
        } else {
            throw e;
        }
    }
}


export function getLocalStorageUser() {
    if (!localStorage) return undefined;
    const cachedDto = localStorage.getItem("me");
    if (!cachedDto || cachedDto.length == 0) return undefined;
    const dto = JSON.parse(cachedDto) as LocalStorageUser;
    return dto;
}

export function saveLocalStorageUser(obj: LocalStorageUser) {
    if (!localStorage) return undefined;
    const serialised = JSON.stringify(obj);
    localStorage.setItem("me", serialised);
    console.log('Saved me in localstorage');
}


export async function clearLocalStorageUser() {
    if (!localStorage) return undefined;
    console.log('Removing me from localstorage');
    localStorage.removeItem("me");
}


export async function registerWithCognito(request: { email: string, password: string, displayName: string }) {
    await AwsAuth.signUp({
        username: request.email.toLowerCase(),
        password: request.password,
        attributes: {
            email: request.email.toLowerCase(),
            name: request.displayName,
        }
    });
}

export async function confirmRegistration(email: string, code: string) {
    await AwsAuth.confirmSignUp(email, code);
}

export async function resendRegisterCode(email: string) {
    await AwsAuth.resendSignUp(email);
}


export async function resendVerificationEmail(username: string) {
    await AwsAuth.resendSignUp(username.toLowerCase());
}

export async function requestPasswordResetCode(username: string) {
    await AwsAuth.forgotPassword(username.toLowerCase());
}

export async function submitNewPassword(username: string, code: string, password: string) {
    await AwsAuth.forgotPasswordSubmit(username.toLowerCase(), code, password)
}

export async function changePasswordWithPrevious(username: string, oldPassword: string, newPassword: string) {
    const user = await AwsAuth.currentAuthenticatedUser();
    await AwsAuth.changePassword(user, oldPassword, newPassword);
}

export function signOut() {
    AwsAuth.signOut();
    clearLocalStorageUser();
}