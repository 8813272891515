import React, { } from "react"
import './CreateTeamHintArrow.scss';
import { childClassClosure, ClassableProps, baseClassAnd } from "@jcharlesworthuk/your-mum-core/dist/functions";

const baseClass = 'create-team-hint-arrow';
const childClass = childClassClosure(baseClass);

type Props = ClassableProps & {
}

const CreateTeamHintArrow: React.FunctionComponent<Props> = (props: Props) => {

    return <div className={baseClassAnd(baseClass, props)}>
        <img className={childClass('img')} src={'/img/show-arrow.svg'} alt="Add user" />
        <h3 className={childClass('title')}>Create your first team</h3>
    </div>
}

export default CreateTeamHintArrow;