import * as React from 'react';
import './ChangeQuestionTextForm.scss';
import { childClassClosure, ClassableProps, baseClassAnd } from '@jcharlesworthuk/your-mum-core/dist/functions';
import { TextField, Button } from '@material-ui/core';
import { ButtonState } from '@jcharlesworthuk/your-mum-frontend/models';
import { SurveyTemplate } from '@Models/SurveyTemplate';
import { useChangeSurveyTemplate } from '@Hooks';
import { SurveyTemplateDto } from '@Dtos';
import { UpdateSurveyTemplateWriteModel } from '@ApiModels';

const baseClass = 'question-text-form';
const childClass = childClassClosure(baseClass);


type Props = ClassableProps & {
  onCancel: () => void;
  onConfirmed: () => void;
  template: SurveyTemplate;
  fieldName: keyof UpdateSurveyTemplateWriteModel;
};


const ChangeQuestionTextForm: React.FunctionComponent<Props> = (props) => {
  const form = useChangeSurveyTemplate(props.template, props.fieldName);
  const inputVariant = 'outlined';

  return <form className={baseClassAnd(baseClass, props)} noValidate autoComplete="off" onSubmit={form.handleSubmitForm(props.onConfirmed)}>
    <h2 className={childClass('title')}>Edit this question</h2>
    <TextField id="text"
      className={childClass('text')}
      label="Question Text"
      margin="normal"
      fullWidth
      variant={inputVariant}
      autoFocus 
      value={form.fields.newQuestionText || ''}
      onChange={form.registerInputChangeHandler('newQuestionText')} />

{/* TODO If RESULTS EXIST: WARN: editing the context of a question too much can affect the accuracy of your result trend */}
    {form.error && <p className={childClass('error-message')}>{form.error}</p>}
    <div className={childClass('buttons')}>
      <Button size="large" onClick={() => props.onCancel()}>Cancel</Button>
      <Button variant="contained" size="large" color="primary" type="submit" disabled={form.buttonState !== ButtonState.Enabled}>Update</Button>
    </div>
  </form>
}

export default ChangeQuestionTextForm;