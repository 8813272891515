import { FullSubscriptionInfoDto } from "models/dtos/FullSubscriptionInfoDto";
import { DatedPayment } from "./DatedPayment";

export class FullSubscriptionInfo {

    constructor(public latestInvoice: DatedPayment & { link: string } | null,
        public nextInstallment: DatedPayment | null) {

        }

    static fromDto(dto: FullSubscriptionInfoDto) {
        return new FullSubscriptionInfo(dto.latestInvoice ? {
            date: new Date(dto.latestInvoice.date),
            amount: dto.latestInvoice.amount,
            link: dto.latestInvoice.link
        } : null,
        dto.nextInstallment ?
        {
            date: new Date(dto.nextInstallment.date),
            amount: dto.nextInstallment.amount
        } : null);
    }
}