import * as React from 'react';
import './OrgSettingsEditorForm.scss';
import { childClassClosure, ClassableProps, baseClassAnd } from '@jcharlesworthuk/your-mum-core/dist/functions';
import { FormControl, InputLabel, Select, MenuItem, TextField, Button } from '@material-ui/core';
import { OrganisationManagerContext, OrganisationManagerActionType, LoggedInContext, SurveyFrequencySettingsContext, SurveyFrequencySettingsActionType, LatestSurveyResultContext } from '@Contexts';
import { useContext, useState } from 'react';
import { SurveyFrequency, SurveyDay } from '@Models/SurveyFrequency';
import { PrivateApiClient } from '../../ApiClient';

const baseClass = 'org-settings-editor-form';
const childClass = childClassClosure(baseClass);


type Props = ClassableProps & {
};

const frequencies = ['Weekly', 'Bi-Weekly', 'Monthly'];
const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']


const OrgSettingsEditorForm: React.FunctionComponent<Props> = (props) => {
  const { currentUser } = useContext(LoggedInContext);
  const { organisation, dispatch: orgDispatch } = useContext(OrganisationManagerContext);
  const { latestResult } = useContext(LatestSurveyResultContext);
  const { frequencySettings, dispatch: frequencySettingsDispatch } = useContext(SurveyFrequencySettingsContext);
  
  const [ editingOrgName, setEditingOrgName ] = useState<string | undefined>(undefined);
  const changeOrgName = (newName: string) => {
    async function save() {
      setEditingOrgName(undefined);
      await new PrivateApiClient().withUser(currentUser).updateOrganisation({
        name: newName
      });
      orgDispatch({ type: OrganisationManagerActionType.NameChanged, stringValue: newName });
    }
    save();
  }

  const changeSurveyOption = (opts: {surveyFrequency?: SurveyFrequency, surveyDay?: SurveyDay, openLength?: number}) => {
    async function save() {
      setEditingOrgName(undefined);
      const writeModel = opts.openLength
      ? { openLength: opts.openLength }
      : { surveyDay: opts.surveyDay || frequencySettings.day,
        surveyFrequency: opts.surveyFrequency || frequencySettings.frequency
      };
      await new PrivateApiClient().withUser(currentUser).updateSurveyTemplate(writeModel);
      frequencySettingsDispatch({ ...opts,
        latestSurveyDate: latestResult ? new Date(latestResult.result.date) : null,
        type: SurveyFrequencySettingsActionType.SurveyOptionChanged });
    }
    save();
  }

  return <form className={baseClassAnd(baseClass, props)} noValidate autoComplete="off" onSubmit={e => e.preventDefault()}>
    {editingOrgName
      ? <div className={childClass('text-edit-inline')}>
      <TextField id="orgName"
        className={childClass('org-name-box')}
        label="Organisation Name"
        margin="normal"
        fullWidth={true}
        variant='outlined'
        value={editingOrgName}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEditingOrgName(e.target.value)}
      />
      <Button size="large" color="primary" variant="contained" onClick={() => changeOrgName(editingOrgName)}>Save</Button>
      <Button size="large" onClick={() => setEditingOrgName(undefined)}>Cancel</Button>
      </div>
      : <h1 className={childClass('title')}>
        {organisation.name}<span className={childClass('edit-text')} onClick={() => setEditingOrgName(organisation.name)}>Edit</span>
      </h1>}

      <div className={childClass('survey-dropdowns')}>
    <FormControl variant="outlined" className={childClass('select')} fullWidth margin='normal'>
      <InputLabel id="survey-frequency-label">
        Survey Frequency
        </InputLabel>
      <Select
        labelId="survey-frequency-label"
        id="survey-frequency"
        autoWidth={false}
        value={frequencySettings.frequency}
        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => changeSurveyOption({ surveyFrequency: e.target.value as SurveyFrequency })}
        labelWidth={150}
      >
        {frequencies.map(freq => <MenuItem value={freq.toLowerCase()} key={freq}>{freq}</MenuItem>)}
      </Select>
    </FormControl>

    <FormControl variant="outlined" className={childClass('select')} fullWidth margin='normal'>
      <InputLabel id="survey-day-label">
        Survey Day
        </InputLabel>
      <Select
        labelId="survey-day-label"
        id="survey-day"
        autoWidth={false}
        value={frequencySettings.day}
        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => changeSurveyOption({ surveyDay: e.target.value as SurveyDay })}
        labelWidth={150}
      >
        {days.map(day => <MenuItem value={day} key={day}>{day}</MenuItem>)}
      </Select>
    </FormControl>

    <FormControl variant="outlined" className={childClass('select')} fullWidth margin='normal'>
      <InputLabel id="survey-frequency-label">
        Employee Answer Time
        </InputLabel>
      <Select
        labelId="open-length-label"
        id="open-length"
        autoWidth={false}
        value={frequencySettings.openLength.toString()}
        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => changeSurveyOption({ openLength: parseInt(e.target.value) })}
        labelWidth={150}
      >
        {[1,2,3].map(item => <MenuItem value={item.toString()} key={item}>{item} Days</MenuItem>)}
      </Select>
    </FormControl>

    </div>
  </form>
}

export default OrgSettingsEditorForm;