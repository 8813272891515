import React from "react";
import { childClassClosure, ClassableProps, baseClassAnd } from "@jcharlesworthuk/your-mum-core/dist/functions";
import CircleIndicator from "./CircleIndicator";

type Props = ClassableProps & {
    value: number
}

const ScoreCircle: React.FunctionComponent<Props> = props => {
    return <CircleIndicator className={props.className} value={props.value} maximum={10} />
  }
  export default ScoreCircle;