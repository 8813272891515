import { SurveyToAnswerDto } from "@Dtos";
import { QuestionToAnswer } from "./QuestionToAnswer";
import { NewSurveyAnswersWriteModel } from "@ApiModels";
import { PublicOrganisation } from "./PublicOrganisation";

export class SurveyToAnswer {
    constructor(
        public id: string,
        public templateId: string,
        public questions: QuestionToAnswer[],
        public organisation: PublicOrganisation,
        public closesOn: Date) {
    }

    static fromDto(dto: SurveyToAnswerDto) {
        return new SurveyToAnswer(dto.id, dto.templateId, dto.questions.map(QuestionToAnswer.fromDto), PublicOrganisation.fromDto(dto.organisation), new Date(dto.closesOn));
    }

    static getAnswers(survey: SurveyToAnswer, secretId: string, teamId?: string): NewSurveyAnswersWriteModel {
        return {
          surveyToAnswerId: survey.id,
          secretId,
          teamId,
          surveyTemplateId: survey.templateId,
          answers: survey.questions.reduce((acc, cur) => {
              acc[cur.templateId] = cur.answer;
              return acc;
          }, {})
        }
    }
}