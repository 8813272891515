import * as React from 'react';
import './BillingSubscriptionEditor.scss';
import { childClassClosure, ClassableProps, baseClassAnd, formatDateOnly } from '@jcharlesworthuk/your-mum-core/dist/functions';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Accordion, AccordionSummary, AccordionDetails, Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Paper } from '@material-ui/core';
import { OrganisationManagerContext, LoggedInContext, OrganisationManagerActionType } from '@Contexts';
import { useContext, useState } from 'react';
import { PrivateApiClient } from '../../ApiClient';
import { FullSubscriptionInfo } from '@Models/FullSubscriptionInfo';
import Spinner from '@jcharlesworthuk/your-mum-frontend/components/indicators/Spinner';
import { Link } from 'gatsby';
import LinkButton from '../buttons/LinkButton';

const baseClass = 'billing-subscription-editor';
const childClass = childClassClosure(baseClass);


type Props = ClassableProps & {
};

const BillingSubscriptionEditor: React.FunctionComponent<Props> = (props) => {
  const { currentUser } = useContext(LoggedInContext);
  const { organisation, dispatch: orgDispatch } = useContext(OrganisationManagerContext);
  const [subscriptionInfo, setSubscriptionInfo] = useState<FullSubscriptionInfo | null | 'loading'>(null);
  const [requestingCancellation, setRequestingCancellation] = useState<boolean>(false);

  const onAccordionToggle = (event: React.ChangeEvent<{}>, expanded: boolean) => {
    async function loadSubscription() {
      setSubscriptionInfo('loading');
      const loadedData = await new PrivateApiClient().withUser(currentUser).getSubscriptionInfo();
      setSubscriptionInfo(loadedData);
    }
    if (expanded && !subscriptionInfo && subscriptionInfo !== 'loading') {
      loadSubscription();
    }
  }

  const handleCancelRequestClick = () => {
    setRequestingCancellation(true);
  }

  const handleCancelConfirmClick = () => {
    setRequestingCancellation(false);
    new PrivateApiClient().withUser(currentUser).cancelSubscription();
    orgDispatch({
      type: OrganisationManagerActionType.SubscriptionStatusChanged,
      stringValue: 'Ending'
    });
    setSubscriptionInfo(new FullSubscriptionInfo((subscriptionInfo as FullSubscriptionInfo).latestInvoice, null));
  }

  if (organisation.subscription.status === 'Free') {
    return <Paper variant="outlined" className={baseClassAnd(baseClass, props, 'aspanel')}>

      <p className={childClass('description')}>Your Plan: <strong>{organisation.subscription.description}</strong></p>
      <p><LinkButton className={childClass('linkbutton')} to={'/account/change-subscription'} label="Upgrade Now" /></p>
    </Paper>
  }

  return <Accordion className={baseClassAnd(baseClass, props)} onChange={onAccordionToggle}>
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      aria-controls="panel-content"
      id="panel-header"
    >
      <p className={childClass('description')}>Your Plan: <strong>{organisation.subscription.description}</strong> <small>{organisation.subscription.price}</small></p>
    </AccordionSummary>
    <AccordionDetails className={childClass('details')}>
      {subscriptionInfo && subscriptionInfo !== 'loading'
        ? <>
          {subscriptionInfo.latestInvoice && <div className={childClass('billing-row')}>
            <h5 className={childClass('billing-label')}>Latest Invoice</h5>
            <div className={childClass('billing-content')}>
              <p className={childClass('billing-text')}>{subscriptionInfo.latestInvoice.amount} on {subscriptionInfo.latestInvoice.date.toLocaleDateString()}</p>
              <a className={childClass('billing-btn')}
                href={subscriptionInfo.latestInvoice.link} target="_blank">View Invoice</a>
            </div>
          </div>}
          { subscriptionInfo.nextInstallment &&<div className={childClass('billing-row')}>
            <h5 className={childClass('billing-label')}>Next Payment Due</h5>
            <div className={childClass('billing-content')}>
              <p className={childClass('billing-text')}>{subscriptionInfo.nextInstallment.amount} on {subscriptionInfo.nextInstallment.date.toLocaleDateString()}</p>
            </div>
          </div> }
          { organisation.subscription.status === 'Paid' &&<div className={childClass('billing-row', 'seperate')}>
            <Button variant="contained" size="large" onClick={handleCancelRequestClick}>Cancel Subscription</Button>
          </div> }
          { organisation.subscription.status === 'Ending' &&<div className={childClass('billing-row', 'seperate')}>
            Your subscription will be terminated at the end of the current billing period. If you do not mean to do this then <Link to='/contact-us/'>let us know</Link>
          </div> }
          <div className={childClass('billing-row', 'seperate', 'smaller')}>For billing queries contact <a href="mailto:support@acorde.app?subject=Billing">support@acorde.app</a></div>
        </>
        : <Spinner />}
    </AccordionDetails>
    <Dialog
      open={requestingCancellation}
      onClose={() => setRequestingCancellation(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{"Cancel Subscription"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Are you sure you want to cancel your subscription from the end of this billing period?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setRequestingCancellation(false)} autoFocus>
          No
          </Button>
        <Button onClick={handleCancelConfirmClick} color="secondary">
          Yes
          </Button>
      </DialogActions>
    </Dialog>
  </Accordion>
}

export default BillingSubscriptionEditor;