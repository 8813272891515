import { ScaleType } from "./ScaleType";
import { Metric } from "./Metric";
import { QuestionTemplateDto, QuestionToAnswerDto } from "@Dtos";
import { QuestionTokens } from "./QuestionTokens";

export class QuestionTemplate {
    constructor(public id: string,
        public metric: Metric, 
        public text: string, 
        public scale: ScaleType,
        public isInverse: boolean) {
    }

    static createFromDto(dto: QuestionTemplateDto, metric: Metric) {
        return new QuestionTemplate(dto.id, metric, dto.text, <ScaleType>dto.scale, dto.isInverse)
    }

    static loadFromTsvText(text: string, tokens?: QuestionTokens) {
        const metrics: { [id: string] :Metric} = {};
        const questions: { [id: string]: QuestionTemplate } = {};
        let readingMetrics = true;
        for(let line of text.split('\n')) {
            if (line.trim() === '--questions') {
                readingMetrics = false;
            } else if (readingMetrics) {
                const fields = line.split('\t');
                metrics[fields[0]] = new Metric(fields[0], fields[1], fields[2].trim());
            } else {
                const fields = line.split('\t');
                const questionText = fields[2].trim();
                const replaced = tokens ? tokens.replace(questionText) : questionText;
                const isInverse = fields[4].trim() === '1';
                questions[fields[0]] = new QuestionTemplate(fields[0], metrics[fields[1]], replaced, <ScaleType>parseInt(fields[3].trim()), isInverse);
            }
        }

        return { metrics, questions };
    }

    toQuestionToAnswerDto(): QuestionToAnswerDto {
        return {
            templateId: this.id,
            text: this.text,
            scale: this.scale,
            isInverse: this.isInverse == true ? true : undefined,
            metricId: this.metric.id
        }
    }
}

export const marqueeQuestionTemplateId = "$$";
export const additionalCommentsQuestionTemplateId = "££";
