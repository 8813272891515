import * as React from 'react';
import './TeamSetupPanel.scss';
import { Link, navigate } from 'gatsby';
import { childClassClosure, baseClassAnd, ClassableProps, toggleOnClick } from '@jcharlesworthuk/your-mum-core/dist/functions';
import { Employee } from '@Models/Employee';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { useContext, useState } from 'react';
import { EmployeeManagerContext, EmployeeManagerActionType, LoggedInContext } from '@Contexts';
import PersonIcon from '@material-ui/icons/Person';
//import { Link } from 'gatsby-plugin-material-ui';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { Button, Menu, MenuItem, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Typography, Tooltip, Fab, Drawer, IconButton, Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import { PrivateApiClient } from '../../ApiClient';
import { EmployeeStatus } from '@Models/EmployeeStatus';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import IconAndText from "@jcharlesworthuk/your-mum-frontend/components/texty/IconAndText";
import UpsertEmployeeForm from '../forms/UpsertEmployeeForm';
import InitialCircle from '../indicators/InitialCircle';
import AddIcon from '@material-ui/icons/Add';
import { Team } from '@Models/Team';
import SetTeamManagerForm from '../forms/SetTeamManagerForm';
import EditTeamInfoForm from '../forms/EditTeamInfoForm';
import WarningIcon from '@material-ui/icons/Warning';
import { CurrentUser } from '@Models/CurrentUser';

const baseClass = 'team-setup-panel';
const childClass = childClassClosure(baseClass);


type Props = ClassableProps & {
  team: Team,
  manager: Employee | null,
  members: Employee[],
  onRequestDeleteUser: (user: Employee) => void;
  onRequestDeleteTeam: (team: Team) => void;
  possibleManagers: Employee[];
  currentUser: CurrentUser
};

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  }
});

const HelperTooltip = withStyles((theme) => ({
  tooltip: {
    fontSize: 12,
    maxWidth: 'none',
  },
}))(Tooltip);

const columnCount = 5;

type DialogFlags = {
  newUser: boolean,
  editUser: null | Employee,
  setManager: boolean,
  editTeamInfo: boolean
};

type ContextMenuFlags = {
  userRowDots: null | { anchorEl: HTMLElement, user: Employee },
  teamInfoDots: null | HTMLElement
};

const emptyDialogFlags: DialogFlags = {
  newUser: false,
  editUser: null,
  setManager: false,
  editTeamInfo: false
};

const emptyContextMenuFlags: ContextMenuFlags = {
  userRowDots: null,
  teamInfoDots: null
}

const TeamSetupPanel: React.FunctionComponent<Props> = props => {
  const classes = useStyles();
  //const [editingUserNode, setEditingUserNode] = useState<null | { anchorEl: HTMLElement, user: Employee }>(null);
  const [dialogs, setDialogs] = useState<DialogFlags>(emptyDialogFlags);
  const { currentUser } = useContext(LoggedInContext);
  const [contextMenus, setContextMenus] = useState<ContextMenuFlags>(emptyContextMenuFlags);
  const [requestingDeleteUser, setRequestingDeleteUser] = useState<null | Employee>(null);
  const [requestingDeleteUserNameLatch, setRequestingDeleteUserNameLatch] = useState<string>('');
  const [requestingDeleteTeam, setRequestingDeleteTeam] = useState<boolean>(false);

  const openUserContextMenu = (user: Employee) => {
    return (event: React.MouseEvent<HTMLButtonElement>) => {
      setContextMenus({
        ...emptyContextMenuFlags,
        userRowDots: {
          user,
          anchorEl: event.currentTarget
        }
      });
    };
  }

  const openTeamInfoContextMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    setContextMenus({
      ...emptyContextMenuFlags,
      teamInfoDots: event.currentTarget
    });
  }

  const closeAllContextMenus = () => {
    setContextMenus(emptyContextMenuFlags);
  }

  const closeAllDialogs = () => {
    setDialogs(emptyDialogFlags);
  }

  const openDialog = (key: keyof DialogFlags) => () => {
    setDialogs({ ...emptyDialogFlags, [key]: true })
  }


  const handleEditUserMenuClick = () => {
    if (!contextMenus.userRowDots || !contextMenus.userRowDots.user) return;
    if (contextMenus.userRowDots.user.email === currentUser.email) {
      navigate("/office/profile");
    } else {
      setDialogs({
        ...emptyDialogFlags,
        editUser: contextMenus.userRowDots.user
      })
    }
    closeAllContextMenus();
  }

  const handleEditTeamInfoMenuClick = () => {
    setDialogs({
      ...emptyDialogFlags,
      editTeamInfo: true
    })
    closeAllContextMenus();
  }

  const handleDeleteUserMenuClick = () => {
    if (!contextMenus.userRowDots || !contextMenus.userRowDots.user) return;
    setRequestingDeleteUserNameLatch(contextMenus.userRowDots.user.fullName);
    setRequestingDeleteUser(contextMenus.userRowDots.user);
    closeAllContextMenus();
  }

  const handleDeleteUserConfirmClick = () => {
    if (!requestingDeleteUser) return;
    props.onRequestDeleteUser(requestingDeleteUser);
    setRequestingDeleteUser(null);
  }

  const handleDeleteTeamMenuClick = () => {
    if (!contextMenus.teamInfoDots) return;
    setRequestingDeleteTeam(true);
    closeAllContextMenus();
  }

  const handleDeleteTeamConfirmClick = () => {
    if (!requestingDeleteTeam) return;
    props.onRequestDeleteTeam(props.team);
    setRequestingDeleteTeam(false);
  }



  return <Accordion className={baseClassAnd(baseClass, props)}>
    <AccordionSummary
      className={childClass('panel-summary')}
      expandIcon={<ExpandMoreIcon />}
    >
      <h2 className={childClass('title')}>{props.team.name}
        <Button className={childClass('team-edit-button')} onFocus={(event) => event.stopPropagation()} aria-controls="simple-menu" aria-haspopup="true" onClick={openTeamInfoContextMenu}>
          <MoreHorizIcon />
        </Button></h2>
      {props.manager ? <IconAndText className={childClass('summary-detail')} icon={PersonIcon}>{props.manager.fullName}</IconAndText> : <IconAndText className={childClass('summary-detail', 'warning')} icon={WarningIcon}>NO MANAGER!</IconAndText>}
    </AccordionSummary>
    <AccordionDetails className={childClass('panel-contents')}>
      <TableContainer>
        <Table className={classes.table + ' ' + baseClass} aria-label="team table">
          <TableBody>
            <TableRow key="Manager-Header">
              <TableCell component="th" scope="row" colSpan={columnCount}>
                <h4 className={childClass('group-heading')}><HelperTooltip title="Managers can log in and see anonymous feedback submitted by their team" placement="right-start" arrow><span>Manager</span></HelperTooltip></h4>
              </TableCell>
            </TableRow>
            {props.manager ?
              <TableRow key="Manager">
                <TableCell scope="row">
                  <InitialCircle user={props.manager} />
                </TableCell>
                <TableCell className='pii'>{props.manager.fullName}</TableCell>
                <TableCell className='pii'>{props.manager.email}</TableCell>
                <TableCell className='pii'><Tooltip title="Add" aria-label="add"><span>{EmployeeStatus[props.manager.status]}</span></Tooltip></TableCell>
                <TableCell align="right">
                  <Button aria-controls="simple-menu" aria-haspopup="true" onClick={openUserContextMenu(props.manager)}>
                    <MoreHorizIcon />
                  </Button>
                </TableCell>
              </TableRow>
              : <TableRow key="Add-Manager">
                <TableCell scope="row" colSpan={columnCount} className={childClass('button-table-row')}>
                  <Button variant="contained" size="large" color="primary" type="button" onClick={openDialog("setManager")}><AddIcon /> Add Manager</Button>
                </TableCell>
              </TableRow>}
          </TableBody>
          <TableBody>
            <TableRow key="MembersTitle">
              <TableCell component="th" scope="row" colSpan={columnCount}>
                <h4 className={childClass('group-heading')}><HelperTooltip title="Every week, team members are emailed a link to submit their feedback.  They do not need an account with Acorde" placement="right-start" arrow><span>Team Members</span></HelperTooltip></h4>
              </TableCell>
            </TableRow>
            {props.members.map(user => <TableRow key={user.email}>
              <TableCell component="th" scope="row">
                <InitialCircle user={user} />
              </TableCell>
              <TableCell className='pii'>{user.fullName}</TableCell>
              <TableCell className='pii'>{user.email}</TableCell>
              <TableCell className='pii'>{EmployeeStatus[user.status]}</TableCell>
              <TableCell align="right">
                <Button aria-controls="simple-menu" aria-haspopup="true" onClick={openUserContextMenu(user)}>
                  <MoreHorizIcon />
                </Button>
              </TableCell>
            </TableRow>
            )}
          </TableBody>
        </Table>
        <Menu
          id="user-edit-menu"
          anchorEl={contextMenus && contextMenus.userRowDots ? contextMenus.userRowDots.anchorEl : null}
          keepMounted
          open={Boolean(contextMenus.userRowDots)}
          onClose={closeAllContextMenus}
          classes={{
            list: childClass('context-menu')
          }}
        >
          <MenuItem onClick={handleEditUserMenuClick}><EditIcon />Edit</MenuItem>
          <MenuItem onClick={handleDeleteUserMenuClick}><DeleteIcon />Delete</MenuItem>
        </Menu>
        <Menu
          id="team-edit-menu"
          anchorEl={contextMenus && contextMenus.teamInfoDots ? contextMenus.teamInfoDots : null}
          keepMounted
          open={Boolean(contextMenus.teamInfoDots)}
          onClose={closeAllContextMenus}
          classes={{
            list: childClass('context-menu')
          }}
        >
          <MenuItem onClick={handleEditTeamInfoMenuClick}><EditIcon />Edit Team</MenuItem>
          <MenuItem onClick={handleDeleteTeamMenuClick}><DeleteIcon />Delete Team</MenuItem>
        </Menu>
        <Dialog
          open={Boolean(requestingDeleteUser)}
          onClose={() => setRequestingDeleteUser(null)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Delete User"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to delete the user '{requestingDeleteUserNameLatch}'?
          </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setRequestingDeleteUser(null)} autoFocus>
              Cancel
          </Button>
            <Button onClick={handleDeleteUserConfirmClick} color="secondary">
              Delete
          </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={Boolean(requestingDeleteTeam)}
          onClose={() => setRequestingDeleteTeam(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Delete User"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to delete the team '{props.team.name}'?<br />
              {props.members.length > 0 && <span className={childClass('dialog-warn')}>This will also remove {props.members.length} team members<br /></span>}
              {(props.manager && props.manager.email !== props.currentUser.email) && <span className={childClass('dialog-warn')}>If this was their only team, {props.manager.fullName} will no longer have access to Acorde</span>}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setRequestingDeleteTeam(false)} autoFocus>
              Cancel
          </Button>
            <Button onClick={handleDeleteTeamConfirmClick} color="secondary">
              Delete
          </Button>
          </DialogActions>
        </Dialog>
      </TableContainer>
      <div className={childClass('buttons-row')}>
        <Button variant="contained" size="large" color="primary" type="button" onClick={openDialog("newUser")}><AddIcon /> Add To Team</Button>
      </div>
    </AccordionDetails>
    <Drawer anchor="right" open={dialogs.newUser} onClose={closeAllDialogs} PaperProps={{ className: childClass('draw-paper') }}>
      <UpsertEmployeeForm
        onCancel={closeAllDialogs}
        onConfirmed={closeAllDialogs}
        employee={null}
        teamId={props.team.id}
      />
    </Drawer>
    <Drawer anchor="right" open={Boolean(dialogs.editUser)} onClose={closeAllDialogs} PaperProps={{ className: childClass('draw-paper') }}>
      <UpsertEmployeeForm
        onCancel={closeAllDialogs}
        onConfirmed={closeAllDialogs}
        employee={dialogs.editUser}
        teamId={null} />
    </Drawer>
    <Drawer anchor="right" open={dialogs.setManager} onClose={closeAllDialogs} PaperProps={{ className: childClass('draw-paper') }}>
      <SetTeamManagerForm
        onCancel={closeAllDialogs}
        onConfirmed={closeAllDialogs}
        oldManager={props.manager}
        possibleManagers={props.possibleManagers}
        team={props.team} />
    </Drawer>
    <Drawer anchor="right" open={dialogs.editTeamInfo} onClose={closeAllDialogs} PaperProps={{ className: childClass('draw-paper') }}>
      <EditTeamInfoForm
        onCancel={closeAllDialogs}
        onConfirmed={closeAllDialogs}
        team={props.team} />
    </Drawer>


  </Accordion>
}

export default TeamSetupPanel;