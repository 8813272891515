import { OrganisationDto } from "@Dtos";
import { BasicSubscriptionInfo } from "./BasicSubscriptionInfo";
import { Team } from "./Team";

export class Organisation {
    constructor(
            public id: string, 
            public name: string, 
            public logoUrl: string | undefined,
            public teams: { [teamId: string]: Team},
            public subscription: BasicSubscriptionInfo) {
    }

    public cloneWithNewName(newName: string) {
        return new Organisation(this.id, newName, this.logoUrl, this.teams, this.subscription);
    }
    public cloneWithNewLogo(newLogo: string) {
        return new Organisation(this.id, this.name, newLogo, this.teams, this.subscription);
    }

    public cloneWithNewSubscription(subscription: BasicSubscriptionInfo) {
        return new Organisation(this.id, this.name, this.logoUrl, this.teams, subscription);
    }

    get teamArray() {
        return Object.keys(this.teams).map(x => this.teams[x]);
    }

    teamsManagedBy(managerId: string) {
        return Object.keys(this.teams).filter(teamId => this.teams[teamId].managerId === managerId);
    }

    public static fromDto(dto: OrganisationDto) {
        const teams: { [teamId: string]: Team} = {};
        for(let teamId in dto.teams) {
            teams[teamId] = new Team(teamId, dto.teams[teamId].name, dto.teams[teamId].managerId);
        }
        return new Organisation(dto.id, dto.name, dto.logoUrl, teams, BasicSubscriptionInfo.fromDto(dto.subscription));
    }

    public static createDefault(id: string, withName: string) {
        return new Organisation(id, withName, '', {}, new BasicSubscriptionInfo('xxx', 'startup', '$tbc', 'Pending'));
    }

}

