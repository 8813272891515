import * as React from "react";
import { forwardRef } from "react";
import "./HandwritingAnimation.scss";
import { HandwritingMessageSvg } from "./HandwritingMessageSvg";
import { childClassClosure, ClassableProps, baseClassAnd } from "@jcharlesworthuk/your-mum-core/dist/functions";

const baseClass = 'handwriting-animation';
const childClass = childClassClosure(baseClass);

export type Ref = HTMLDivElement;

const HandwritingAnimation: React.RefForwardingComponent<Ref, ClassableProps> = (props: ClassableProps, ref )  => {
  return <div ref={ref} className={baseClassAnd(baseClass, props)}>
      <HandwritingMessageSvg className={childClass('svg')} />
      </div>
}

export default forwardRef<Ref, ClassableProps>(HandwritingAnimation);