import * as React from 'react';
import './OrganisationSetupForm.scss';
import { useState, useEffect } from 'react';
import { childClassClosure, ClassableProps, baseClassAnd } from '@jcharlesworthuk/your-mum-core/dist/functions';
import { PrivateApiClient } from '../../ApiClient';
import { loadStripe } from '@stripe/stripe-js/pure'; // The "pure" at the end here stops stripe doing its _fraud detection_ bollocks on every page.
import SubscriptionSelector from '../selling/SubscriptionSelector';
import { BillingPlanIdentitiers } from '@Models/BillingPlanIdentitiers';
import frontEndConfig from '../../frontEndConfig';
import { navigate } from 'gatsby';
import { CurrentUser } from '@Models/CurrentUser';

const baseClass = 'stripe-subscription';
const childClass = childClassClosure(baseClass);

type Props = ClassableProps & {
  user: CurrentUser;
};

let loadedStripe: any | null = null;


const StripeSubscriptionFlow: React.FunctionComponent<Props> = props => {
  const [error, setError] = useState<string | null>(null);

  //TODO put a useEffect() and immediately redirect to the plan if there is one in the query string

  useEffect(() => {
    loadStripe(frontEndConfig.stripeApiKey).then(s => {
      loadedStripe = s;
    });
  }, []);

  async function redirectToStripeCheckout(planIdentifier: keyof BillingPlanIdentitiers) {
    try {
      if (!loadedStripe) throw new Error('Failed to load stripe!');
      const stripe = loadedStripe!;
      const planId = frontEndConfig.billingPlanIds[planIdentifier];
      const sessionId = await new PrivateApiClient().createCheckoutSession({
        email: props.user.email,
        planId: planId,
        orgId: props.user.orgId
      });
      const stripeResult = await stripe.redirectToCheckout({
        // Make the id field from the Checkout Session creation API response
        // available to this file, so you can provide it as parameter here
        // instead of the {{CHECKOUT_SESSION_ID}} placeholder.
        sessionId: sessionId
      })
      console.log(stripeResult);
    } catch (e) {
      console.error(e);
      debugger;
      setError(e.message || 'There was an error with the subscription');
    }
  }

  async function continueWithFreePlan() {
    try {
      await new PrivateApiClient().withUser(props.user).updateSubscription({ status: 'Free'});
      navigate("/office/dashboard");
    } catch (e) {
      console.error(e);
      debugger;
      setError(e.message || 'There was an error with selecting the free plan');
    }
  }
  //todo createSubscription();

  const selectPlan = (planIdentifier: keyof BillingPlanIdentitiers | 'free') => {
    if (planIdentifier !== 'free') {
      redirectToStripeCheckout(planIdentifier);
    } else {
      continueWithFreePlan();
    }
    return true;
  }
  return <div className={baseClassAnd(baseClass, props)}>
    <SubscriptionSelector onSelect={selectPlan} />
    {error && <p className={childClass('error-message')}>{error}</p>}
  </div>
}


export default StripeSubscriptionFlow;