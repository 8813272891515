import * as React from 'react';
import './LikertBarChart.scss';
import { childClassClosure, ClassableProps, baseClassAnd } from '@jcharlesworthuk/your-mum-core/dist/functions';
import { LikertMetricResult } from '@Models/LikertMetricResult';
import { useRef, useEffect, useState } from 'react';
import { FaceRbf } from "@jcharlesworthuk/your-mum-frontend/svg/FaceRbf";
import { FaceHappy } from "@jcharlesworthuk/your-mum-frontend/svg/FaceHappy";
import { FaceNeutral } from "@jcharlesworthuk/your-mum-frontend/svg/FaceNeutral";
import { LikertResult } from '@Models/LikertResult';
import { TeamScoresResult } from '@Models/TeamScoresResult';
import { Tooltip } from '@material-ui/core';
import { ScaleLabelItem } from '@Models/ScaleLabelSet';

// The technical name for this chart is a "diverging stacked bar chart"

const baseClass = 'lbg';
const childClass = childClassClosure(baseClass);

function generateBarFenceposts(result: LikertResult, scaleWidth: number, likertOptions: number[]) {
  //All the values are as a RATIO of scaleWidth
  const centralBarDistanceFromCentreLine = (result.counts[0] / 2) / scaleWidth;
  const reverseOptions = [...likertOptions].reverse() //[2, 1, 0, -1, 2]
  const negativeFenceposts: number[] = [0.5 + centralBarDistanceFromCentreLine];
  // The negative bars
  for (let i = Math.floor((reverseOptions.length - 1) / 2) + 1; i < reverseOptions.length; i++) {
    const barWidth = result.counts[reverseOptions[i]] / scaleWidth;
    negativeFenceposts.push(negativeFenceposts[negativeFenceposts.length - 1] + barWidth);
  }

  const positiveFencePosts: number[] = [0.5 + centralBarDistanceFromCentreLine];
  // The negative bars
  for (let i = Math.floor((reverseOptions.length - 1) / 2) - 1; i >= 0; i--) {
    const barWidth = result.counts[reverseOptions[i]] / scaleWidth;
    positiveFencePosts.push(positiveFencePosts[positiveFencePosts.length - 1] + barWidth);
  }
  negativeFenceposts.reverse();
  const fenceposts = [...negativeFenceposts.map(x => 1 - x), ...positiveFencePosts];
  return fenceposts;
}

function* generateBars(fenceposts: number[]) {
  for (let i = 0; i < fenceposts.length - 1; i++) {
    yield [fenceposts[i], fenceposts[i + 1]];
  }
}

function classNameFromOption(option: number) {
  return option < 0 ? 'bad-' + Math.abs(option) : option > 0 ? 'good-' + Math.abs(option) : 'neutral';
}

type Props = ClassableProps & {
  results: LikertResult[],
  scale: ScaleLabelItem[]
};

/**
 * The "divergence from zero" is the number of respondents centered around the middle point.
 */
function calculateLargestDivergenceFromZero(results: LikertResult[]) {
  return results.reduce((res, item) => item.divergenceFromZero > res ? item.divergenceFromZero : res, 0);
}

const pixelDimensions = {
  width: 500,
  height: 100
}

const LikertBarChart: React.FunctionComponent<Props> = props => {
  let scaleWidth = calculateLargestDivergenceFromZero(props.results);
  if (scaleWidth % 2 !== 0) scaleWidth++;
  const xAxisLineRatios = [0, 0.25, 0.5, 0.75, 1];
  const xAxisLabels = xAxisLineRatios.map(x => Math.abs(x - 0.5) * scaleWidth);
  const likertOptions = Array.from(props.scale.map(x => x.value));
  const likertLabels = Array.from(props.scale.map(x => x.label));

  return <div className={baseClassAnd(baseClass, props)}>
        <ul className={childClass('scale-top-labels')}>
      {props.scale.map((item, i) => <li key={`scale-top-labels-${i}`}><img src={item.iconPath} alt={item.label} /></li>)}
    </ul>
    <svg className={childClass('svg')} viewBox={`0 0 ${pixelDimensions.width} ${pixelDimensions.height}`} style={{height: `${props.results.length * 2}rem`, width: 'calc(100% - 2rem)'}} preserveAspectRatio="none">
      <g className={childClass('grid')}>
        {xAxisLineRatios.map(lineRatio => <line key={lineRatio.toString()} x1={Math.round(lineRatio * pixelDimensions.width)} x2={Math.round(lineRatio * pixelDimensions.width)} y1="0" y2={pixelDimensions.height}></line>)}
      </g>
      <g className={childClass('bars')}>
        {props.results.map((row, i) => {
          const fenceposts = generateBarFenceposts(row, scaleWidth, likertOptions);
          return <g className={childClass('row')} key={`row-${i}`}>
            {Array.from(generateBars(fenceposts)).map(([x1, x2], j) => <Tooltip title={likertLabels[j] + " - " +  row.counts[likertOptions[j]] + (row.counts[likertOptions[j]] == 1 ? ' response': ' responses')} key={`${i}-${j}`} arrow>
              <rect x={Math.round(x1 * pixelDimensions.width)} width={Math.round((x2 - x1) * pixelDimensions.width)} y={((i / props.results.length) + 0.05) * pixelDimensions.height} height={(0.8 / props.results.length) * pixelDimensions.height} className={childClass(classNameFromOption(likertOptions[j]))}></rect>
              </Tooltip>)}
          </g>
        })}
      </g>
    </svg>
    <ul className={childClass('x-axis')}>
      {xAxisLabels.map((x, i) => <li key={`x-label-${i}`}>{x}</li>)}
    </ul>
    {/* <div className={childClass('x-axis-title')}>Responses</div> */}
  </div>
}

export default LikertBarChart;