import * as React from 'react';
import './LinkButton.scss';
import { Link } from 'gatsby';
import { ClassableProps, childClassClosure, baseClassAnd } from '@jcharlesworthuk/your-mum-core/dist/functions';
import { ButtonState, getCssClassFromButtonState } from '@jcharlesworthuk/your-mum-frontend/models';
import Spinner from '@jcharlesworthuk/your-mum-frontend/components/indicators/Spinner';

const baseClass = 'link-button';
const childClass = childClassClosure(baseClass);


type Props = ClassableProps & {
    to: string;
    /**
     * The current state to show the button in
     */
    state?: ButtonState;

    /**
     * The text to show on the button
     */
    label: string;

    type?: "button" | "submit" | "reset";

    allowClickEvenWhenDisabled?: boolean;

    onClick?: () => void;

    style?: 'primary' | 'secondary' | 'grey';

    target?: string;
}

const LinkButton: React.FunctionComponent<Props> = props => {
    const state = props.state || ButtonState.Enabled;
    return <Link
        to={props.to}
        className={`${baseClassAnd(baseClass, props, props.style || 'primary')} ${getCssClassFromButtonState(state)}`}
        onClick={props.onClick}
        target={props.target}>
        <span className={childClass('submit-label', getCssClassFromButtonState(state))}>{props.label}</span>
        {props.state === ButtonState.Working && <Spinner />}
    </Link>
}

export default LinkButton;