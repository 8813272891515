// @ts-check

import { Boot } from "./src/Boot"
import configureAws from "./src/configureAwsAmplify"

export const wrapRootElement = Boot

export const onInitialClientRender = () => {
    configureAws().then(() => {
        console.log("AWS Amplify configured")
    });
}