import React, { useContext } from "react"
import "./BackOfficeHeader.scss";
import { Link } from "gatsby"
import { ClassableProps, baseClassAnd, childClassClosure } from "@jcharlesworthuk/your-mum-core/dist/functions";
import AccountDropdown from "../menus/AccountDropdown";
import { QuestionMarkSvg } from "@jcharlesworthuk/your-mum-frontend/svg/QuestionMarkSvg";
import { HelpIndicatorsContext } from "@Contexts";

const baseClass = 'back-office-header';
const childClass = childClassClosure(baseClass);

type Props = ClassableProps & {
}

const BackOfficeHeader: React.FunctionComponent<Props> = (props: Props) => {
  const { highlightedMenu } = useContext(HelpIndicatorsContext);
  
    return <header className={baseClassAnd(baseClass, props)}>
          <div className={childClass('homelink')}>
            <Link to="/office/dashboard">
              <img src={'/img/logo.svg'} alt='Acorde' />
            </Link>
          </div>
          <nav className={childClass('menu')}>
            <Link to='/using-acorde-as-a-manager/' 
                className={[childClass('menu-link'), childClass('help-button', highlightedMenu === 'Help' ? 'highlighted' : '')].join(' ')}>
                <QuestionMarkSvg />
                <span>Help</span>
            </Link>
            <AccountDropdown className={childClass('menu-link')} />
          </nav>
    </header>
}

export default BackOfficeHeader;