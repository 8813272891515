import * as React from 'react';
import './ParticipationGraph.scss';
import { childClassClosure, baseClassAnd, ClassableProps } from '@jcharlesworthuk/your-mum-core/dist/functions';
import ChartistGraph from 'react-chartist';
import { ILineChartOptions, IBarChartOptions } from 'chartist';

const baseClass = 'participation-graph';
const childClass = childClassClosure(baseClass);

type Props = ClassableProps & {
    participationHistory: { date: Date, participationCount: number} [];
    totalUsersCount: number;
    height?: number;
};



const ParticipationGraph: React.FunctionComponent<Props> = props => {
    var dataPoints = props.participationHistory.map(survey => survey.participationCount);

    var data = {
        labels: props.participationHistory.map(survey => survey.date.toLocaleDateString()),
        series: [
            dataPoints
        ]
    };

    const options: IBarChartOptions = {
        high: props.totalUsersCount,
        low: 0,
        height: props.height,
        axisX: {
            showLabel: true,
            showGrid: true,
            labelInterpolationFnc: function (value, index) {
                return index % 2 === 0 ? value : null;
            }
        }
    };

    //TODO: filter to 8-10 max because the bar width is CSS'd to 10%

    return <div className={baseClassAnd(baseClass, props)}>
        <ChartistGraph data={data} options={options} type='Bar' />
    </div>
}

export default ParticipationGraph;
