import * as React from "react";
import { StaticQuery, graphql, Link } from "gatsby"
import { childClassClosure, baseClassAnd, ClassableProps } from "@jcharlesworthuk/your-mum-core/dist/functions/css-naming";
import "./MarketingSiteFooter.scss";
import { Site } from "@GraphQLModels";
import SubscribeForm from "@jcharlesworthuk/your-mum-frontend/components/forms/SubscribeForm";
import SocialButton from "@jcharlesworthuk/your-mum-frontend/components/social/SocialButton";
import { object } from "prop-types";
import { ISocialPage } from "@jcharlesworthuk/your-mum-frontend/models";
import { OutboundLink } from 'gatsby-plugin-google-analytics';
import { mapMetadataToSocialSites } from "@GraphQLMappers";

const baseClass = 'site-footer';
const childClass = childClassClosure(baseClass);

interface StaticQueryData {
  site?: Site
}

type Props = ClassableProps;



const MarketingSiteFooter: React.FunctionComponent<Props> = (props: Props) => {

  return <StaticQuery
    query={marketingFooterQuery}
    render={(data: StaticQueryData) => {
      return <footer className={baseClassAnd(baseClass, props)}>
        <section className={childClass('engagement-section')}>
          <div className={childClass('engagement-section-container')}>
            <div className={childClass('engagement-section-social')}>
              <div className={childClass('engagement-section-social-text')}>
                <h4>Follow us!</h4>
                <p>Acorde on social media</p>
              </div>
              <ul className={childClass('engagement-section-social-icon-list')}>
                {Array.from(mapMetadataToSocialSites(data.site.siteMetadata.social))
                  .map(page => <li key={page.name}><SocialButton linkComponent={OutboundLink} page={page} /></li>)}
              </ul>
            </div>
            {/* <div className={childClass('engagement-section-subscribe')}>
              <div className={childClass('text-block')}>
                <h4>Subscribe to our newsletter</h4>
                <p>For the latest news and reviews</p>
              </div>
              <SubscribeForm buttonText="Join" className={childClass('mailing-list-form')} />
            </div> */}
            <div className={childClass('engagement-section-guff')}>
              <p>&copy; Acorde 2020</p>
              <p>Icons made by <a href="https://www.flaticon.com/authors/freepik">Freepik</a> from <a href="http://www.flaticon.com/">www.flaticon.com</a></p>
            </div>
          </div>
        </section>
        <section className={childClass('info-section')}>
          <nav className={[childClass('container'), childClass('info-section-nav')].join(' ')}>
            <Link to="/" className='unimportant'>Home</Link>
            <Link to="/privacy-policy">Privacy</Link>
            <Link to="/cookie-policy">Cookies</Link>
            <Link to="/blog">Blog</Link>
            <Link to="/contact-us">Support</Link>
            {/* <span>Icons: <a href="https://www.flaticon.com/" title="Flaticon">flaticon.com</a></span> */}
          </nav>
        </section>
      </footer>
    }
    }
  />
}

export default MarketingSiteFooter;


export const marketingFooterQuery = graphql`
      query MarketingFooterQuery {
        site {
            siteMetadata {
                title
                titleTemplate
                siteUrl
                social {
                  twitter {
                      handle,
                      url
                  }
                  facebook {
                      handle,
                      url
                  }
                }
            }
        }
    }
  `