import Amplify from "@Plugins/amplify-core";
import * as ApiClient from "./ApiClient";

const awsRegion = 'eu-west-2';

export default async function configureAws() {
    await Amplify.configure({
        Auth: {
            region: awsRegion,
            userPoolId: 'eu-west-2_iFHcxkPz4',
            userPoolWebClientId: '2irlsb3cql84aitb5g8mqj0078',
            identityPoolId: 'eu-west-2:7a465122-f047-4832-af20-0bc8b3080bc4',
            oauth: {
                mandatorySignIn: true,
                domain: 'acorde-web-users.auth.eu-west-2.amazoncognito.com',
                scope: ['email', 'openid'],
                responseType: 'code',
                redirectSignIn: 'https://acorde.app',
                redirectSignOut: 'https://acorde.app/logout',
            },
            cookieStorage: {
                domain: __GATSBY_ENV__  === 'develop' ? 'localhost' : 'acorde.app',
                path: '/',
                expires: 365,
                secure: false //TODO make true
            },
        },
        API: {
            endpoints: [
                {
                    name: ApiClient.apiName,
                    endpoint: __USE_API__ === 'dev' ? "https://dev-api.acorde.app" : __USE_API__ === 'local' ?  "http://localhost:1881/dev" : "https://api.acorde.app",
                    region: awsRegion
                },
            ]
        },
        Storage: {
            AWSS3: {
                bucket: 'acorde-jsontree',
                region: awsRegion,
                identityPoolId: 'eu-west-2:7a465122-f047-4832-af20-0bc8b3080bc4',
            }
        }
    });
}