import React from "react";
import './CircleIndicator.scss';
import { childClassClosure, ClassableProps, baseClassAnd } from "@jcharlesworthuk/your-mum-core/dist/functions";


const baseClass = 'circle-indicator';
const childClass = childClassClosure(baseClass);

type Props = ClassableProps & {
    value: number;
    maximum: number;
    triColour?: boolean;
}

const CircleIndicator: React.FunctionComponent<Props> = props => {
    const percent = Math.round((props.value / (props.maximum || 1)) * 100);
    function getTricolourModifier() {
        if (percent < 33) return 'low';
        if (percent > 66) return 'high';
        return 'medium';
    }
    const colourModifier = props.triColour === true
    ? getTricolourModifier()
    : 'primary';
    return <svg className={baseClassAnd(baseClass, props)} viewBox="0 0 36 36">
    <path className={childClass('background')}
        d="M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831"
      />
      <path className={childClass('circle', colourModifier)}
        strokeDasharray={`${percent} 100`}
        d="M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831"
      />
      <text x="18" y="20.35" className={childClass('text')}>{props.value}</text>
  </svg>
  }
  export default CircleIndicator;