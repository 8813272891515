import * as React from 'react';
import './MetricAccordions.scss';
import { childClassClosure, ClassableProps } from '@jcharlesworthuk/your-mum-core/dist/functions';
import { useContext, useEffect, useState } from 'react';
import { SurveyManagerContext, SurveyManagerActionType, LoggedInContext } from '@Contexts';
import { Accordion, AccordionSummary, FormControlLabel, Switch, AccordionDetails, RadioGroup, Radio, Checkbox } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useDebounce } from '@Hooks';
import { PrivateApiClient } from '../../ApiClient';
import { SaveState } from '@Models/SaveState';

const baseClass = 'metric-accordions';
const childClass = childClassClosure(baseClass);


type Props = ClassableProps & {
};

const MetricAccordions: React.FunctionComponent<Props> = () => {
  const { template, saveState, dispatch } = useContext(SurveyManagerContext);
  const debouncedTemplate = useDebounce(template, 1500);
  const [ loading, setLoading ] = useState(true);
  const { currentUser } = useContext(LoggedInContext);
  
  useEffect(
    () => {
      async function save() {
        console.log('Saving template...');
        dispatch({ type: SurveyManagerActionType.SetSaveState, saveState: SaveState.Saving });
        const writeModel = template.toWriteModel();
        console.log(writeModel);
        await new PrivateApiClient().withUser(currentUser).updateSurveyTemplate(writeModel);
        dispatch({ type: SurveyManagerActionType.SetSaveState, saveState: SaveState.Saved });
      }
      if (loading) {
        // first pass, on page load
        setLoading(false);
      } else if (debouncedTemplate && saveState !== SaveState.Saving) {
        console.log('attempting to save...');
        save();
      }
    },
    [debouncedTemplate]
  );

  return <>
    {template.metrics.map(metricConfig => <Accordion key={metricConfig.metric.name}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >

        <FormControlLabel
          className={childClass('switch-label')}
          onClick={event => event.stopPropagation()}
          onFocus={event => event.stopPropagation()}
          control={<Switch
            color="primary"
            onChange={() => dispatch({type: SurveyManagerActionType.MetricToggled, metricConfiguration: metricConfig})}
            checked={!!metricConfig.active}
            value="activated"
          />}
          label={metricConfig.metric.name}
        />
        <p className={childClass('top-text')}>{metricConfig.metric.description}</p>
      </AccordionSummary>
      <AccordionDetails className={childClass('details')}>
      <div className={childClass('details-content')}>
      <p className={childClass('details-text')}>Choose which questions to ask when measuring {metricConfig.metric.name.toLowerCase()}, the questions will be rotated over multiple surveys.</p>
      {metricConfig.questions.map((questionConfig, index) => <FormControlLabel
      className={childClass('radio')}
        key={questionConfig.template.id}
        control={
          <Checkbox
            checked={questionConfig.enabled}
            onChange={e => dispatch({type: SurveyManagerActionType.MetricQuestionChanged, metricConfiguration: metricConfig, questionIndex: index})}
            value={questionConfig.template.id}
            color="primary"
          />
        }
        label={questionConfig.template.text}
      />)}
      </div>
      </AccordionDetails>
    </Accordion>
    )}
  </>
}

export default MetricAccordions;
