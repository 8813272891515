import React, { useState } from "react"
import "./ScaleRadioButton.scss";
import { childClassClosure, ClassableProps, baseClassAnd } from "@jcharlesworthuk/your-mum-core/dist/functions";

const baseClass = 'scale-radio-button';
const childClass = childClassClosure(baseClass);

type Props = ClassableProps & {
    iconPath: string;
    value: number;
    thisRadioValue: number;
    label: string;
    onChange: (newValue: number) => void;
}

const ScaleRadioButton: React.FunctionComponent<Props> = props => {
    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = parseInt(e.currentTarget.value);
        props.onChange(newValue);
    };
    const isSomethingSelected = typeof props.value !== 'undefined';
    return <div className={baseClassAnd(baseClass, props)}>
            <input type="radio" name="likert" value={props.thisRadioValue.toString()} checked={props.value === props.thisRadioValue} onChange={onChange} />
            <div className={childClass('wrapper', isSomethingSelected ? 'something-selected' : 'nothing-selected')}>
                <img src={props.iconPath} alt={props.label} />
                <p className={childClass('label')}>
                    {props.label}
                </p>
            </div>
        </div>
}

export default ScaleRadioButton;
