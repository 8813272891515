import { LikertMetricResultDto } from "@Dtos";

export enum DiscreteLikertResult {
    VeryBad = -2,
    Bad = -1,
    Neutral = 0,
    Good = 1,
    VeryGood = 2
}


export const minDiscreteLikertResult = DiscreteLikertResult.VeryBad;
export const maxDiscreteLikertResult = DiscreteLikertResult.VeryGood;


export function createBlankLikertMetricResultDto(): LikertMetricResultDto {
    const result = {
        score: 0,
        counts: {
        }
    }
    for (let i = minDiscreteLikertResult; i <= maxDiscreteLikertResult; i++) {
        result.counts[i] = 0;
    }
    return result;
}