import * as React from 'react';
import './MyUserSettingsForm.scss';
import { childClassClosure, ClassableProps, baseClassAnd } from '@jcharlesworthuk/your-mum-core/dist/functions';
import { TextField, Button, Accordion, AccordionSummary, FormControlLabel, Switch, AccordionDetails, RadioGroup, Radio, Checkbox } from '@material-ui/core';
import { LoggedInContext, SurveyManagerActionType, LoggedInActionType } from '@Contexts';
import { useContext, useState } from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useChangePasswordForm } from '@Hooks';
import { ButtonState } from '@jcharlesworthuk/your-mum-frontend/models';
import { PrivateApiClient } from '../../ApiClient';

const baseClass = 'my-user-settings-form';
const childClass = childClassClosure(baseClass);


type Props = ClassableProps & {
};


const MyUserSettingsForm: React.FunctionComponent<Props> = (props) => {
  const { currentUser, dispatch } = useContext(LoggedInContext);
  const [editingFullName, setEditingFullName] = useState<string | undefined>(undefined);
  const passwordChangeForm = useChangePasswordForm(currentUser.email);

  const changeFullName = (newName: string) => {
    async function save() {
      setEditingFullName(undefined);
      await new PrivateApiClient().withUser(currentUser).updateEmployee({
        userId: currentUser.sub,
        email: currentUser.email,
        fullName: newName
      });
      dispatch({ type: LoggedInActionType.NameChanged, stringValue: newName });
    }
    save();
  }

  const changeEmailPreference = (e: React.ChangeEvent<HTMLInputElement>) => {
    async function save() {
      const newValue = e.currentTarget.checked ? 1 : -1;
      await new PrivateApiClient().withUser(currentUser).updateEmployee({
        userId: currentUser.sub,
        email: currentUser.email,
        newsletter: newValue
      });
      dispatch({ type: LoggedInActionType.NewsletterChanged, stringValue: newValue.toString() });
    }
    save();
  }


  return <div className={baseClassAnd(baseClass, props)}>
    {editingFullName
      ? <div className={childClass('text-edit-inline')}>
        <TextField id="orgName"
          className={childClass('name-box')}
          label="Your Name"
          margin="normal"
          fullWidth={true}
          variant='outlined'
          value={editingFullName}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEditingFullName(e.target.value)}
        />
        <Button size="large" color="primary" variant="contained" onClick={() => changeFullName(editingFullName)}>Save</Button>
        <Button size="large" onClick={() => setEditingFullName(undefined)}>Cancel</Button>
      </div>
      : <h1 className={childClass('title')}>
        {currentUser.fullName}<span className={childClass('edit-text')} onClick={() => setEditingFullName(currentUser.fullName)}>Edit</span>
      </h1>}

    <TextField id="email"
      className={childClass('email-box')}
      label="Email"
      margin="normal"
      fullWidth={true}
      disabled
      variant='outlined'
      value={currentUser.email}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => e.preventDefault()}
    />

    <FormControlLabel
      className={childClass('radio')}
      control={
        <Checkbox
          checked={currentUser.newsletter === 1}
          onChange={changeEmailPreference}
          value="1"
          color="primary"
        />
      }
      label="Subscribe to Monthly Tips Newsletter"
    />

    {passwordChangeForm.changeSuccess !== true
      ? <Accordion className={childClass('change-password-accordion')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <p className={childClass('accordion-title')}>Change Your Password</p>
        </AccordionSummary>
        <AccordionDetails className={childClass('change-password-container')}>
          <form className={childClass('change-password-form')} noValidate autoComplete="off" onSubmit={passwordChangeForm.handleFormSubmit()}>
            <TextField id="currentPassword"
              className={childClass('org-name-box')}
              label="Current Password"
              margin="normal"
              type="password"
              fullWidth={true}
              variant='outlined'
              value={passwordChangeForm.fields.oldPassword}
              onChange={passwordChangeForm.handlePasswordInputChange('oldPassword')}
            />
            <TextField id="newPassword"
              className={childClass('org-name-box')}
              label="New Password"
              margin="normal"
              type="password"
              fullWidth={true}
              variant='outlined'
              value={passwordChangeForm.fields.newPassword}
              onChange={passwordChangeForm.handlePasswordInputChange('newPassword')}
            />

            <TextField id="newPasswordRepeat"
              className={childClass('org-name-box')}
              label="Re-Enter New Password"
              margin="normal"
              type="password"
              fullWidth={true}
              variant='outlined'
              value={passwordChangeForm.fields.newPasswordCopy}
              onChange={passwordChangeForm.handlePasswordInputChange('newPasswordCopy')}
            />
            <div className={childClass('change-password-container-buttons')}>
              <Button size="large" color="primary" variant="contained" type="submit" disabled={passwordChangeForm.buttonState != ButtonState.Enabled}>Change Password</Button>
            </div>
            {passwordChangeForm.error && <span className={childClass('change-password-error')}>{passwordChangeForm.error}</span>}
          </form>
        </AccordionDetails>
      </Accordion>
      : <p className={childClass('change-password-changed')}>Your password has been changed</p>}

  </div>
}

export default MyUserSettingsForm;