import React, { useState } from "react"
import "./SurveyCompletePage.scss";
import { childClassClosure, ClassableProps, baseClassAnd } from "@jcharlesworthuk/your-mum-core/dist/functions";
import { FaceRbf } from "@jcharlesworthuk/your-mum-frontend/svg/FaceRbf";
import { FaceHappy } from "@jcharlesworthuk/your-mum-frontend/svg/FaceHappy";

const baseClass = 'survey-complete-page';
const childClass = childClassClosure(baseClass);

type Props = ClassableProps & {
}

const SurveyCompletePage: React.FunctionComponent<Props> = props => {
    return <div className={baseClassAnd(baseClass, props)}>
        <h2 className={childClass('title')}>Thank you!</h2>
        <p className={childClass('text')}>Your responses have been submitted</p>
        <p className={childClass('text')}>You can now close this window</p>
    </div>
}

export default SurveyCompletePage;
