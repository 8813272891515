import React, { useEffect } from "react"
import "./_CommonShared.scss";
import "./AcordeLandingPage.scss";
import { graphql } from "gatsby"
import { FileConnection } from "@GraphQLModels";
import { childClassClosure } from "@jcharlesworthuk/your-mum-core/dist/functions";
import MarketingSiteHeader from "@StaticQueryComponents/MarketingSiteHeader";
import MarketingSiteFooter from "@StaticQueryComponents/MarketingSiteFooter";
import { useRef, useState } from 'react'
import { useScrollPosition } from '@n8tb1t/use-scroll-position'
import HandwritingAnimation, { Ref as HandwritingAnimationRef } from "../components/marketing/HandwritingAnimation";
import WhoItIsFor, { WhoItIsForRef } from "@jcharlesworthuk/your-mum-frontend/components/marketing/WhoItIsFor";
import { Site } from "@GraphQLModels";
import PageMeta from "@StaticQueryComponents/PageMeta";
import CookieBanner from "@jcharlesworthuk/your-mum-frontend/components/notifications/CookieBanner";
import { getCompanyStructuredData } from "@jcharlesworthuk/your-mum-frontend/components/seo/CompanyStructuredData";
import { mapToWebsiteInformation } from "@GraphQLMappers";
import LinkButton from "../components/buttons/LinkButton";
import SubscriptionSelector from "../components/selling/SubscriptionSelector";
import AcordeAnimatedSteps from "../components/marketing/AcordeAnimatedSteps";

const baseClass = 'acorde-landing-page';
const childClass = childClassClosure(baseClass);

type Props = {
  pageContext: {
  };
  data: {
    site?: Site
  }
}

const AcordeLandingPage: React.FunctionComponent<Props> = (props: Props) => {
  const handwritingRef = useRef<HandwritingAnimationRef>(null);
  const whoIsItForRef = useRef<WhoItIsForRef>(null);
  //Hooks for fixing the header on scroll
  const [hasScrolledStep, setHasScrolledStep] = useState(0)
  useScrollPosition(({ currPos, prevPos }) => {
    const isOverThreshold = currPos.y < -500;
    if (isOverThreshold && hasScrolledStep == 0) {
      setHasScrolledStep(1);
      setTimeout(() => setHasScrolledStep(2), 200);
    }
    if (!isOverThreshold && hasScrolledStep > 0) {
      setHasScrolledStep(0);
    }
    if (currPos.y > prevPos.y) return;
    // From now on it's only down scrolling we care about
    const handwritingRefTop = handwritingRef.current.getBoundingClientRect().top;
    if (window.innerHeight - handwritingRefTop > 200 && !handwritingRef.current.classList.contains('shown')) {
      handwritingRef.current.classList.add('shown')
    }

    const whoIsItForRefTop = whoIsItForRef.current.getBoundingClientRect().top;
    if (window.innerHeight - whoIsItForRefTop > 200 && !whoIsItForRef.current.classList.contains('shown')) {
      whoIsItForRef.current.classList.add('shown')
    }
  }, [hasScrolledStep])

  const website = mapToWebsiteInformation(props.data.site.siteMetadata);

  return (
    <div className={baseClass}>

      <PageMeta
        route='/'>
        <script className='structured-data-list' type="application/ld+json">{getCompanyStructuredData(website)}</script>
      </PageMeta>
      <CookieBanner />
      <div className={childClass('hero')}>
        <MarketingSiteHeader className={childClass('header', hasScrolledStep == 1 ? 'ready' : hasScrolledStep > 1 ? 'affix' : '')} />
        <div className={childClass('hero-container')}>
          <h1 className={childClass('hero-title')}>Take care of your team</h1>
          <h2 className={childClass('hero-subtitle')}>Employee surveys and engagement tracking <br />for high performing teams</h2>
          <LinkButton className={childClass('cta')} to='/account/register' label='Start Now' />
          <img className={childClass('hero-image')} src="/marketing/desktop-and-mobile.svg" />
        </div>
      </div>
      <section className={childClass('section', 'medium')}>
        <div className={childClass('container')}>
          <h2 className={childClass('centre-h2')}>Do you need to <mark>feel the pulse</mark> of your team? We have the solution.</h2>
        </div>
      </section>

      <section className={childClass('section', 'close')}>
        <AcordeAnimatedSteps className={childClass('step-by-step')} descriptions="short" />
      </section>

      <section className={childClass('handwriting')}>
        <a id="features"></a>
        <div className={childClass('handwriting-container')}>
          <HandwritingAnimation ref={handwritingRef} className={childClass('handwriting-animation')} />
          <p className={childClass('handwriting-text')}>Our feedback surveys are based on scientific research into employee well-being and what makes a high functioning team</p>
        </div>
      </section>

      {/* <section className={childClass('section')}>
      <div className={childClass('container')}>
          <WhatOthersSay />
        </div>
      </section> */}
      <section className={childClass('section')}>
        <div className={childClass('container')}>
          <h2 id="pricing" className={childClass('centre-h2')}>Pricing</h2>
          <SubscriptionSelector onSelect={plan => false} />
        </div>
      </section>


      <section className={childClass('callout')}>
        <img className={childClass('callout-background-bl')} src='/img/callout-bg-bl.svg' />
        <img className={childClass('callout-background-tr')} src='/img/callout-bg-tr.svg' />
        <img className={childClass('callout-background-tl')} src='/img/callout-bg-tl.svg' />
        <img className={childClass('callout-background-br')} src='/img/callout-bg-br.svg' />
        <div className={childClass('callout-container')}>
          <div className={childClass('second-subscribe-form-box')}>
            <h3 className={childClass('second-subscribe-form-box-subtitle')}>Take your business to the next level</h3>
            <LinkButton className={childClass('cta')} to='/account/register' label='Start Now' />
          </div>
        </div>
      </section>


      <section className={childClass('section')}>
        <div className={childClass('container')}>
          <WhoItIsFor ref={whoIsItForRef}
            items={[
              { title: 'For Teams', iconPath: '/img/visitor.svg' },
              { title: 'For Managers', iconPath: '/img/manager.svg' },
              { title: 'For Business', iconPath: '/img/factory.svg' }]} />
        </div>
      </section>

      <section>
        <div className={childClass('container')}>
          <img className={childClass('massive-logo')} src={'/img/logo.svg'} alt={props.data.site.siteMetadata.title} />
        </div>
      </section>

      <footer className={childClass('footer')}>
        <MarketingSiteFooter />
      </footer>
    </div>
  )
}

export default AcordeLandingPage;


export const pageQuery = graphql`
  query AcordeLandingPageQuery {
      site {
            siteMetadata {
                title
                siteUrl
                siteLogoUrl
                siteLogoUrlSquare
                titleTemplate
                social {
                  twitter {
                      handle,
                      url
                  }
                  facebook {
                      handle
                      url
                  }
                }
            }
        }
  }
  `
