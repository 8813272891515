import React from "react"
import "./_CommonShared.scss";
import "./StaticPageLayout.scss";
import { graphql } from "gatsby"
import { Mdx } from "@GraphQLModels";
import {  childClassClosureMultiple, childClassClosure } from "@jcharlesworthuk/your-mum-core/dist/functions";
import MarketingSiteHeader from "@StaticQueryComponents/MarketingSiteHeader";
import MarketingSiteFooter from "@StaticQueryComponents/MarketingSiteFooter";
import MdxBlock from "../components/markup/MdxBlock";
import PageMeta from "@StaticQueryComponents/PageMeta";
import AcordeContactForm from "../components/forms/AcordeContactForm";

const baseClass = 'static-page-layout';
const childClass = childClassClosure(baseClass);

type Props = {
  pageContext: {
    id: string
  };
  data: {
    mdx: Mdx,
  }
}

export default function StaticPageLayout(props: Props) {
  return (
    <div className={baseClass}>
      <PageMeta
          title={props.data.mdx.frontmatter.title}
          description={props.data.mdx.frontmatter.title}
          route={props.data.mdx.fields.route}>
          <meta name="robots" content="noindex" />
      </PageMeta>      
      <MarketingSiteHeader className={childClass('header')} />

      <div className={childClass('top-image-container')}>
        <img src={'/img/article/acorde-branded-header.svg'} alt={'Page Header'} />
      </div>
      <div className={childClass('main-container')}>
        <section className={childClass('article-container')}>
          <h1 className={childClass('title')}>{props.data.mdx.frontmatter.title}</h1>
          <MdxBlock mdx={props.data.mdx}
            className={childClass('markdown')} />
        </section>
        <aside className={childClass('rhs-column')}>
        </aside>
      </div>
      
      <MarketingSiteFooter />
    </div>
  )
}

export const pageQuery = graphql`
  query StaticPageLayoutQuery($id: String) {
      mdx(id: {eq: $id }) {
        id
        fields {
          route
        }
        frontmatter {
          title
        }
        body
      }
  }
  `

