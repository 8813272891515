import * as React from 'react';
import './MyProfileWindow.scss';
import { childClassClosure } from '@jcharlesworthuk/your-mum-core/dist/functions';
import { useContext } from 'react';
import { LoggedInContext } from "@Contexts";
import Spinner from '@jcharlesworthuk/your-mum-frontend/components/indicators/Spinner';
import { Paper } from '@material-ui/core';
import OrgSettingsEditorForm from '../forms/OrgSettingsEditorForm';
import UserSvg from '@jcharlesworthuk/your-mum-frontend/svg/UserSvg';
import MyUserSettingsForm from '../forms/MyUserSettingsForm';
import InitialCircle from '../indicators/InitialCircle';

const baseClass = 'my-profile-window';
const childClass = childClassClosure(baseClass);


type Props = {};


const MyProfileWindow: React.FunctionComponent<Props> = () => {
    const { currentUser } = useContext(LoggedInContext);

    return <section className={baseClass}>
        <Paper className={childClass('panel-paper')}>
            {currentUser ?
                <>
                    <div className={childClass('image-box')}>
                    <InitialCircle user={currentUser} />
                    </div>

                    <div className={childClass('details-pane')}>
                        <MyUserSettingsForm />

                    </div>
                </>
                : <Spinner />}

        </Paper>
    </section>
}

export default MyProfileWindow;