import * as React from 'react';
import { baseClassAnd, ClassableProps } from '@jcharlesworthuk/your-mum-core/dist/functions';
import { useRef, useEffect } from 'react';

const baseClass = 'screen-demo';

type Props = ClassableProps & {
    animate: boolean;
};

const SurveyEditorScreenDemo: React.FunctionComponent<Props> = props => {
    const objectRef = useRef<HTMLObjectElement>(null);

    useEffect(() => {
        if (props.animate && objectRef.current && objectRef.current.contentDocument && isLoaded(objectRef.current.contentDocument)) {
            animateDomElement(objectRef.current.contentDocument);
        } 
    }, [props.animate]);

    return <object width="100%" 
                className={baseClassAnd(baseClass, props)} 
                id="surveys-page-svg" 
                data="/screen-demos/surveys-page.svg" 
                ref={objectRef} />
}

export default SurveyEditorScreenDemo;

function isLoaded(svg: Document) {
    return !!svg.getElementById("top-box");
}

export function animateDomElement(svg: Document) {
    const restBlock = svg.getElementById("rest-block");
    const topBox = svg.getElementById("top-box");
    const questionTicks = svg.getElementById('questions-ticks');

    topBox.style.height = '0px';
    topBox.style.transition = 'height 800ms ease';

    questionTicks.style.transition = 'opacity 100ms ease-out';
    questionTicks.style.opacity = '0';


    function openAccordion() {
        topBox.style.height = '67px';
        restBlock.style.transition = 'transform 800ms ease';
        restBlock.style.transform = 'translate(0px,40px)';
        setTimeout(()=> {
            questionTicks.style.opacity = '1';
            setTimeout(closeAccordion, 1000);
        }, 800);
    }

    function closeAccordion() {
        questionTicks.style.opacity = '0';
        setTimeout(() => {
            topBox.style.height = '0px';
            restBlock.style.transform = 'translate(0px,0px)';
        }, 100)
    }

    openAccordion();
}