import React, { useState, useEffect } from "react"
import "./_CommonShared.scss";
import "./_FullHeightModifiers.scss";
import "./SurveyCompletionLayout.scss";
import { childClassClosure } from "@jcharlesworthuk/your-mum-core/dist/functions";
import { LoggedInContextProvider } from "@Contexts";
import * as Authentication from "@Services/Authentication"
import { RouteComponentProps } from "@reach/router";
import { navigate } from "gatsby";
import { ThemeProvider } from "@material-ui/core/styles";
import { createMuiTheme } from '@material-ui/core/styles';
import themeOptions from '../material-theme';
import { QuestionType } from "@Models/QuestionType";
import { Stepper, Step, StepLabel, Button, Paper } from "@material-ui/core";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { publicApiClient, staticDataApi } from "../ApiClient";
import { SurveyToAnswer } from "@Models/SurveyToAnswer";
import { QuestionToAnswer } from "@Models/QuestionToAnswer";
import { ScaleType } from "@Models/ScaleType";
import { parse as parseQueryString } from 'query-string';
import { Link } from "gatsby"
import SurveyCompletePage from "../components/survey/SurveyCompletePage";
import SurveyWelcomePage from "../components/survey/SurveyWelcomePage";
import { PublicOrganisation } from "@Models/PublicOrganisation";
import PageMeta from "@StaticQueryComponents/PageMeta";
import FreetextEntry from "../components/questions/FreetextEntry";
import ScaleEntryRadios from "../components/questions/ScaleEntryRadios";
import { ScaleLabelSet } from "@Models/ScaleLabelSet";

const baseClass = 'survey-completion-page';
const childClass = childClassClosure(baseClass);

type Props = {
  location: {
    pathname: string,
    search: string
  },
  pageContext: {
  };
}

type QueryParams = {
  /** Organisation id */
  o: string;
  /** Survey id */
  s: string;
  /** User secret id */
  u: string;
  /** Team ID (optional) */
  t?: string;
}

const exampleOrgId = 'example';

const theme = createMuiTheme(themeOptions);


const SurveyCompletionLayout: React.FunctionComponent<Props> = props => {
  const [survey, setSurvey] = useState<SurveyToAnswer | undefined>(undefined);
  const [activeStep, setActiveStep] = React.useState(-1);
  const queryParameters = parseQueryString(props.location.search) as QueryParams;

  useEffect(() => {
    async function load() {
      const loadedSurvey = await staticDataApi.getSurveyToAnswer(queryParameters.o, queryParameters.s);
      setSurvey(loadedSurvey);
    }
    if (!survey) {
      load();
    }
  }, []);

  const answerQuestion = (question: QuestionToAnswer, answer: number | string) => {
    const questionIndex = survey.questions.indexOf(question);
    const clonedNew = survey.questions[questionIndex].withAnswer(answer);
    // 1. Make a shallow copy of the items
    const newQuestions = [...survey.questions];
    newQuestions[questionIndex] = clonedNew;
    // 5. Set the state to our new copy
    setSurvey({
      ...survey,
      questions: newQuestions
    });
  }

  const handleNext = () => {
    async function saveAnswers() {
      if (queryParameters.o === exampleOrgId) return;
      const writeModel = SurveyToAnswer.getAnswers(survey, queryParameters.u, queryParameters.t);
      await publicApiClient.saveSurveyAnswers(writeModel);
    }
    if (activeStep === survey.questions.length - 1) {
      saveAnswers();
    }
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const isLargeWindow = !(activeStep < 0 || activeStep == survey.questions.length);
  const isSurveyOpen = survey && (survey.closesOn > new Date() || queryParameters.o === exampleOrgId);
  return (
    <div className={baseClass}>
      <PageMeta
        title="Feedback Survey"
        description="Give feedback to your organisation with a survey, from Acorde"
        route={'/complete'}>
        <meta name="robots" content="noindex" />
      </PageMeta>
      <ThemeProvider theme={theme}>

        <header className={childClass('header')}>
          <div className={childClass('header-container')}>
            <img className={childClass('header-logo')} src={'/img/logo-white.svg'} alt='Acorde Survey' />
          </div>
        </header>

        <div className={childClass('paper-container', isLargeWindow ? 'large' : 'small')}>
          <Paper className={childClass('paper')} elevation={3}>
            {survey && activeStep == -1 && isSurveyOpen && <SurveyWelcomePage className={childClass('small-view')} handleNext={handleNext} organisationName={survey.organisation.name} />}

            {activeStep >= 0 && <Stepper activeStep={activeStep} className={childClass('stepper')}>
              {survey.questions.map((question, index) => <Step key={index}>
                <StepLabel>&nbsp;</StepLabel>
              </Step>
              )}
            </Stepper>
            }
            {survey && isLargeWindow && <div className={childClass('question-window', activeStep >= 0 ? 'with-stepper' : '')}>
              <TransitionGroup>
                <CSSTransition key={activeStep} timeout={500} classNames={childClass('question-view')}>
                  <>
                    {survey.questions.filter((x, i) => i === activeStep).map((question) => {
                      return <div className={childClass('question-view')} key={question.text}>
                        <h2 className={childClass('question-text')}>{question.text} {question.isOptional === true && <small>(optional)</small>}</h2>
                        {question.scale === ScaleType.Freetext
                          ? <FreetextEntry className={childClass('question-entry')} value={question.answer as string} onChange={val => answerQuestion(question, val)} />
                          : <ScaleEntryRadios scale={ScaleLabelSet.forScale(question.scale, false)} className={childClass('question-entry')} value={question.numericAnswer} onChange={val => answerQuestion(question, val)} invert={question.isInverse} />
                        }
                        <div className={childClass('question-buttons')}>
                          <Button disabled={activeStep === 0} onClick={handleBack} size="large">
                            Back
                          </Button>

                          <Button
                            variant="contained"
                            color="primary"
                            onClick={handleNext}
                            disabled={question.isOptional === true || question.answer !== undefined ? false : true}
                            size="large"
                          >
                            {activeStep === survey.questions.length - 1 ? 'Finish' : 'Next'}
                          </Button>
                        </div>
                      </div>
                    })}
                  </>
                </CSSTransition>
              </TransitionGroup>
            </div>}
            {survey && activeStep == survey.questions.length && <SurveyCompletePage className={childClass('small-view')} />}
            {survey && !isSurveyOpen && <div className={childClass('small-view')}>
              <h2 className={childClass('small-view-texty')}>This survey is now closed</h2>
              <p className={childClass('small-view-texty')}>Sorry, this survey has run its course and we are no longer taking responses.  Keep an eye out for your next feedback survey email!</p>
            </div>}

          </Paper>
        </div>

        <footer className={childClass('footer')}>
          Feedback surveys by Acorde | <Link to="/privacy-policy/">Privacy Policy</Link> | <Link to="/cookie-policy/">Cookies</Link>
        </footer>

      </ThemeProvider>
    </div>
  )
}

export default SurveyCompletionLayout;
