import { UpdateOrganisationWriteModel } from "@ApiModels";

export class Team {
    constructor(
            public id: string,
            public name: string, 
            public managerId: string) {
    }

    public static createWriteModel(team: Team): UpdateOrganisationWriteModel {
        return {
            teams: {
                [team.id]: {
                    name: team.name,
                    managerId: team.managerId
                }
            }
        }

    }
}

