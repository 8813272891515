import API from "@Plugins/amplify-api";
import { OrganisationDto, EmployeeDto, SurveyTemplateDto, SurveyToAnswerDto, SurveyResultsDto, FullSubscriptionInfoDto, MeDto } from "@Dtos";
import { UpsertEmployeeWriteModel, UpdateOrganisationWriteModel, UpdateMyUserProfileWriteModel, UpdateSurveyTemplateWriteModel, NewSurveyAnswersWriteModel, NewOrganisationWriteModel, NewCheckoutSessionWriteModel, NewContactFormEnquiryRequest, UpsertSubscriptionWriteModel, ManualActionType } from "@ApiModels";
import { Organisation } from "@Models/Organisation";
import { Employee } from "@Models/Employee";
import { SurveyToAnswer } from "@Models/SurveyToAnswer";
import { SurveyTemplate } from "@Models/SurveyTemplate";
import { Metric } from "@Models/Metric";
import { QuestionTemplate } from "@Models/QuestionTemplate";
import { CurrentUser } from "@Models/CurrentUser";
import { QuestionTokens } from "@Models/QuestionTokens";
import { SurveyResults } from "@Models/SurveyResults";
import { SurveyFrequencySettings } from "@Models/SurveyFrequencySettings";
import { FullSubscriptionInfo } from "@Models/FullSubscriptionInfo";

export const apiName = 'acorde';
export const jsonBaseUrl = 'https://acorde-jsontree.s3.eu-west-2.amazonaws.com';

class PrivateApiClientWithUser {
    constructor(public user: CurrentUser) {

    }
        
    async insertEmployee(writeModel: UpsertEmployeeWriteModel) {
        const response = await API.post(apiName, `/organisations/${this.user.orgId}/employees`, {
            body: writeModel
        });
        return (response || {}).userId as string | undefined;
    }

    async updateEmployee(writeModel: UpsertEmployeeWriteModel) {
        const response = await API.put(apiName, `/organisations/${this.user.orgId}/employees`, {
            body: writeModel
        });
        return (response || {}).userId as string | undefined;
    }

    async deleteEmployee(writeModel: UpsertEmployeeWriteModel) {
        await API.del(apiName, `/organisations/${this.user.orgId}/employees`, {
            body: writeModel
        });
    }

    async updateOrganisation(writeModel: UpdateOrganisationWriteModel) {
        await API.put(apiName, `/organisations/${this.user.orgId}`, {
            body: writeModel
        });
    }

    async updateSurveyTemplate(writeModel: UpdateSurveyTemplateWriteModel) {
        await API.put(apiName, `/organisations/${this.user.orgId}/survey-template`, {
            body: writeModel
        });
    }

    async getOrganisation() {
        const dto = await API.get(apiName, `/organisations/${this.user.orgId}`, {}) as OrganisationDto;
        return Organisation.fromDto(dto);
    }


    async getEmployees() {
        const dtos = await API.get(apiName, `/organisations/${this.user.orgId}/employees`, {}) as EmployeeDto[];
        return dtos.map(Employee.fromDto);
    }

    async getHistoricalSurveyResults(metrics: { [metricId: string]: Metric}) {
        const dtos = await API.get(apiName, `/organisations/${this.user.orgId}/survey-results`, {}) as SurveyResultsDto[];
        return dtos.filter(x => x.participationCount > 0).map(x => SurveyResults.fromDto(x, metrics));
    }

    async getLatestSurveyResult(metrics: { [metricId: string]: Metric}) : Promise<SurveyResults | null> {
        const dto = await API.get(apiName, `/organisations/${this.user.orgId}/survey-results?latest=true`, {}) as SurveyResultsDto[];
        if (!dto || dto.length === 0) return null;
        return SurveyResults.fromDto(dto[0], metrics);
    }    
    
    async getSurveyTemplate(metrics: { [metricId: string]: Metric }, questionTemplates: { [templateId: string]: QuestionTemplate }) {
        const dto = await API.get(apiName, `/organisations/${this.user.orgId}/survey-template`, {}) as SurveyTemplateDto;
        return SurveyTemplate.fromDtoTsvPriority(dto, metrics, questionTemplates);
    }

    async getSurveyTemplateFrequencySettings() {
        const dto = await API.get(apiName, `/organisations/${this.user.orgId}/survey-template`, {}) as SurveyTemplateDto;
        return SurveyFrequencySettings.fromDto(dto);
    }
        
    async getSubscriptionInfo() {
        const dto = await API.get(apiName, `/organisations/${this.user.orgId}/subscription`, {}) as FullSubscriptionInfoDto;
        return FullSubscriptionInfo.fromDto(dto);
    }
    
    async cancelSubscription() {
        await API.del(apiName, `/organisations/${this.user.orgId}/subscription`, {
            body: {}
        });
    }
    
    async updateSubscription(writeModel: UpsertSubscriptionWriteModel) {
        await API.put(apiName, `/organisations/${this.user.orgId}/subscription`, {
            body: writeModel
        })
    }

    async triggerManualAction(actionName: ManualActionType) {
        await API.post(apiName, `/organisations/${this.user.orgId}/actions/${actionName}`, {
            body: {}
        })
    }

}


export class PrivateApiClient {

    async getMe(orgId: string) {
        const dto = await API.get(apiName, `/organisations/${orgId}/me`, {}) as MeDto;
        return dto;
    }

    public withUser(user: CurrentUser) {
        return new PrivateApiClientWithUser(user);
    }

    async createOrganisation(writeModel: NewOrganisationWriteModel) {
        await API.post(apiName, `/organisations`, {
            body: writeModel
        });
    }

    
    async createCheckoutSession(writeModel: NewCheckoutSessionWriteModel) {
        const result = await API.post(apiName, `/checkout/sessions`, {
            body: writeModel
        }) as { sessionId: string };
        return result.sessionId;
    }
}

export class PublicApiClient {


    async saveSurveyAnswers(writeModel: NewSurveyAnswersWriteModel) {
        await API.post(apiName, `/responses`, {
            body: writeModel
        });
    }

    
    async unsubscribe(token: string) {
        await API.get(apiName, `/unsubscribe?token=${token}`, {});
    }

    async makeContactEnquiry(writeModel: NewContactFormEnquiryRequest) {
        await API.post(apiName, `/enquiries`, {
            body: writeModel
        });
    }
}

// Not an actual API - gets from S3
class StaticDataApi {

    async getQuestionsAndMetrics(organisationName: string) {
        const tsvResponse = await fetch(`${jsonBaseUrl}/questions.tsv`);
        if (!tsvResponse.ok) throw new Error('Failed to get questions data file!');
        const text = await tsvResponse.text();
        const result = QuestionTemplate.loadFromTsvText(text, new QuestionTokens(organisationName));
        return result;
    }

    async getSurveyToAnswer(organisationId: string, surveyToAnswerId: string) {
        const jsonResponse = await fetch(`${jsonBaseUrl}/${organisationId}/${surveyToAnswerId}.json`);
        if (!jsonResponse.ok) throw new Error('Failed to get survey to answer json!');
        const dto = (await jsonResponse.json()) as SurveyToAnswerDto;
        return SurveyToAnswer.fromDto(dto);
    }

}

export const publicApiClient = new PublicApiClient();
export const staticDataApi = new StaticDataApi();