import * as React from 'react';
import './FrontDoorLayout.scss';
import PageMeta from "@StaticQueryComponents/PageMeta";
import { useEffect, useState } from 'react';
import * as Authentication from "@Services/Authentication";
import { Link } from 'gatsby';
import { childClassClosure, isNullOrEmpty, base64DecodeUrl } from '@jcharlesworthuk/your-mum-core/dist/functions';
import { TransitionGroup, CSSTransition } from "react-transition-group";
import CognitoSignupForm, { CognitoSignupRequest } from '../components/frontdoor/CognitoSignupForm';
import { CurrentUser } from '@Models/CurrentUser';
import { LoginCredentials } from '@Models/LoginCredentials';
import CognitoEmailVerifyForm from '../components/frontdoor/CognitoEmailVerifyForm';
import { Organisation } from '@Models/Organisation';
import OrganisationSetupForm from '../components/frontdoor/OrganisationSetupForm';
import StripeSubscriptionFlow from '../components/frontdoor/StripeSubscriptionFlow';
import LoginForm from '../components/frontdoor/LoginForm';
import PasswordResetRequestForm from '../components/frontdoor/PasswordResetRequestForm';
import PasswordResetSetNewform from '../components/frontdoor/PasswordResetSetNewform';
import { FrontDoorSteps } from '@Models/FrontDoorSteps';
import { parse as parseQueryString } from 'query-string';
import { navigate } from "gatsby"
import LinkButton from '../components/buttons/LinkButton';
import SpinningTickWithLabel from '@jcharlesworthuk/your-mum-frontend/components/indicators/SpinningTickWithLabel';
import { publicApiClient, PrivateApiClient } from '../ApiClient';
import CompleteAccountCreationForm from '../components/frontdoor/CompleteAccountCreationForm';

type FrontDoorRouteString = 'login' | 'register' | 'logout' | 'manager-signup' | 'forgot-password' | 'subscription-confirmed' | 'subscription-cancel' | 'unsubscribe' | 'change-subscription';

export const baseClass = 'front-door';
export const childClass = childClassClosure(baseClass);

type Props = {
    location: {
        pathname: string,
        search: string
    },
};

type QueryParams = {
    session_id?: string;
    token?: string;
    p?: string;
}

/**
 * REGISTRATION FLOW
 * 
 * 1. Enter email + password -> cognito
 * 2. Enter email verficiation -> cognito
 * 3. Enter organisastion name -> lambda new-organisation
 * 4. Stripe Billing Subscription -> stripe
 * 5. All good -> redirect to dashboard
 * 
 * They can "tap in" at any point in the above
 */



const FrontDoorLayout: React.FunctionComponent<Props> = props => {
    const [activeStep, setActiveStep] = useState<FrontDoorSteps>(FrontDoorSteps.Initialising);
    const [credentials, setCredentials] = useState<LoginCredentials | null>(null);
    const [message, setMessage]  =useState<string>(''); //used with the ShowMessage Step
    const [user, setUser] = useState<CurrentUser | null>(null);
    const [tempCredentials, setTempCredentials ] = useState<{email: string, password: string} | null>(null);
    const pathSections = props.location.pathname.split('/').filter(x => !isNullOrEmpty(x));
    const route = (pathSections.length >= 2 ? pathSections[1].toLowerCase() : 'login') as FrontDoorRouteString;
    const queryParameters = parseQueryString(props.location.search) as QueryParams;

    useEffect(() => {
        switch (route) {
            case 'logout':
                Authentication.signOut();
                setActiveStep(FrontDoorSteps.LoggedOut);
                break;
            case 'login':
                setActiveStep(FrontDoorSteps.LogIn);
                break;
            case 'register':
                setActiveStep(FrontDoorSteps.RegisterCognito);
                break;
            case 'forgot-password':
                setActiveStep(FrontDoorSteps.PasswordResetRequest);
                break;
            case 'manager-signup': {
                const email = decodeBase64Token(queryParameters.token);
                const password = queryParameters.p;
                if (isNullOrEmpty(email) || isNullOrEmpty(password)) {
                    setActiveStep(FrontDoorSteps.LogIn);
                } else {
                    setTempCredentials({ email, password});
                    setActiveStep(FrontDoorSteps.ManagerSignup);
                }
                break;
            }
            case 'subscription-cancel':
                setActiveStep(FrontDoorSteps.SubscriptionCancel);
                break;
            case 'change-subscription':
                loadUserAndSetStep(FrontDoorSteps.SubscriptionChange);
                break;
            case 'subscription-confirmed':
                if (!queryParameters.session_id) {
                    setActiveStep(FrontDoorSteps.LogIn);
                } else {
                    const sessionId = queryParameters.session_id;
                    navigate("/office/dashboard");
                }
                break;
            case 'unsubscribe':
                if (!queryParameters.token) {
                    setActiveStep(FrontDoorSteps.LogIn);
                } else {
                    const token = queryParameters.token;
                    publicApiClient.unsubscribe(token).then(() => {
                    setMessage('You have been unsubscribed from this list')
                    setActiveStep(FrontDoorSteps.ShowMessage);
                    }).catch(() =>  {
                        setMessage('Link errror');
                        setActiveStep(FrontDoorSteps.ShowMessage);
                    })
                }
        }
    }, [])

    async function loadUserAndSetStep(step: FrontDoorSteps) {
        const user = await Authentication.loadUserFromStorage();
        setUser(user);
        setActiveStep(step);
    }

    async function loadOrgAndRedirectFromThere(user: CurrentUser) {
        const organisation = await new PrivateApiClient().withUser(user).getOrganisation();
        if (!organisation || !organisation.subscription) throw new Error('No organisation found on server');
        switch (organisation.subscription.status) {
            case 'Pending':
            case 'Ended':
                setActiveStep(FrontDoorSteps.SetupBillingSubscription);
                return;
            default: 
                navigate("/office/dashboard");
                return;
        }
    }

    const onCognitoSignupFormComplete = (request: CognitoSignupRequest) => {
        setCredentials(request);
        setActiveStep(FrontDoorSteps.CognitoEmailVerification);
    }
    const onCognitoSignedIn = (user: CurrentUser) => {
        console.log(user);
        setUser(user);
        if (isNullOrEmpty(user.orgId) || user.orgId === 'PENDING') {
            setActiveStep(FrontDoorSteps.CreateOrganisation);
        } else {
            loadOrgAndRedirectFromThere(user);
        }
    }
    const onOrganisationCreated = (organisation: Organisation) => {
        setUser(user.withOrgId(organisation.id));
        setActiveStep(FrontDoorSteps.SetupBillingSubscription);
    }
    const onPasswordResetRequest = (email: string) => {
        setCredentials({ email, password: '' });
        setActiveStep(FrontDoorSteps.PasswordResetSetNew);
    }

    // This has to be manual so it works nicely with the animation
    const redirect = (step: FrontDoorSteps) => {
        setActiveStep(step);
    }

    return <div className={baseClass}>
        <PageMeta
            route={props.location.pathname}>
            <meta name="robots" content="noindex" />
        </PageMeta>
        <div className={childClass('logo-container')}>
            <Link to={'/'}><img src={'/img/logo.svg'} alt='Acorde' /></Link>
        </div>


        {activeStep === FrontDoorSteps.SetupBillingSubscription || activeStep === FrontDoorSteps.SubscriptionChange
            ? <section className={childClass('unstepped-container')}>
                <h1 className={childClass('unstepped-title')}>Select Your Plan</h1>
                <StripeSubscriptionFlow user={user} />
            </section>
            : <div className={childClass('make-paper')}>
                <div className={childClass('step-window')}>
                    <TransitionGroup>
                        <CSSTransition key={activeStep} timeout={500} classNames={childClass('step-view')}>
                            <>

                                {activeStep === FrontDoorSteps.LoggedOut && <section className={childClass('step-view')}>
                                    <SpinningTickWithLabel
                                        className={childClass('tick-message')}
                                        title='Log Out'
                                        labelText='You have been logged out'
                                        isLoading={false} />
                                    <LinkButton className={childClass('ok-cta')}
                                        to={'/'} label="Ok" />
                                </section>}

                                {activeStep === FrontDoorSteps.SubscriptionCancel && <section className={childClass('step-view')}>
                                    <p>Subscription Cancelled</p>
                                    <LinkButton className={childClass('ok-cta')}
                                        to={'/'} label="Ok" />
                                </section>}

                                {activeStep === FrontDoorSteps.ShowMessage && <section className={childClass('step-view')}>
                                    <p>{message}</p>
                                    <LinkButton className={childClass('ok-cta')}
                                        to={'/'} label="Ok" />
                                </section>}                                

                                {activeStep === FrontDoorSteps.LogIn && <section className={childClass('step-view')}>
                                    <LoginForm
                                        redirect={redirect}
                                        title='Log In'
                                        onComplete={onCognitoSignedIn}
                                        onRequiresVerification={onCognitoSignupFormComplete} />
                                </section>}

                                {activeStep === FrontDoorSteps.RegisterCognito && <section className={childClass('step-view')}>
                                    <CognitoSignupForm
                                        redirect={redirect}
                                        title='Get Started With Acorde'
                                        onComplete={onCognitoSignupFormComplete} />
                                </section>}
                                {activeStep === FrontDoorSteps.CognitoEmailVerification && <section className={childClass('step-view')}>
                                    {credentials ?
                                        <CognitoEmailVerifyForm
                                            credentials={credentials}
                                            onComplete={onCognitoSignedIn} />
                                        : <h1>Error!</h1>}
                                </section>}
                                {activeStep === FrontDoorSteps.CreateOrganisation && <section className={childClass('step-view')}>
                                    <OrganisationSetupForm
                                        onComplete={onOrganisationCreated} />
                                </section>}

                                {activeStep === FrontDoorSteps.PasswordResetRequest && <section className={childClass('step-view')}>
                                    <PasswordResetRequestForm
                                        redirect={redirect}
                                        onComplete={onPasswordResetRequest} />
                                </section>}



                                {activeStep === FrontDoorSteps.PasswordResetSetNew && <section className={childClass('step-view')}>
                                    <PasswordResetSetNewform
                                        email={credentials ? credentials.email || '' : ''}
                                        onComplete={onCognitoSignedIn} />
                                </section>}

                                
                                {activeStep === FrontDoorSteps.ManagerSignup && <section className={childClass('step-view')}>
                                    <CompleteAccountCreationForm
                                        email={tempCredentials.email}
                                        oldPassword={tempCredentials.password}
                                        onComplete={onCognitoSignedIn} />
                                </section>}



                            </>
                        </CSSTransition>
                    </TransitionGroup>
                </div>
            </div>}

    </div>
}

function decodeBase64Token(token: string | undefined) {
    if (!token || isNullOrEmpty(token)) return undefined;
    const decoded = atob(base64DecodeUrl(token));
    return decoded;
}


export default FrontDoorLayout;
