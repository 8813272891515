import * as React from 'react';
import { ClassableProps, generateNewId } from '@jcharlesworthuk/your-mum-core/dist/functions';
import { useContext } from 'react';
import { OrganisationManagerContext, OrganisationManagerActionType, LoggedInContext } from '@Contexts';
import { ProcessedImageData } from '@jcharlesworthuk/your-mum-frontend/models';
import AddPhotoAlternateIcon from '@material-ui/icons/AddPhotoAlternate';
import PhotoUploaderAndViewer from '@jcharlesworthuk/your-mum-frontend/components/photos/PhotoUploaderAndViewer';
import Storage from "@Plugins/amplify-storage";
import { PrivateApiClient } from '../../ApiClient';

type Props = ClassableProps;

const imagesS3Url = 'https://acorde-jsontree.s3.eu-west-2.amazonaws.com';

const OrganisationPhotoEditor: React.FunctionComponent<Props> = props => {
  const { organisation, dispatch } = useContext(OrganisationManagerContext);
  const { currentUser } = useContext(LoggedInContext);
  const handlePhotoChange = (chosenImageData: ProcessedImageData) => {
    async function changeImage() {
      debugger;
      var imageBuffer = Buffer.from(chosenImageData.base64, 'base64');
      const fileName = `logo-${generateNewId()}.${chosenImageData.imageType.fileExtension}`;
      const fullFilePath = `${imagesS3Url}/protected/${encodeURIComponent(currentUser.identityId)}/${fileName}`;
      //return;
      await Storage.put(fileName, imageBuffer, { level: 'protected', contentType: chosenImageData.imageType.mimeType });
      console.log(fullFilePath);
      debugger;
      await new PrivateApiClient().withUser(currentUser).updateOrganisation({
        logoUrl: fullFilePath
      });
      dispatch({ type: OrganisationManagerActionType.UpdateLogo, stringValue: fullFilePath });
    }
    changeImage();
  }

  return <PhotoUploaderAndViewer
    imageWidth={600}
    className={props.className}
    alt={organisation.name}
    allowPng={true}
    imageUrl={organisation.logoUrl || '/img/image-placeholder.svg'}
    photoIcon={<AddPhotoAlternateIcon />}
    onChange={handlePhotoChange}
    onHandledError={e => console.error(e)}
    />
}

export default OrganisationPhotoEditor;
