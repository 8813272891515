import * as React from 'react';
import './UpsertEmployeeForm.scss';
import { childClassClosure, ClassableProps, baseClassAnd } from '@jcharlesworthuk/your-mum-core/dist/functions';
import { Employee } from '@Models/Employee';
import { TextField, InputAdornment, ButtonGroup, Button } from '@material-ui/core';
import { useUpsertEmployee } from '@Hooks';
import Autocomplete from '@material-ui/lab/Autocomplete';
import AlternateEmail from '@material-ui/icons/AlternateEmail';
import { ButtonState } from '@jcharlesworthuk/your-mum-frontend/models';
import { useContext } from 'react';
import { OrganisationManagerContext } from '@Contexts';
import Alert from '@material-ui/lab/Alert';

const baseClass = 'upsert-employee';
const childClass = childClassClosure(baseClass);


type Props = ClassableProps & {
  onCancel: () => void;
  onConfirmed: () => void;
  employee: Employee | null;
  teamId: string;
};

const UpsertEmployeeForm: React.FunctionComponent<Props> = (props) => {
  const form = useUpsertEmployee(props.employee, props.teamId);
  const inputVariant = 'outlined';

  return <form className={baseClassAnd(baseClass, props)} noValidate autoComplete="off" onSubmit={form.handleSubmitForm(props.onConfirmed)}>
    <h2 className={childClass('title')}>{props.employee ? 'Edit team member' : 'Create new team member'}</h2>
    {!props.employee && <Alert severity="info">Team members do not need to log in, they receive an email with an anonymous link to the survey.</Alert>}
    <TextField id="fullName"
      className={childClass('full-name')}
      label="Full Name"
      margin="normal"
      fullWidth
      variant={inputVariant}
      value={form.fields.fullName || ''}
      onChange={form.registerInputChangeHandler('fullName')} />
    <TextField id="email"
      className={childClass('email')}
      label="Email Address"
      fullWidth
      margin="normal"
      disabled={!!props.employee}
      variant={inputVariant}
      value={form.fields.email || ''}
      onChange={form.registerInputChangeHandler('email')}
    />
    {form.error && <p className={childClass('error-message')}>{form.error}</p>}
    <div className={childClass('buttons')}>
      <Button size="large" onClick={() => props.onCancel()}>Cancel</Button>
      <Button variant="contained" size="large" color="primary" type="submit" disabled={form.buttonState !== ButtonState.Enabled}>{props.employee ? 'Update' : 'Add'}</Button>
    </div>
  </form>
}

export default UpsertEmployeeForm;