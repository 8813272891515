import { Metric } from "./Metric";
import { QuestionTemplate } from "./QuestionTemplate";
import { SurveyTemplateDto } from "@Dtos";
import { MetricConfiguration } from "./MetricConfiguration";
import { UpdateSurveyTemplateWriteModel } from "@ApiModels";

export class SurveyTemplate {
    constructor(public id: string, public marqueeQuestion: string, public additionalCommentsQuestion: string, public testSurveySent: Date | null, public metrics: MetricConfiguration[], public unansweredSurveyCount: number) {
    }

    cloneWith(value: string | Date | MetricConfiguration[], key: keyof SurveyTemplateDto) {
        switch (key) {
            case 'marqueeQuestion':
                return new SurveyTemplate(this.id, value as string, this.additionalCommentsQuestion, this.testSurveySent, this.metrics, this.unansweredSurveyCount);
            case 'additionalCommentsQuestion':
                return new SurveyTemplate(this.id, this.marqueeQuestion, value as string, this.testSurveySent, this.metrics, this.unansweredSurveyCount);
            case 'testSurveySent':
                return new SurveyTemplate(this.id, this.marqueeQuestion, this.additionalCommentsQuestion, value as Date, this.metrics, this.unansweredSurveyCount);
            case 'metrics':
                return new SurveyTemplate(this.id, this.marqueeQuestion, this.additionalCommentsQuestion, this.testSurveySent, value as MetricConfiguration[], this.unansweredSurveyCount);
        }
    }

    //The TSV takes priority.  Any new questions are added and old questions removed from the survey template
    static fromDtoTsvPriority(dto: SurveyTemplateDto, metrics: { [metricId: string]: Metric }, questionTemplates: { [templateId: string]: QuestionTemplate }) {
        const metricConfigs = Object.keys(metrics).map(metricId => {
            const metric = metrics[metricId];
            if (!dto.metrics[metricId]) {
                dto.metrics[metricId] = {
                    active: false,
                }
            }

            const questions = Object.keys(questionTemplates).filter(x => questionTemplates[x].metric.id === metricId)
                .map(questionTemplateId => {
                    const enabled = dto.metrics[metricId][questionTemplateId] == false ? false : true;
                    const template = questionTemplates[questionTemplateId];
                    return { enabled, template };
                });

            return new MetricConfiguration(metric, questions, dto.metrics[metricId].active);
        });
        return new SurveyTemplate(dto.id, dto.marqueeQuestion, dto.additionalCommentsQuestion, dto.testSurveySent ? new Date(dto.testSurveySent) : null, metricConfigs, dto.unansweredSurveyCount);
    }


    //Not used for MVP, but this allows the template DTO to take priority over which questions to include
    static fromDtoTemplatePriority(dto: SurveyTemplateDto, metrics: { [metricId: string]: Metric }, questionTemplates: { [templateId: string]: QuestionTemplate }) {
        const metricConfigs = Object.keys(dto.metrics).map(metricId => {
            const metric = metrics[metricId];
            if (!metric) throw new Error('Metric not found in dictionary with id ' + metricId);
            const questions = Object.keys(dto.metrics[metricId]).filter(x => x !== 'active')
                .map(questionTemplateId => {
                    const enabled = dto.metrics[metricId][questionTemplateId];
                    const template = questionTemplates[questionTemplateId];
                    if (!template) throw new Error('Could not find question template with id ' + questionTemplateId);
                    return { enabled, template };
                });

            return new MetricConfiguration(metric, questions, dto.metrics[metricId].active);
        });
        return new SurveyTemplate(dto.id, dto.marqueeQuestion, dto.additionalCommentsQuestion, dto.testSurveySent ? new Date(dto.testSurveySent) : null, metricConfigs, dto.unansweredSurveyCount);
    }


    toWriteModel(): UpdateSurveyTemplateWriteModel {
        const result: UpdateSurveyTemplateWriteModel = { metrics: {} };
        for (let metric of this.metrics) {
            const metricItem: {
                active: boolean,
                [questionTemplateId: string]: boolean
            } = {
                active: metric.active
            };
            for (let question of metric.questions) {
                metricItem[question.template.id] = question.enabled;
            }
            result.metrics[metric.metric.id] = metricItem;
        }
        return result;
    }
}
