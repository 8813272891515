import React, { useEffect, useState } from "react";
import './CountdownClock.scss';
import { childClassClosure, ClassableProps, baseClassAnd } from "@jcharlesworthuk/your-mum-core/dist/functions";

const baseClass = 'countdown-clock';
const childClass = childClassClosure(baseClass);

type Props = ClassableProps & {
    targetDate: Date
}

type HourMinuteSecond = {
    h: number[],
    m: number[],
    s: number[]
}

type State = {
    time: HourMinuteSecond,
    next?: HourMinuteSecond
}

function toSeconds(time: HourMinuteSecond): number {
    return time.h[0] * 36000 + time.h[1] * 3600 + time.m[0] * 600 + time.m[1] * 60 + time.s[0] * 10 + time.s[1];
}


function fromSeconds(seconds): HourMinuteSecond {
    return {
        h: [Math.floor(seconds / 36000), Math.floor((seconds % 36000) / 3600)],
        m: [Math.floor((seconds % 3600) / 600), Math.floor((seconds % 600) / 60)],
        s: [Math.floor((seconds % 60) / 10), Math.floor(seconds % 10)]
    }
}

function stepDown(time: HourMinuteSecond): HourMinuteSecond {
    const asSeconds = toSeconds(time);
    if (asSeconds <= 1) return  {
        h: [0,0],
        m: [0,0],
        s: [0,0]
    }
    const newTime = fromSeconds(asSeconds - 1);
    return newTime;
}

const Digit: React.FunctionComponent<{ current: number, next?: number }> = props => {
    return <div className={childClass('block-figure')}>
        <span className={childClass('top', typeof props.next !== 'undefined' && props.next !== props.current ? 'animate' : '')}>{props.current}</span>
        <span className={childClass('top-back', typeof props.next !== 'undefined' && props.next !== props.current ? 'animate' : '')}>
            <span>{typeof props.next !== 'undefined' ? props.next : props.current}</span>
        </span>
        <span className={childClass('bottom')}>{props.current}</span>
        <span className={childClass('bottom-back')}>
            <span>{typeof props.next !== 'undefined' ? props.next : props.current}</span>
        </span>
    </div>
}

const CountdownClock: React.FunctionComponent<Props> = props => {
    const targetDateAsTimeObject = fromSeconds((props.targetDate.getTime() - new Date().getTime()) / 1000);
    const [state, setState] = useState<State>({
        time: targetDateAsTimeObject
    });
    

    useEffect(() => {
        const interval = setInterval(() => {
            setState(prevState => {
                const nextTime = stepDown(prevState.time);
                return {
                    time: prevState.time,
                    next: nextTime
                };
            });
        }, 1000);
        return () => {
            clearInterval(interval);
        }
    }, []);

    useEffect(() => {
        let timeout: NodeJS.Timeout = undefined;
        if (state.next) {
            timeout = setTimeout(() => {
                setState(prevState => {
                    const newState = {
                        time: prevState.next
                    };
                    return newState;
                });
            }, 400) as unknown as NodeJS.Timeout;
        }
        return () => {
            if (timeout) {
                clearTimeout(timeout);
            }
        }
    }, [state])

    return <div className={baseClassAnd(baseClass, props)}>

        <div className={childClass('block')}>
            <span className={childClass('block-title')}>Hours</span>

            <div className={childClass('block-content')}>
                <Digit next={state.next ? state.next.h[0] : undefined} current={state.time.h[0]} />

                <Digit next={state.next ? state.next.h[1] : undefined} current={state.time.h[1]} />
            </div>
        </div>


        <div className={childClass('block')}>
            <span className={childClass('block-title')}>Minutes</span>

            <div className={childClass('block-content')}>
                <Digit next={state.next ? state.next.m[0] : undefined} current={state.time.m[0]} />

                <Digit next={state.next ? state.next.m[1] : undefined} current={state.time.m[1]} />
            </div>
        </div>


        <div className={childClass('block')}>
            <span className={childClass('block-title')}>Seconds</span>

            <div className={childClass('block-content')}>
                <Digit next={state.next ? state.next.s[0] : undefined} current={state.time.s[0]} />

                <Digit next={state.next ? state.next.s[1] : undefined} current={state.time.s[1]} />
            </div>
        </div>

    </div>
}
export default CountdownClock;