import React, { useState, useEffect } from "react"
import "./FreetextEntry.scss";
import { childClassClosure, ClassableProps, baseClassAnd } from "@jcharlesworthuk/your-mum-core/dist/functions";
import ScaleRadioButton from "./ScaleRadioButton";
import { TextField } from "@material-ui/core";

const baseClass = 'freetext-entry';
const childClass = childClassClosure(baseClass);

type Props = ClassableProps & {
    value?: string;
    onChange: (newValue: string) => void;
}

const FreetextEntry: React.FunctionComponent<Props> = props => {
    const [show, setShow] = useState(false);
    useEffect(() => {
        const timer = setTimeout(() => {
            setShow(true);
        }, 500);
        return () => clearTimeout(timer);
    }, []);

    return <form className={baseClassAnd(baseClass, props, 'something-selected')} onSubmit={e => e.preventDefault()} noValidate autoComplete="off">
        <div className={childClass('container')}>
            {show &&
                <TextField
                    fullWidth
                    variant="outlined"
                    multiline
                    rows={6}
                    rowsMax={6}
                    value={props.value || ''}
                    onChange={e => props.onChange(e.currentTarget.value)}
                />
            }
        </div>
    </form>
}

export default FreetextEntry;
