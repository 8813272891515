import * as React from 'react';
import './SingleSurveyParticipantTotal.scss';
import { ClassableProps, childClassClosure, baseClassAnd } from "@jcharlesworthuk/your-mum-core/dist/functions";
import { Paper } from '@material-ui/core';
import { HistoricalDataAnalysisContext, OrganisationManagerContext } from '@Contexts';
import { useContext } from 'react';
import DivergingStackedBarChart from '../graphs/DivergingStackedBarChart';
import LinkButton from '../buttons/LinkButton';
import ScoreCircle from '../indicators/ScoreCircle';
import CircleIndicator from '../indicators/CircleIndicator';

const baseClass = 'single-survey-participant-total';
const childClass = childClassClosure(baseClass);

type Props = ClassableProps & {
    paperClass?: string;
    titleClass?: string;
    subtitleClass?: string;
    includeCtaLink?: string;
};

const SingleSurveyParticipantTotal: React.FunctionComponent<Props> = props => {
    const { historicalData } = useContext(HistoricalDataAnalysisContext);

    return <div className={baseClassAnd(baseClass, props)}>
        <Paper className={props.paperClass} elevation={1}>
            <h3 className={props.titleClass}>You Had <strong>{historicalData.latestSurvey.participationCount}</strong> Responses</h3>
            <p className={[props.subtitleClass, childClass('subtitle')].join(' ')}>Out of {historicalData.latestSurvey.invitedCount}</p>
            <CircleIndicator className={props.className} value={historicalData.latestSurvey.participationCount} maximum={historicalData.latestSurvey.invitedCount} triColour={true} />
        </Paper>
        {props.includeCtaLink && <div className={childClass('buttons-container')}>
                <LinkButton className={childClass('cta')}
                    style="grey"
                    to={props.includeCtaLink} label="Add More People" />
            </div>
            }
    </div>
}

export default SingleSurveyParticipantTotal;