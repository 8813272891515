import * as React from 'react';
import './ArticleAsideBox.scss';
import { childClassClosure, baseClassAnd, ClassableProps } from '@jcharlesworthuk/your-mum-core/dist/functions';
import FollowUsBox from '@jcharlesworthuk/your-mum-frontend/components/boxes/FollowUsBox';
import { mapMetadataToSocialSites } from '@GraphQLMappers';
import { OutboundLink } from 'gatsby-plugin-google-analytics';
import { SiteSiteMetadata } from '@GraphQLModels';
import LinkButton from '../buttons/LinkButton';

const baseClass = 'article-aside-box';
const childClass = childClassClosure(baseClass);

type Props = ClassableProps & {
    siteMetadata: SiteSiteMetadata;
};

const ArticleAsideBox: React.FunctionComponent<Props> = props => {
    return <aside className={baseClassAnd(baseClass, props)}>
        <div className={childClass('signup-box')}>
            <h4 className={childClass('signup-box-title')}>Get Started With Acorde</h4>
            <p className={childClass('signup-box-blurb')}>{props.siteMetadata.shortDescription}</p>
            <LinkButton className={childClass('signup-box-cta')} to='/account/register' label='Start Now' />
        </div>
        <FollowUsBox
            className={childClass('box')}
            pages={Array.from(mapMetadataToSocialSites(props.siteMetadata.social))}
            linkComponent={OutboundLink}
        />
    </aside>
}

export default ArticleAsideBox;