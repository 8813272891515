import React from "react"
import "./_CommonShared.scss";
import "./LandingPageLayout.scss";
import { graphql, Link } from "gatsby"
import { Mdx, MdxConnection } from "@GraphQLModels";
import { childClassClosureMultiple, childClassClosure } from "@jcharlesworthuk/your-mum-core/dist/functions";
import MarketingSiteHeader from "@StaticQueryComponents/MarketingSiteHeader";
import MarketingSiteFooter from "@StaticQueryComponents/MarketingSiteFooter";
import MdxBlock from "../components/markup/MdxBlock";
import PageMeta from "@StaticQueryComponents/PageMeta";
import AcordeContactForm from "../components/forms/AcordeContactForm";
import { mapMdxToArticle } from "@GraphQLMappers";
import { BlogArticlesContextProvider } from "@Contexts";
import ArticleCta from "../components/markup/ArticleCta";
import LinkButton from "../components/buttons/LinkButton";

const baseClass = 'landing-page-layout';
const childClass = childClassClosure(baseClass);

export type Props = {
  pageContext: {
    id: string,
    showCta: 'true' | 'false' | undefined
  };
  data: {
    mdx: Mdx,
    latestArticles: MdxConnection
  }
}

export default function LandingPageLayout(props: Props) {
  const latestArticles = props.data.latestArticles ? props.data.latestArticles.edges
    .filter(x => x.node.fields.route != props.data.mdx.fields.route)
    .map(edge => mapMdxToArticle(edge.node))
    : [];
  return (
    <div className={baseClass}>
      <PageMeta
        title={props.data.mdx.frontmatter.title}
        description={props.data.mdx.frontmatter.title}
        route={props.data.mdx.fields.route}>
      </PageMeta>
      <MarketingSiteHeader className={childClass('header')} />

      <div className={childClass('main-container')}>
        <section className={childClass('title-block')}>
          <h1 className={childClass('title')}>{props.data.mdx.frontmatter.title}</h1>
          {props.data.mdx.frontmatter.standfirst && <h2 className={childClass('standfirst')}>{props.data.mdx.frontmatter.standfirst}</h2>}
          { props.pageContext.showCta !== 'false' &&
          <div className={childClass('title-cta-block')}>
            <LinkButton to='/account/register' label='Get Started' className={childClass('cta')} />
            <Link to='/account/login' className={childClass('other-link')}>Log In</Link>
          </div> }
        </section>
        <section className={childClass('article-container')}>

          <BlogArticlesContextProvider initialState={{ latestArticles }}>
            <MdxBlock mdx={props.data.mdx}
              className={childClass('markdown')} />
          </BlogArticlesContextProvider>
        </section>
      </div>
      <section className={childClass('sub-footer')}>
        <div className={childClass('sub-footer-container')}>
          <div className={childClass('sub-footer-homelink')}>
            <Link to="/">
              <img src={'/img/logo.svg'} alt='Acorde' />
            </Link>
          </div>
        </div>
      </section>

      <MarketingSiteFooter />
    </div>
  )
}

export const pageQuery = graphql`
  query LandingPageLayoutQuery($id: String) {
      mdx(id: {eq: $id }) {
        id
        fields {
          route
        }
        frontmatter {
          title
          standfirst
        }
        body
      }
      latestArticles: allMdx(filter: { 
        frontmatter: {
                author: {ne: null} 
                unlisted: {ne: true} 
            }
       }
        sort: { 
          fields: [frontmatter___date]
          order: DESC 
          }
        limit: 5) {
        edges {
          node {
            fields {
              route
            }
            frontmatter {
              title
              author
              mainImage
              date
              standfirst
            }
            timeToRead
          }
        }
      }
  }
  `

