import React from "react"
import "./_CommonShared.scss";
import "./ArticlePageLayout.scss";
import { graphql, Link } from "gatsby"
import { Mdx, Site, MdxConnection } from "@GraphQLModels";
import { childClassClosure, childClassClosureMultiple } from "@jcharlesworthuk/your-mum-core/dist/functions";
import MarketingSiteHeader from "@StaticQueryComponents/MarketingSiteHeader";
import MarketingSiteFooter from "@StaticQueryComponents/MarketingSiteFooter";
import { Article } from "@jcharlesworthuk/your-mum-frontend/models";
import PageMeta from "@StaticQueryComponents/PageMeta";
import MdxBlock from "../components/markup/MdxBlock";
import { mapMdxToArticle, mapToWebsiteInformation, mapMetadataToSocialSites } from "@GraphQLMappers";
import CookieBanner from "@jcharlesworthuk/your-mum-frontend/components/notifications/CookieBanner";
import { getArticleStructuredData } from "@jcharlesworthuk/your-mum-frontend/components/seo/ArticleStructuredData";
import { changeSvgToPng } from "@jcharlesworthuk/your-mum-core/dist/functions/url-helpers";
import ArticleList from "@jcharlesworthuk/your-mum-frontend/components/lists/ArticleList";
import LatestArticles from "@StaticQueryComponents/LatestArticles";

const baseClass = 'article-page-layout';
const childClass = childClassClosure(baseClass);

interface IProps {
  pageContext: {
    id: string
  };
  data: {
    mdx: Mdx,
    site: Site
  }
}

export default function ArticlePageLayout(props: IProps) {
  const article = mapMdxToArticle(props.data.mdx);
  console.log(article);
  const website = mapToWebsiteInformation(props.data.site.siteMetadata);
  // const latestArticles = props.data.latestArticles ? props.data.latestArticles.edges
  // .filter(x => x.node.fields.route != props.data.mdx.fields.route)
  // .map(edge => mapMdxToArticle(edge.node))
  // : [];


  return (
    <div className={baseClass}>
      <PageMeta
        title={article.title}
        description={article.standfirst}
        route={props.data.mdx.fields.route}
        imageUrl={changeSvgToPng(props.data.mdx.frontmatter.mainImage)}>
        <meta property="og:type" content="article" />
        <script className='structured-data-list' type="application/ld+json">{getArticleStructuredData(article, website)}</script>
      </PageMeta>
      <CookieBanner />

      <MarketingSiteHeader className={childClass('header')} />
      <div className={childClass('top-image-container')}>
        <img src={article.mainImage} alt={article.title} />
      </div>
      <div className={childClass('main-container')}>
        <section className={childClass('article-container')}>
          <h1 className={childClass('title')}>{article.title}</h1>
          <div className={childClass('title-info')}>
            <p>By {article.authorName} {props.data.mdx.frontmatter.attribution && 
                <>, vectors by <a href={`https://www.${props.data.mdx.frontmatter.attribution.toLowerCase()}.com`}>{props.data.mdx.frontmatter.attribution}</a></>
              }
            </p>
            <p>{article.dateString} • {article.timeToRead} min read</p>
          </div>
          <MdxBlock mdx={props.data.mdx}
            className={childClass('markdown')} />

        </section>
        <aside className={childClass('rhs-column')}>
        </aside>
      </div>

      {/* <section className={childClass('section')}>
        <FixedShareLinks title={article.title} route={article.route} baseUrl="https://acorde.app" />
        <div className={childClass('container')}>
          <MdxBlock mdx={props.data.mdx}
            className={childClass('markdown')} />


            
          <ArticleAsideBox className={childClass('rhs-aside')} siteMetadata={props.data.site.siteMetadata} />
        </div>
      </section>
       */}

      <section className={childClass('following-section')}>
        <h4 className={childClass('following-section-title')}>More Articles from Acorde</h4>
        <LatestArticles className={childClass('other-articles')} excludeRoute={props.data.mdx.fields.route} />
      </section>
      <MarketingSiteFooter />
    </div>
  )
}

export const pageQuery = graphql`
  query ArticlePageLayoutQuery($id: String) {
      mdx(id: {eq: $id }) {
        id
        fields {
          route
        }
        frontmatter {
          title
          date
          author
          mainImage
          standfirst
          attribution
        }
        timeToRead
        body
      }
      site {
            siteMetadata {
                title
                siteUrl
                siteLogoUrl
                siteLogoUrlSquare
                callToAction
                shortDescription
                social {
                  twitter {
                      handle,
                      url
                  }
                  facebook {
                      handle,
                      url
                  }
                }
            }
      }
  }
  `

