import React, { useEffect } from "react"
import "./_CommonShared.scss";
import "./PersonalisedLandingPageLayout.scss";
import { graphql, Link } from "gatsby"
import { Mdx, MdxConnection } from "@GraphQLModels";
import { childClassClosureMultiple, childClassClosure, isNullOrEmpty, base64DecodeUrl } from "@jcharlesworthuk/your-mum-core/dist/functions";
import MarketingSiteHeader from "@StaticQueryComponents/MarketingSiteHeader";
import MarketingSiteFooter from "@StaticQueryComponents/MarketingSiteFooter";
import { Redirect, RouteComponentProps, Router } from "@reach/router";
import MdxBlock from "../components/markup/MdxBlock";
import PageMeta from "@StaticQueryComponents/PageMeta";
import AcordeContactForm from "../components/forms/AcordeContactForm";
import { mapMdxToArticle } from "@GraphQLMappers";
import { BlogArticlesContextProvider } from "@Contexts";
import ArticleCta from "../components/markup/ArticleCta";
import LinkButton from "../components/buttons/LinkButton";
import { parse as parseQueryString } from 'query-string';
import ArticleImage from "@jcharlesworthuk/your-mum-frontend/components/articles/ArticleImage";
import SubscriptionSelector from "../components/selling/SubscriptionSelector";
import TryExampleSurveyArrow from "../components/user-hints/TryExampleSurveyArrow";
import SubscriptionSelectorSpecialPlans from "../components/selling/SubscriptionSelectorSpecialPlans";

const baseClass = 'personalised-landing-page-layout';
const childClass = childClassClosure(baseClass);

type QueryParams = {
  /** Prospect Name */
  n: string;
  /** Company Name */
  c: string;
  /** Glassdoor company id */
  gd: string;

  /** Glassdoor state: Could Be Higher, Great */
  gdr: 'cbh' | 'grt';

  /** Free plans only if fr */
  fr: 'fr' | string | undefined;
}

export type Props = {
  location: {
    pathname: string,
    search: string
  },
  pageContext: {
  },
  data: {
    latestArticles: MdxConnection
  }
}

export default function PersonalisedLandingPageLayout(props: Props) {

  const queryParameters = parseQueryString(props.location.search) as QueryParams;
  const pathSections = props.location.pathname.split('/');

  // useEffect(() => {
  //   //The glassdoor
  //   const script = document.createElement('script');
  //   script.src = "https://www.glassdoor.co.uk/static/js/api/widget/v1.js";
  //   script.async = true;
  //   document.body.appendChild(script);
  //   return () => {
  //     document.body.removeChild(script);
  //   }
  // }, []);


  // const latestArticles = props.data.latestArticles ? props.data.latestArticles.edges
  //   .map(edge => mapMdxToArticle(edge.node))
  //   : [];

  console.log('PersonalisedLandingPageLayout');
  console.log(pathSections);
  console.log(queryParameters);

  if (pathSections.length < 2 || !queryParameters || isNullOrEmpty(queryParameters.c) || isNullOrEmpty(queryParameters.n) || isNullOrEmpty(queryParameters.gd) || isNullOrEmpty(queryParameters.gdr)) {
    return <Redirect noThrow to="/" />
  }


  let vars: {
    company: string,
    prospectName: string,
    glassdoorId: string,
    glassdoorGood: boolean
  } = undefined;
  try {
    vars = decodeParams(queryParameters);
  } catch {
    return <Redirect noThrow to="/" />
  }

  if (history) {
    //history.pushState(null, "", location.href.split("?")[0]);
  }

  return (
    <div className={baseClass}>
      {/* <PageMeta
        title={props.data.mdx.frontmatter.title}
        description={props.data.mdx.frontmatter.title}
        route={props.data.mdx.fields.route}>
      </PageMeta> */}
      <MarketingSiteHeader className={childClass('header')} />

      <div className={childClass('full-row')}>
        <div className={childClass('main-container', 'pad-bottom')}>
          <section className={childClass('title-block')}>
            <h1 className={childClass('title')}>Hi {vars.prospectName}</h1>
            <h2 className={childClass('subtitle')}>Let's find out how pulse surveys with Acorde would work for your team at {vars.company}</h2>
          </section>
        </div>

      </div>
      <div className={childClass('full-row')}>
        <div className={childClass('main-container')}>
          <section className={childClass('annotated-block')}>
            <div className={childClass('annotated-block-text', 'pad')}>
              <h2>What is a Pulse Survey?</h2>
              <p>A quick survey emailed to your team at {vars.company} every week, asking them how they are feeling about work and if they have any immediate feedback.  The surveys are filled out anonymously to encourage your team to be candid about how they are feeling.</p>
            </div>
            <div className={childClass('annotated-block-image')}>
                <img alt="Acorde" src="/marketing/marketing-slide-no-bg.svg" />
            </div>
          </section>

        </div>
      </div>
      <div className={childClass('full-row', 'backsplash')}>
        <div className={childClass('main-container')}>
          <section className={childClass('glassdoor-block')}>

            <div className={childClass('glassdoor-block-lhs')}>
              <iframe id="gdFrame" src={`https://www.glassdoor.co.uk/api/api.htm?version=1&action=employer-combo&t.s=w-m&format=300x400&employerId=${vars.glassdoorId}&activeTab=R&t.a=i&responseType=embed`} scrolling="no" name="widgetIFrame" style={{ visibility: 'visible', border: 'none', overflow: 'hidden', width: '300px', height: '400px', margin: '0 auto' }}></iframe>
            </div>
            <div className={childClass('glassdoor-block-rhs')}>
              <h2>{vars.company} on Glassdoor</h2>
              <p>Glassdoor is a popular way for employees to write anonymous reviews about your organisation. {vars.company}'s score {vars.glassdoorGood ? 'is incredible!  But we need to keep it that way in the future right?  Regular feedback surveys can help address any concerns early on, and show your team their opinions matter.' : 'is good but it could be higher still, by asking your team for feedback more regularly you can make them feel valued and more likely to promote your company on platforms like Glassdoor.'}</p>
            </div>
          </section>

        </div>
      </div>
      <div className={childClass('full-row', 'grey', 'pad-bottom')}>
        <div className={childClass('main-container')}>
          <section className={childClass('annotated-block')}>
            <div className={childClass('annotated-block-text', 'pad')}>
              <h2>Your Suggested Metrics</h2>
              <p>Pulse surveys don't just ask your employees how they are feeling each week, we also add in a couple of questions designed to measure specific metrics about your team.  You can configure these in the platform once you are set up.</p>
              <p>From what I've seen, {vars.company} has a really strong company culture, so we would suggest measuring the <strong>Culture</strong> metric to keep tabs on how aligned your employees feel with your company values over time.</p>
              <p>You might also consider selecting the <strong>Work Life Balance</strong> metric.  With all the challenges 2020 has posed to workplaces around the world, ensuring your employees are getting this balance right could be crucial to their wellbeing and therefore their success at work.</p>
            </div>
            <div className={childClass('annotated-block-image')}>
              <img alt="Metrics" src="/marketing/metrics-trends.svg" />
            </div>
          </section>


          <section className={childClass('annotated-block', 'reverse')}>
            <div className={childClass('annotated-block-image', 'pad')}>
              <img alt="Metrics" src="/screen-demos/surveys-page.svg" />
            </div>

            <div className={childClass('annotated-block-text')}>
              <h2>Survey Frequency</h2>
              <p>Our surveys usually go out every week, contain three multiple-choice questions and a comments box.  Most of the teams we help find that sending surveys <strong>weekly on a Friday</strong> offer the most insight whilst not creating much distraction for your team.</p>
              <p>We would recommend keeping this at once per week on a Friday when the week's work is still fresh in the mind.</p>
            </div>
          </section>

        </div>
      </div>

      <section className={childClass('example-bar')}>
        <div className={childClass('example-bar-container')}>
          <div className={childClass('example-bar-logo')}>
            <img src={'/img/logo.svg'} alt='Acorde' />
          </div>
          <div className={childClass('example-bar-link-block')}>
            <TryExampleSurveyArrow className={childClass('example-bar-arrow')} />
            <LinkButton target="_blank" className={childClass('example-bar-cta')} to="https://acorde.app/complete/?o=example&s=4d2nd41b1b4w77lbqdlnqfk8mzfrsob3kjncsdls&u=3kjncf4&t=sakljhrcv" label="Try Example" />
          </div>
        </div>
      </section>


      <section className={childClass('pricing-block')}>
        <div className={childClass('pricing-block-container')}>
          <h2 id="pricing" className={childClass('centre-h2')}>Choose Your Usage Plan</h2>
          {queryParameters.fr === 'fr'
            ? <SubscriptionSelectorSpecialPlans onSelect={plan => false} companyName={vars.company} />
            : <SubscriptionSelector onSelect={plan => false} />}
        </div>
      </section>

      <MarketingSiteFooter />
    </div>
  )
}

export const pageQuery = graphql`
  query PersonalisedLandingPageLayoutQuery {
      latestArticles: allMdx(filter: { 
        frontmatter: {
                author: {ne: null} 
                unlisted: {ne: true} 
            }
       }
        sort: { 
          fields: [frontmatter___date]
          order: DESC 
          }
        limit: 5) {
        edges {
          node {
            fields {
              route
            }
            frontmatter {
              title
              author
              mainImage
              date
              standfirst
            }
            timeToRead
          }
        }
      }
  }
  `


function decodeParams(fromUrl: QueryParams) {
  return {
    company: atob(base64DecodeUrl(fromUrl.c)),
    prospectName: atob(base64DecodeUrl(fromUrl.n)),
    glassdoorId: fromUrl.gd,
    glassdoorGood: fromUrl.gdr === 'grt' ? true : false
  }
}
