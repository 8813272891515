import * as React from 'react';
import './ScoreTrendGraph.scss';
import { childClassClosure, baseClassAnd, ClassableProps } from '@jcharlesworthuk/your-mum-core/dist/functions';
import ChartistGraph from 'react-chartist';
import { ILineChartOptions } from 'chartist';
import { useEffect, useState } from 'react';

const baseClass = 'score-trend-graph';
const childClass = childClassClosure(baseClass);

type Props = ClassableProps & {
    scores: { date: Date, value: number }[]
    height?: number;
};

const ScoreTrendGraph: React.FunctionComponent<Props> = props => {

    var data = {
        labels: props.scores.map(x => x.date.toLocaleDateString()),
        series: [
            props.scores.map(x => x.value)
        ]
    };

    const options: ILineChartOptions = {
        high: 10,
        low: 0,
        fullWidth: true,
        height: props.height,
        axisX: {
            showLabel: true,
            showGrid: true,
            labelInterpolationFnc: function (value, index) {
                return index % 2 === 0 ? value : null;
            }
        }
    };
    return <div className={baseClassAnd(baseClass, props)}>
        <ChartistGraph data={data} options={options} type='Line' />
    </div>
}

export default ScoreTrendGraph;
