import * as React from 'react';
import './AcordeAnimatedSteps.scss';
import { childClassClosure, baseClassAnd, ClassableProps } from '@jcharlesworthuk/your-mum-core/dist/functions';
import HandwritingNumberCircle from '../user-hints/HandwritingNumberCircle';
import ShowArrowSvg from '@jcharlesworthuk/your-mum-frontend/svg/ShowArrowSvg';
import { useRef, useState, useEffect } from 'react';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import AddUserScreenDemo from '../screendemos/AddUserScreenDemo';
import SurveyEditorScreenDemo from '../screendemos/SurveyEditorScreenDemo';
import SurveyCompletionScreenDemo from '../screendemos/SurveyCompletionScreenDemo';
import AnalysisScreenDemo from '../screendemos/AnalysisScreenDemo';

const baseClass = 'animated-steps';
const childClass = childClassClosure(baseClass);

type Props = ClassableProps & {
    descriptions: 'short' | 'detailed';
};

const AcordeAnimatedSteps: React.FunctionComponent<Props> = props => {
    const stepRefs = [
        useRef<HTMLDivElement>(null),
        useRef<HTMLDivElement>(null),
        useRef<HTMLDivElement>(null),
        useRef<HTMLDivElement>(null)
    ]
    const [ animated, setAnimated ] = useState<boolean[]>([ false, false, false, false ]);

    //Hooks for animating the elements when scrolled to
    const [hasScrolledStep, setHasScrolledStep] = useState(0)
    useScrollPosition(({ currPos, prevPos }) => {
        const isOverThreshold = currPos.y < -500;
        if (isOverThreshold && hasScrolledStep == 0) {
            setHasScrolledStep(1);
            setTimeout(() => setHasScrolledStep(2), 200);
        }
        if (!isOverThreshold && hasScrolledStep > 0) {
            setHasScrolledStep(0);
        }
        if (currPos.y > prevPos.y) return;

        function shouldAnimate(ref: React.MutableRefObject<HTMLDivElement>) {
            const isScrolledTo = ref.current && 
            window.innerHeight - ref.current.getBoundingClientRect().top > 300 
            && !ref.current.classList.contains('shown');
            if (isScrolledTo) {
                ref.current.classList.add('shown');
            }
            return isScrolledTo;
        }
        const newAnimated = [...animated];
        for(let i = 0; i < animated.length; i++) {
            if (shouldAnimate(stepRefs[i])) {
                newAnimated[i] = true;
            }    
        }
        setAnimated(newAnimated);

    }, [hasScrolledStep])

    return <section className={baseClassAnd(baseClass, props)}>

        <div className={childClass('row', 'step-1')}>
            <div className={childClass('panel')} ref={stepRefs[0]}>
                <HandwritingNumberCircle number={1} />
                <AddUserScreenDemo animate={animated[0]} />
                <h5 className={childClass('title')}>Add Your People</h5>
                <p className={childClass('para')}>{props.descriptions === 'short' ? 'Acorde works with any team size from five to five thousand.' : 'Add your employees as recipients of the survey using their work email address.  They will be sent an email every time your survey begins with a link they can use to submit their answers,'}</p>
            </div>
            <div className={childClass('arrow')}>
                <ShowArrowSvg />
            </div>
        </div>
        <div className={childClass('row', 'step-2')}>
            <div className={childClass('panel')} ref={stepRefs[1]}>
                <HandwritingNumberCircle number={2} />
                <SurveyEditorScreenDemo animate={animated[1]} />
                <h5 className={childClass('title')}>Choose Your Questions</h5>
                <p className={childClass('para')}>{props.descriptions === 'short' ? 'We have over 40 questions spread across 10 metrics, from general happiness and wellbeing to productivity metrics and alignment with your company values.' : 'We have over 40 questions spread across 10 metrics, from general happiness and wellbeing to productivity metrics and alignment with your company values.'}</p>
            </div>
            <div className={childClass('arrow')}>
                <ShowArrowSvg />
            </div>
        </div>
        <div className={childClass('row', 'step-3')}>
            <div className={childClass('panel')} ref={stepRefs[2]}>
                <HandwritingNumberCircle number={3} />
                <SurveyCompletionScreenDemo animate={animated[2]} />
                <h5 className={childClass('title')}>Gather Feedback</h5>
                <p className={childClass('para')}>{props.descriptions === 'short' ? 'The platform sends out a tailored survey each week targeted to your metrics.  It is quick and easy to fill out whether on desktop or mobile.' : 'When your survey goes lives on the day of your choice your employees will begin submitting their answers.  Just sit back and wait for the survey period to end.'}</p>

            </div>
            <div className={childClass('arrow')}>
                <ShowArrowSvg />
            </div>
        </div>
        <div className={childClass('row', 'step-4')}>
            <div className={childClass('panel')} ref={stepRefs[3]}>
                <HandwritingNumberCircle number={4} />
                <AnalysisScreenDemo animate={animated[3]} />
                <h5 className={childClass('title')}>Analyse Results</h5>
                <p className={childClass('para')}>{props.descriptions === 'short' ? 'See a breakdown of your survey results and measure trends for your metrics over time.' : 'Once your survey has ended log into the Acorde platform to see a breakdown of your results, how many people answered, and trends for your metrics over time.'}</p>
            </div>
            <div className={childClass('arrow')}>
                &nbsp;
            </div>
        </div>
    </section>
}

export default AcordeAnimatedSteps;