import { Metric } from "./Metric";
import { LikertMetricResultDto } from "@Dtos";
import { DiscreteLikertResult } from "./DiscreteLikertResult";

export abstract class LikertResult {
    constructor(public counts: {[score: number]: number}) {
    }
    
    public get score(): number {
        const sum = Object.keys(this.counts).reduce((a,b) => a + (parseInt(b) * this.counts[b]), 0);
        const totalAnswerCount = Object.keys(this.counts).reduce((a,b) => a + this.counts[b], 0);
        const average = sum / totalAnswerCount;
        const mode = Object.keys(this.counts).reduce((current, item) => this.counts[item] > this.counts[current] ? item : current, 0);
        // console.log('counts: ', this.counts);
        // console.log('sum: ', sum);
        // console.log('totalAnswerCount: ' + totalAnswerCount);
        // console.log('average: ' + average);
        // console.log('mode: ' + mode);
        return Math.round(((average + 2) / 4) * 10);
    }

    get positiveDivergence() {
        return (this.counts[2] + this.counts[1] + (this.counts[0] / 2));
    }
    get negativeDivergence() {
        return (this.counts[-2] + this.counts[-1] + (this.counts[0] / 2));
    }

    get divergenceFromZero() {
        return (this.negativeDivergence > this.positiveDivergence ? this.negativeDivergence : this.positiveDivergence) * 2;
    }
}
