import { ScaleType } from "./ScaleType";
import { QuestionToAnswerDto } from "@Dtos";

export class QuestionToAnswer {
    constructor(
        public templateId: string,
        public text: string, 
        public scale: ScaleType,
        public isInverse: boolean,
        public isOptional: boolean,
        public metricId: string | null) {
    }
    public answer?: number | string;

    get numericAnswer() {
        return this.answer as number;
    }

    public withAnswer(answer: number | string) {
        const cloned = new QuestionToAnswer(this.templateId, this.text, this.scale, this.isInverse, this.isOptional, this.metricId);
        cloned.answer = answer;
        return cloned;
    }

    static fromDto(dto: QuestionToAnswerDto) {
        return new QuestionToAnswer(dto.templateId, dto.text, dto.scale, dto.isInverse == true, dto.isOptional == true, dto.metricId || null);
    }
}
