import React, { useContext } from "react"
import './GuidedSetupCards.scss';
import { childClassClosure, ClassableProps, baseClassAnd, fullDaysUntil } from "@jcharlesworthuk/your-mum-core/dist/functions";
import { Paper } from "@material-ui/core";
import { LoggedInContext, HelpIndicatorsContext, HelpIndicatorsActionType, OrganisationManagerContext, SurveyFrequencySettingsContext, SurveyManagerContext } from "@Contexts";
import { Link } from "gatsby";
import SendTestSurveyButton from "../buttons/SendTestSurveyButton";
import SpinningTick from "@jcharlesworthuk/your-mum-frontend/components/indicators/SpinningTick";
import { useLocalStorageSetting } from "@Hooks";

const baseClass = 'guided-setup-cards';
const childClass = childClassClosure(baseClass);

type Props = ClassableProps & {
}

enum CardState {
  Active = 1,
  NotReadyYet = 2,
  Done = 3
}

type CardProps = {
  number: number;
  linkTo?: string;
  state: CardState;
  children: JSX.Element[];
};

const SingleCard: React.FunctionComponent<CardProps> = (props: CardProps) => {
  return <Paper className={childClass('card', CardState[props.state].toLowerCase())}>
    <div className={childClass('card-lhs')}>
      {props.state === CardState.Done
        ? <SpinningTick isLoading={false} invertColours={false} />
        : <div className={childClass('card-number')}>{props.number}</div>}
    </div>
    <div className={childClass('card-rhs')}>
      {props.children}
    </div>
  </Paper>
}

const GuidedSetupCards: React.FunctionComponent<Props> = (props: Props) => {
  const { organisation } = useContext(OrganisationManagerContext);
  const { frequencySettings } = useContext(SurveyFrequencySettingsContext);
  const { template } = useContext(SurveyManagerContext);
  const [surveyPageVisitedCookie, setSurveyPageVisitedCookie] = useLocalStorageSetting("survey-visited", "0");
  const dones = [organisation.teamArray.length > 0, surveyPageVisitedCookie != "0", Boolean(template.testSurveySent)];

  return <div className={baseClassAnd(baseClass, props)}>
    <div className={childClass('item-container')}>
      <Link to="/office/people" className={childClass('link')}>
        <SingleCard number={1} state={dones[0] ? CardState.Done : CardState.Active}>
          <h4>Set up your teams</h4>
          <p>Add the people at your organisation you want to gather feedback from, they will be emailed a link each week to complete their survey</p>
        </SingleCard>
      </Link>
    </div>

    <div className={childClass('item-container')}>
      <Link to="/office/surveys" className={childClass('link')}>
        <SingleCard number={2} state={dones[0] ? dones[1] ? CardState.Done : CardState.Active : CardState.NotReadyYet}>
          <h4>Design your survey</h4>
          <p>Configure the questions on your survey and choose which metrics you want to measure</p>
        </SingleCard>
      </Link>
    </div>

    <div className={childClass('item-container')}>
      <SingleCard number={3} state={(dones[0] && dones[1]) ? dones[2] ? CardState.Done : CardState.Active : CardState.NotReadyYet}>
        <h4>Send a Test Survey</h4>
        <p>Send yourself a test version of your survey</p>
        <div className={childClass('card-button-row')}>
          { (dones[0] && dones[1] && !dones[2]) && <SendTestSurveyButton/>}
        </div>
      </SingleCard>
    </div>

    <div className={childClass('item-container')}>
      <SingleCard number={4} state={dones.every(x => x === true) ? CardState.Active : CardState.NotReadyYet}>
        <h4>Wait for your survey to start</h4>
        <p>Your first feedback survey will automatically be started {fullDaysUntil(frequencySettings.nextDate)}</p>
      </SingleCard>
    </div>
  </div>
}

export default GuidedSetupCards;