import * as React from 'react';
import { ClassableProps, childClassClosure, baseClassAnd } from '@jcharlesworthuk/your-mum-core/dist/functions';
import { ButtonState, getCssClassFromButtonState } from '@jcharlesworthuk/your-mum-frontend/models';
import { Dialog, Button, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@material-ui/core';
import { useState, useContext } from 'react';
import { SurveyManagerContext, LoggedInContext, SurveyManagerActionType } from '@Contexts';
import { PrivateApiClient } from '../../ApiClient';


type Props = {
  disabled?: boolean;
}


const SendTestSurveyButton: React.FunctionComponent<Props> = props => {
    const { currentUser } = useContext(LoggedInContext);
    const { template, dispatch } = useContext(SurveyManagerContext);
    const [sendTestSurveyState, setSendTestSurveyState] = useState<'available' | 'sent' | 'dialog'>('available');
    const doneRecently = template.testSurveySent ? ((new Date().getTime() - template.testSurveySent.getTime()) / 60000) < 10 : false;  // 10 minutes ago

    return <>
    <Button variant="contained"  size="large" type="button" onClick={() => setSendTestSurveyState('dialog')} color="primary" disabled={props.disabled === true || doneRecently || sendTestSurveyState !== 'available'}>Test Survey</Button>
    <Dialog
          open={sendTestSurveyState === 'dialog'}
        >
          <DialogTitle id="alert-dialog-title">Test Survey</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Do you want to send a test survey to your email address {currentUser.email}?
          </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setSendTestSurveyState('available')} autoFocus>
              Cancel
          </Button>
            <Button onClick={() => {
                new PrivateApiClient().withUser(currentUser).triggerManualAction('trigger-test-survey');
                dispatch({ type: SurveyManagerActionType.TestSurveySent });
                setSendTestSurveyState('sent');
            }} color="primary">
              Send
          </Button>
          </DialogActions>
        </Dialog>
        </>
}

export default SendTestSurveyButton;