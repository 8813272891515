import * as React from 'react';
import './FreetextQuestionPreview.scss';
import { childClassClosure, ClassableProps, baseClassAnd } from '@jcharlesworthuk/your-mum-core/dist/functions';
import { useContext, useEffect, useState } from 'react';
import { SurveyManagerContext, SurveyManagerActionType, LoggedInContext } from '@Contexts';
import { ExpansionPanel, ExpansionPanelSummary, FormControlLabel, Switch, ExpansionPanelDetails, RadioGroup, Radio, Checkbox, Paper, Drawer, TextField } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useDebounce } from '@Hooks';
import { PrivateApiClient } from '../../ApiClient';
import { SaveState } from '@Models/SaveState';
import ChangeQuestionTextForm from '../forms/ChangeQuestionTextForm';
import { UpdateSurveyTemplateWriteModel } from '@ApiModels';

const baseClass = 'freetext-preview';
const childClass = childClassClosure(baseClass);


type Props = ClassableProps & {
  fieldName: keyof UpdateSurveyTemplateWriteModel;
};


const FreetextQuestionPreview: React.FunctionComponent<Props> = props => {
  const { template, saveState, dispatch } = useContext(SurveyManagerContext);
  const { currentUser } = useContext(LoggedInContext);
  const [editing, setEditing] = useState(false);

  return <Paper className={baseClassAnd(baseClass, props)}>
    <div className={childClass('text')}>{template[props.fieldName]}<span className={childClass('edit-text')} onClick={() => setEditing(true)}>Edit</span></div>
    <div className={childClass('textarea')}>
      <TextField
        placeholder="I really enjoyed the latest company initiative, keep it up!"
        fullWidth
        disabled
        variant="outlined"
        multiline
        rows={4}
        rowsMax={4}
      />
    </div>
    <Drawer anchor="right" open={editing} onClose={() => setEditing(false)} PaperProps={{ className: childClass('draw-paper') }}>
      <ChangeQuestionTextForm
        onCancel={() => setEditing(false)}
        onConfirmed={() => setEditing(false)}
        fieldName={props.fieldName}
        template={template} />
    </Drawer>
  </Paper>
}

export default FreetextQuestionPreview;
