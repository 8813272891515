import * as React from 'react';
import './LatestSurveyResults.scss';
import { ClassableProps, childClassClosure, baseClassAnd } from "@jcharlesworthuk/your-mum-core/dist/functions";
import { Paper } from '@material-ui/core';
import { HistoricalDataAnalysisContext, OrganisationManagerContext, LatestSurveyResultContext } from '@Contexts';
import { useContext, useState } from 'react';
import DivergingStackedBarChart from '../graphs/DivergingStackedBarChart';
import LinkButton from '../buttons/LinkButton';
import LikertBarChart from '../graphs/LikertBarChart';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import IconAndText from "@jcharlesworthuk/your-mum-frontend/components/texty/IconAndText";
import { lastEventId } from '@sentry/browser';
import Spinner from '@jcharlesworthuk/your-mum-frontend/components/indicators/Spinner';
import { TeamScoresResult } from '@Models/TeamScoresResult';
import { createBlankLikertMetricResultDto } from '@Models/DiscreteLikertResult';
import { ScaleLabelSet } from '@Models/ScaleLabelSet';
import PersonIcon from '@material-ui/icons/Person';

const baseClass = 'latest-survey-results';
const childClass = childClassClosure(baseClass);

type Props = ClassableProps & {
    paperClass?: string;
    titleClass?: string;
    subtitleClass?: string;
    includeCtaLink?: string;
};

const LatestSurveyResults: React.FunctionComponent<Props> = props => {
    const { historicalData } = useContext(HistoricalDataAnalysisContext);
    const { organisation } = useContext(OrganisationManagerContext);
    const { latestResult } = useContext(LatestSurveyResultContext);

    return <div className={baseClassAnd(baseClass, props)}>
        <Paper className={props.paperClass} elevation={1}>
            <h3 className={props.titleClass}>Your Latest Survey Result</h3>
            {latestResult
                ? <>
                    <p className={[props.subtitleClass, childClass('subtitle')].join(' ')}>{historicalData.latestSurvey.date.toLocaleDateString(undefined, { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })} | <strong>{historicalData.latestSurvey.participationCount}</strong> responses</p>

                    <div className={[childClass('question-results'), childClass('section-block', 'first')].join(' ')}>
                        <h4 className={childClass('question-results-title')}>Happiness</h4>
                        <h4 className={childClass('question-results-subtitle')}>“{latestResult.survey.questions[0].text}”</h4>
                    </div>
                    <div className={childClass('chart-container')}>
                        <ul className={childClass('chart-container-lhs')}>
                            <li className={childClass('lhs-item', 'accent')}>All Employees</li>
                            {Object.keys(latestResult.result.teamScores).map(teamId => <li key={`team-label-${teamId}`} className={childClass('lhs-item')}>{organisation.teams[teamId] ? organisation.teams[teamId].name : '(team deleted)'}</li>)}
                        </ul>
                        <LikertBarChart className={childClass('chart-container-chart')} results={latestResult.result.teamScoresArrayWithCombined} scale={ScaleLabelSet.happiness()} />
                    </div>

                    {latestResult.survey.questions.filter(x => x.metricId).map((question, i) => {
                        console.log('question', question);
                        const thisQuestionResult = latestResult.result.metrics[question.metricId];
                        if (!thisQuestionResult) return <div key={question.templateId}></div>
                        return <div key={question.templateId} className={childClass('section-block')}>
                            <div className={childClass('question-results')}>
                                <h4 className={childClass('question-results-title')}>{thisQuestionResult.metric.name}</h4>
                                <h4 className={childClass('question-results-subtitle')}>“{question.text}”</h4>
                            </div>
                            <div className={childClass('chart-container')}>
                                <ul className={childClass('chart-container-lhs')}>
                                    <li className={childClass('lhs-item')}>All Employees</li>
                                </ul>
                                <LikertBarChart className={childClass('chart-container-chart')} results={[thisQuestionResult]} scale={ScaleLabelSet.forScale(question.scale, question.isInverse)} />
                            </div>
                        </div>
                    })}


                    <div className={childClass('section-block')}>
                        <h4 className={childClass('question-results-title')}>Comments</h4>
                        <h4 className={childClass('question-results-subtitle')}>“{latestResult.survey.questions[latestResult.survey.questions.length - 1].text}”</h4>
                    </div>
                    <ul className={childClass('freetext-list')}>
                        {latestResult.result.allComments.length > 0
                            ? latestResult.result.allComments.map((comment, i) => <li key={`comment-${i}`}>
                                <IconAndText className={childClass('summary-detail')} icon={PersonIcon}>{comment}</IconAndText></li>)
                            : <li className={childClass('no-results-text')}>No results</li>}
                    </ul>

                </>
                : <Spinner />}
        </Paper>
        {props.includeCtaLink && <div className={childClass('buttons-container')}>
            <LinkButton className={childClass('cta')}
                style="grey"
                to={props.includeCtaLink} label="View Analysis" />
        </div>
        }
    </div>
}

export default LatestSurveyResults;