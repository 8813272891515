import { Mdx, SiteSiteMetadataSocial, SiteSiteMetadata } from "@GraphQLModels";
import { Article, ISocialPage, FacebookPage, TwitterPage, WebsiteInformation } from "@jcharlesworthuk/your-mum-frontend/models";

export function mapMdxToArticle(mdx: Mdx) {
    return new Article(mdx.fields.route,
            mdx.frontmatter.title,
            mdx.frontmatter.standfirst,
            mdx.frontmatter.author,
            new Date(mdx.frontmatter.date),
            mdx.frontmatter.mainImage,
            mdx.timeToRead);
}

export function mapToWebsiteInformation(siteMeta: SiteSiteMetadata) : WebsiteInformation {
    return new WebsiteInformation(
        siteMeta.title,
        siteMeta.siteUrl,
        siteMeta.siteLogoUrl,
        siteMeta.siteLogoUrlSquare,
        Array.from(mapMetadataToSocialSites(siteMeta.social))
    );
}

export function* mapMetadataToSocialSites(metaData: SiteSiteMetadataSocial) : Generator<ISocialPage> {
    if (metaData.twitter) {
        yield new TwitterPage(metaData.twitter.handle);
    }
    if (metaData.facebook) {
        yield new FacebookPage(metaData.facebook.handle, '');
    }
}