import * as React from 'react';
import './PasswordResetSetNewform.scss';
import { useState } from 'react';
import { ButtonState } from "@jcharlesworthuk/your-mum-frontend/models";
import NiceTextField from "@jcharlesworthuk/your-mum-frontend/components/forms/NiceTextField";
import TermsAndConditionsField from '@jcharlesworthuk/your-mum-frontend/components/forms/TermsAndConditionsField';
import ActionButton from "@jcharlesworthuk/your-mum-frontend/components/buttons/ActionButton";
import { childClassClosure, ClassableProps, isNullOrEmpty, baseClassAnd } from '@jcharlesworthuk/your-mum-core/dist/functions';
import { Link } from '@reach/router';
import { createInputChangeHandler, InputChangeDelegate } from '@jcharlesworthuk/your-mum-frontend/helpers/InputDelegates';
import * as Authentication from '@Services/Authentication';
import * as Sentry from '@sentry/browser';
import { LoginCredentials } from '@Models/LoginCredentials';
import { CurrentUser } from '@Models/CurrentUser';

const baseClass = 'password-reset-set-new-form';
const childClass = childClassClosure(baseClass);

type Props = ClassableProps & {
    email: string;
    onComplete: (user: CurrentUser) => void;
};

function validateSetNewPasswordRequest(request: SetNewPasswordRequest) {
    return !isNullOrEmpty(request.password) &&
        !isNullOrEmpty(request.code)
}


export type SetNewPasswordRequest = {
    password: string;
    code: string;
};

const PasswordResetSetNewform: React.FunctionComponent<Props> = props => {
    const [inputs, setInputs] = useState<SetNewPasswordRequest>({ code: '', password: '' });
    const [error, setError] = useState<string | undefined>(undefined);
    const [buttonState, setButtonState] = useState<ButtonState>(ButtonState.Disabled);

    const registerInputChangeHandler = createInputChangeHandler<SetNewPasswordRequest>(setInputs, newInputs => {
        const isValid = validateSetNewPasswordRequest(newInputs);
        setButtonState(isValid ? ButtonState.Enabled : ButtonState.Disabled);
    });

    const handleError = e => {
        console.error(e);
        Sentry.captureException(e);
        setError(e.message);
    }

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const action = async () => {
            setButtonState(ButtonState.Working);
            try {
                await Authentication.submitNewPassword(props.email, inputs.code, inputs.password);
                const user = await Authentication.signInWithPassword(props.email, inputs.password);
                props.onComplete(user);
            } catch (e) {
                handleError(e);
            }
        };
        action();
    }

    return <form onSubmit={handleSubmit} className={baseClassAnd(baseClass, props)}>
        <h1 className={childClass('title')}>Please check your email</h1>
        <p className={childClass('message')}>We have sent you an email with a code to reset your password.</p>
        
        <div className={childClass('text-wrapper')}>
      <input type={'text'}
        className={childClass('input')}
        name='resetCode'
        value={inputs.code}
        onChange={registerInputChangeHandler('code', inputs)}
        required={true} />
    </div>

        <NiceTextField
            className={childClass('text-field')}
            type='password'
            label="New Password"
            value={inputs.password || ''}
            onChange={registerInputChangeHandler('password', inputs)} />

        <p className={childClass('error-message')}>{error}</p>
        <div className={childClass('button-container')}>
            <ActionButton
                type='submit'
                size='large'
                state={buttonState}
                label='Continue'
            />
        </div>
        <div className={childClass('grow-space')}>&nbsp; </div>

    </form>
}


export default PasswordResetSetNewform;