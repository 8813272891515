import * as React from 'react';
import './SurveyOverviewWindow.scss';
import { childClassClosure } from '@jcharlesworthuk/your-mum-core/dist/functions';
import { useState, useEffect, useContext } from 'react';
import { SurveyManagerContextProvider, LoggedInContext, QuestionsAndMetricsContext, SurveyFrequencySettingsContext, SurveyFrequencySettingsActionType, LatestSurveyResultContext } from "@Contexts";
import Spinner from '@jcharlesworthuk/your-mum-frontend/components/indicators/Spinner';
import MetricAccordions from '../editors/MetricAccordions';
import { PrivateApiClient } from '../../ApiClient';
import { SurveyTemplate } from '@Models/SurveyTemplate';
import { SaveState } from '@Models/SaveState';
import { FormControl, InputLabel, Select, MenuItem, withStyles, Paper, Button, Dialog } from '@material-ui/core';
import { SurveyFrequency, SurveyDay } from '@Models/SurveyFrequency';
import MarqueeQuestionPreview from '../editors/MarqueeQuestionPreview';
import FreetextQuestionPreview from '../editors/FreetextQuestionPreview';
import SendTestSurveyButton from '../buttons/SendTestSurveyButton';
import { useLocalStorageSetting } from "@Hooks";

const baseClass = 'survey-overview-window';
const childClass = childClassClosure(baseClass);


const frequencies: { forApi: string, label: string }[] = [
    {
        forApi: 'weekly',
        label: 'week',
    }, {
        forApi: 'bi-weekly',
        label: '2 weeks',
    }, {
        forApi: 'monthly',
        label: 'month',
    }
];

const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']


const BiggerSelect = withStyles((theme) => ({
    select: {
        fontSize: 22,
    },
}))(Select);


type Props = {};

const SurveyOverviewWindow: React.FunctionComponent<Props> = () => {
    const { currentUser } = useContext(LoggedInContext);
    const { frequencySettings, dispatch: frequencySettingsDispatch } = useContext(SurveyFrequencySettingsContext);
    const { latestResult } = useContext(LatestSurveyResultContext);
    const [_, setSurveyPageVisitedCookie] = useLocalStorageSetting("survey-visited", "0");


    useEffect(() => {
        setSurveyPageVisitedCookie("1");
    }, []);




    const changeSurveyOption = (opts: { surveyFrequency?: SurveyFrequency, surveyDay?: SurveyDay, openLength?: number }) => {
        async function save() {
            const writeModel = opts.openLength
                ? { openLength: opts.openLength }
                : {
                    surveyDay: opts.surveyDay || frequencySettings.day,
                    surveyFrequency: opts.surveyFrequency || frequencySettings.frequency
                };
            await new PrivateApiClient().withUser(currentUser).updateSurveyTemplate(writeModel);
            frequencySettingsDispatch({
                ...opts,
                latestSurveyDate: latestResult ? new Date(latestResult.result.date) : null,
                type: SurveyFrequencySettingsActionType.SurveyOptionChanged
            });
        }
        save();
    }


    return <section className={baseClass}>

        <h1 className={childClass('title')}>Design Your Survey</h1>
        <div className={childClass('section-title')}>Every
                        <BiggerSelect
                id="survey-freq-select"
                value={frequencySettings.frequency}
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => changeSurveyOption({ surveyFrequency: e.target.value as SurveyFrequency })}
            >
                {frequencies.map(freq => <MenuItem value={freq.forApi.toLowerCase()} key={freq.label}>{freq.label}</MenuItem>)}
            </BiggerSelect> on a <BiggerSelect
                id="survey-day-select"
                value={frequencySettings.day}
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => changeSurveyOption({ surveyDay: e.target.value as SurveyDay })}
            >
                {days.map(day => <MenuItem value={day} key={day}>{day}</MenuItem>)}
            </BiggerSelect> your employees are asked</div>

        <MarqueeQuestionPreview />

        <p className={childClass('section-title')}>Then asked <strong>2</strong> questions from your selected metrics</p>

        <MetricAccordions />

        <p className={childClass('section-title')}>Finally, they can provide optional comments</p>

        <FreetextQuestionPreview fieldName="additionalCommentsQuestion" />

        <div className={childClass('buttons-row')}>
            <SendTestSurveyButton />
        </div>

    </section>
}

export default SurveyOverviewWindow;