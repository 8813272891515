import * as React from 'react';
import './AnalysisWindow.scss';
import { childClassClosure } from '@jcharlesworthuk/your-mum-core/dist/functions';
import ChartistGraph from 'react-chartist';
import { Paper } from '@material-ui/core';
import { IBarChartOptions } from 'chartist';
import { LikertMetricResult } from '@Models/LikertMetricResult';
import { HistoricalSurveyResultsSet } from '@Models/HistoricalSurveyResultsSet';
import { SurveyResults } from '@Models/SurveyResults';
import { Metric } from '@Models/Metric';
import { useState, useEffect, useContext } from 'react';
import { HistoricalDataAnalysisContextProvider, OrganisationManagerContextProvider, LoggedInContext, SurveyFrequencySettingsContextProvider, QuestionsAndMetricsContext, OrganisationManagerContext } from '@Contexts';
import Spinner from '@jcharlesworthuk/your-mum-frontend/components/indicators/Spinner';
import LatestSurveyResults from '../resultsviews/LatestSurveyResults';
import LatestSurveyParticipation from '../resultsviews/LatestSurveyParticipation';
import SurveyCalendarOverview from '../resultsviews/SurveyCalendarOverview';
import { PrivateApiClient, staticDataApi } from '../../ApiClient';
import { SurveyFrequencySettings } from '@Models/SurveyFrequencySettings';
import { LatestSurveyResult } from '@Models/LatestSurveyResult';
import { MetricScoreTimeGraph, AllTeamsScoreTimeGraph, TeamScoreTimeGraph } from '../resultsviews/ScoreTimeGraphs';

const baseClass = 'analysis-window';
const childClass = childClassClosure(baseClass);

type Props = {};

const AnalysisWindow: React.FunctionComponent<Props> = () => {
  const { currentUser } = useContext(LoggedInContext);
  const { metrics } = useContext(QuestionsAndMetricsContext);
  const { organisation } = useContext(OrganisationManagerContext);
  const [initialState, setInitialState] = useState<{
    historicalData: HistoricalSurveyResultsSet
  } | null>(null);

  useEffect(() => {
    async function load() {
      const apiClient = new PrivateApiClient().withUser(currentUser);
      const results = await apiClient.getHistoricalSurveyResults(metrics);
      const historicalData = new HistoricalSurveyResultsSet(results || [])
      setInitialState({
        historicalData
      })

    }
    if (!initialState) {
      load();
    }
  }, []);

  return <section className={baseClass}>
    <h1 className={childClass('title')}>Survey Results</h1>
    {initialState ?
      initialState.historicalData.surveys.length > 0
        ? <HistoricalDataAnalysisContextProvider initialState={{ historicalData: initialState.historicalData }}>
          <section className={childClass('latest-survey')}>
            <div className={childClass('latest-survey-lhs')}>
              <LatestSurveyResults
                className={childClass('panel')}
                titleClass={childClass('panel-title')}
                subtitleClass={childClass('panel-subtitle')}
                paperClass={[childClass('panel-paper'), childClass('latest-survey-box')].join(' ')} />

            </div><div className={childClass('latest-survey-rhs')}>
              <AllTeamsScoreTimeGraph
                subtitle='Summary of all your feedback'
                className={childClass('panel')}
                titleClass={childClass('panel-title')}
                subtitleClass={childClass('panel-subtitle')}
                paperClass={[childClass('panel-paper'), childClass('overall-score-box')].join(' ')}
                height={undefined}
              />

              <LatestSurveyParticipation
                className={childClass('panel')}
                titleClass={childClass('panel-title')}
                subtitleClass={childClass('panel-subtitle')}
                paperClass={[childClass('panel-paper'), childClass('participation-box')].join(' ')} />
            </div>
          </section>
          {/* 
          <SurveyCalendarOverview
            className={childClass('panel')}
            titleClass={childClass('panel-title')}
            paperClass={childClass('panel-paper')} /> */}
          <h1 className={childClass('title', 'big-gap')}>Your Metrics Over Time</h1>
          <section className={childClass('metric-charts')}>
            {Object.keys(metrics).map(x => metrics[x]).map(metric => <MetricScoreTimeGraph
              subtitle={metric.description}
              key={metric.name}
              metric={metric}
              className={childClass('panel')}
              titleClass={childClass('panel-title')}
              paperClass={childClass('panel-paper')} />)}
          </section>

          <h1 className={childClass('title', 'big-gap')}>Individual Team Feedback</h1>
          <section className={childClass('metric-charts')}>
            { organisation.teamArray.map(team => <TeamScoreTimeGraph
              subtitle={team.name}
              key={team.id}
              team={team}
              className={childClass('panel')}
              titleClass={childClass('panel-title')}
              paperClass={childClass('panel-paper')} />)}
          </section>

        </HistoricalDataAnalysisContextProvider>
        : <h2 className={childClass('title')}>This is where your survey results will appear</h2>
      : <Spinner />}



  </section>
}

export default AnalysisWindow;

