import * as React from "react";
import { StaticQuery, graphql, Link } from "gatsby"
import { childClassClosure, ClassableProps, baseClassAnd } from "@jcharlesworthuk/your-mum-core/dist/functions";
import "./MarketingSiteHeader.scss";
import { Site } from "@GraphQLModels";
import * as Authentication from "@Services/Authentication"
import { useEffect, useState } from "react";
import { CurrentUser } from "@Models/CurrentUser";

const baseClass = 'marketing-site-header';
const childClass = childClassClosure(baseClass);

type Props = ClassableProps & {
  onCtaClick?: () => void;
};

interface StaticQueryData {
  site?: Site
}

const MarketingSiteHeader: React.FunctionComponent<Props> = (props: Props) => {
  const [currentUser, setCurrentUser] = useState<CurrentUser | null | 'none'>(null);
  useEffect(() => {
    async function loadUser() {
      const user = await Authentication.loadUserFromStorage();
      setCurrentUser(user || 'none');
    }
    loadUser();
  }, [])

  return <StaticQuery
    query={marketingSiteHeaderQuery}
    render={({ site: { siteMetadata } }: StaticQueryData) => (
      <header className={baseClassAnd(baseClass, props)}>
        <div className={childClass('container')}>
          <div className={childClass('homelink')}>
            <Link to="/">
              <img src={'/img/logo.svg'} alt={siteMetadata.title} />
            </Link>
          </div>
          <nav className={childClass('menu')}>
            <Link to='/pricing' className={childClass('menu-link', 'canhide')}>Pricing</Link>
            <Link to='/blog' className={childClass('menu-link', 'canhide')}>Blog</Link>
            {(!currentUser || currentUser === 'none') && <Link to='/account/login' className={childClass('menu-link', 'canhide')}>Log In</Link>}
          </nav>
          <div className={childClass('cta-container')}>
            {currentUser && currentUser !== 'none'
              ? <Link className={childClass('cta')} to='/office/dashboard'>Go To Dashboard</Link>
              : <Link className={childClass('cta')} to='/account/register'>Start Now</Link>}
          </div>
        </div>
      </header>
    )}
  />
}

export default MarketingSiteHeader;

export const marketingSiteHeaderQuery = graphql`
      query MarketingSiteHeaderQuery {
        site {
            siteMetadata {
                title
                titleTemplate
                siteUrl
                social {
                  twitter {
                      handle,
                      url
                  }
                  facebook {
                      url
                  }
                }
            }
        }
    }
  `