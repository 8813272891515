import { EmployeeDto } from "@Dtos";
import { UpsertEmployeeWriteModel } from "@ApiModels";
import { EmployeeStatus } from "./EmployeeStatus";
import { IHasProfileImageCircle } from "./IHasProfileImageCircle";

export class Employee implements IHasProfileImageCircle {
    constructor(
        public sub: string | null,
        public email: string,
        public fullName: string,
        public status: EmployeeStatus,
        public indicatorHue: number,
        public teamId: string | null) {
    }

    get shorterDisplayName() {
        return this.fullName.length > 10 ? this.fullName.split(' ')[0] : this.fullName;
    }

    get firstName() {
        return this.fullName.substring(0, this.fullName.lastIndexOf(' '));
    }

    get lastName() {
        return this.fullName.substring(this.fullName.lastIndexOf(' '));
    }

    public static fromDto(dto: EmployeeDto) {
        return new Employee(dto.sub || null, dto.email, dto.fullName, dto.status, dto.indicatorHue, dto.teamId || null);
    }

    public static createWriteModel(employee: Employee): UpsertEmployeeWriteModel {
        return {
            userId: employee.sub,
            email: employee.email,
            fullName: employee.fullName,
            status: employee.status,
            teamId: employee.teamId,
            indicatorHue: employee.indicatorHue
        }

    }
}
