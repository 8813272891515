import * as React from 'react';
import './OrganisationSettingsWindow.scss';
import { childClassClosure } from '@jcharlesworthuk/your-mum-core/dist/functions';
import { Paper } from '@material-ui/core';
import OrgSettingsEditorForm from '../forms/OrgSettingsEditorForm';
import OrganisationPhotoEditor from '../editors/OrganisationPhotoEditor';
import BillingSubscriptionEditor from '../editors/BillingSubscriptionEditor';
import UserAccountsTable from '../tables/UserAccountsTable';
import { LoggedInContext } from '@Contexts';
import { useContext } from 'react';

const baseClass = 'org-settings-window';
const childClass = childClassClosure(baseClass);


type Props = {};

const OrganisationSettingsWindow: React.FunctionComponent<Props> = () => {
    const { currentUser } = useContext(LoggedInContext);
    
    return <section className={baseClass}>
        <Paper className={childClass('section', 'columns')}>
            <OrganisationPhotoEditor
                className={childClass('image-box')} />

            <div className={childClass('details-pane')}>
                <OrgSettingsEditorForm />

                { currentUser.hasPermission('billing') && <BillingSubscriptionEditor className={childClass('billing')} /> }
            </div>
        </Paper>


        <section className={childClass('section', 'noppad')}>
            <h2>User Accounts</h2>
            <UserAccountsTable />
        </section>
    </section>
}

export default OrganisationSettingsWindow;
