import { IHasProfileImageCircle } from "./IHasProfileImageCircle";
import { EmployeeStatus } from "./EmployeeStatus";
import { Employee } from "./Employee";
import { AcordePermissions } from "./AcordePermissions";

export class CurrentUser implements IHasProfileImageCircle {
    constructor(
            public sub: string,
            public email: string,
            public fullName: string,
            public orgId: string,
            public identityId: string,
            public indicatorHue: number,
            public newsletter: number,
            public permissions: AcordePermissions[],
            public teamId: string | null) {
    }
    
    get shorterDisplayName() {
        return this.fullName.length > 10 ? this.fullName.split(' ')[0] : this.fullName;
    }

    get avatarLetter() {
        return this.fullName[0].toUpperCase();
    }

    withOrgId(orgId: string) {
        return new CurrentUser(this.sub, this.email, this.fullName, orgId, this.identityId, this.indicatorHue, this.newsletter, this.permissions, this.teamId);
    }

    withNewName(name: string) {
        return new CurrentUser(this.sub, this.email, name, this.orgId, this.identityId, this.indicatorHue, this.newsletter, this.permissions, this.teamId);
    }

    withNewNewsletter(value: number) {
        return new CurrentUser(this.sub, this.email, this.fullName, this.orgId, this.identityId, this.indicatorHue, value, this.permissions, this.teamId);
    }

    hasPermission(permission: AcordePermissions) {
        return this.permissions.includes(permission);
    }

    get asEmployee() {
        return new Employee(this.sub, this.email, this.fullName, EmployeeStatus.Active, this.indicatorHue, this.teamId);
    }
}
