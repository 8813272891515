import { Metric } from "./Metric";
import { QuestionTemplate } from "./QuestionTemplate";
import { SurveyTemplateDto } from "@Dtos";
import { MetricConfiguration } from "./MetricConfiguration";
import { UpdateSurveyTemplateWriteModel } from "@ApiModels";
import { SurveyFrequency, SurveyDay } from "./SurveyFrequency";

export class SurveyFrequencySettings {
    constructor(public templateId: string, 
        public frequency: SurveyFrequency,
        public day: SurveyDay | null,
        public nextDate: Date,
        public openLength: number,
        public currentlyOpenUntil: Date | null) {
    }

    static fromDto(dto: SurveyTemplateDto) {
        return new SurveyFrequencySettings(dto.id, 
            dto.frequency,
            dto.day, 
            dto.nextDate ? new Date(dto.nextDate) : null,
            dto.openLength,
            dto.currentlyOpenUntil ? new Date(dto.currentlyOpenUntil) : null);
    }

}
