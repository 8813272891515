import * as React from 'react';
import './SetTeamManagerForm.scss';
import { childClassClosure, ClassableProps, baseClassAnd } from '@jcharlesworthuk/your-mum-core/dist/functions';
import { Employee } from '@Models/Employee';
import { TextField, InputAdornment, ButtonGroup, Button, FormControlLabel, Checkbox } from '@material-ui/core';
import { useUpsertEmployee, useUpsertTeam } from '@Hooks';
import Autocomplete from '@material-ui/lab/Autocomplete';
import AlternateEmail from '@material-ui/icons/AlternateEmail';
import { ButtonState } from '@jcharlesworthuk/your-mum-frontend/models';
import { useContext, useState } from 'react';
import { OrganisationManagerContext } from '@Contexts';
import Alert from '@material-ui/lab/Alert';
import { Team } from '@Models/Team';

const baseClass = 'set-team-manager';
const childClass = childClassClosure(baseClass);


type Props = ClassableProps & {
  onCancel: () => void;
  onConfirmed: () => void;
  oldManager: Employee | null;
  team: Team;
  possibleManagers: Employee[];
};

const SetTeamManagerForm: React.FunctionComponent<Props> = (props) => {
  const employeeForm = useUpsertEmployee(props.oldManager, null);
  const teamForm = useUpsertTeam(props.team);
  const [selectedManager, setSelectedManager] = useState<Employee | null>(null);
  const inputVariant = 'outlined';
  const [ newUserChecked, setNewUserChecked] = useState(true);
  const managerSelectionValid = (newUserChecked && employeeForm.buttonState === ButtonState.Enabled) || selectedManager !== null;

  const handleNewUserCheckChange = (event) => {
    setNewUserChecked(event.target.checked);
  };

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (newUserChecked) {
      employeeForm.handleSubmitForm(employee => {
        teamForm.handleSubmitForm(props.onConfirmed, employee)(e);
      }, true)(e);
    } else {
      if (!selectedManager.sub) {
        employeeForm.convertEmployeeToUser(selectedManager).then(updatedManager => {
          teamForm.handleSubmitForm(props.onConfirmed, updatedManager)(e);
        })
      } else {
        teamForm.handleSubmitForm(props.onConfirmed, selectedManager)(e);
      }
    }
  }

  return <form className={baseClassAnd(baseClass, props)} noValidate autoComplete="off" onSubmit={onSubmit}>
    <h2 className={childClass('title')}>{props.oldManager ? `Change manager for ${props.team.name}` : `Add manager for ${props.team.name}`}</h2>
    { props.oldManager && <TextField id="oldManager"
      className={childClass('old-manager')}
      label="Current Manager"
      margin="normal"
      fullWidth
      variant={inputVariant}
      value={props.oldManager.fullName}
      disabled />
}

    <FormControlLabel className={childClass('section')}  control={<Checkbox checked={newUserChecked} onChange={handleNewUserCheckChange} name="newManagerUser" color="primary" />} label="Create new user to manage this team" />
    { newUserChecked ?
    <>
    <Alert  severity="info">Your team manager will be given a login so they can view feedback results</Alert>
    <TextField id="fullName"
      className={childClass('full-name')}
      label="Manager Name"
      margin="normal"
      fullWidth
      variant={inputVariant}
      value={employeeForm.fields.fullName || ''}
      onChange={employeeForm.registerInputChangeHandler('fullName')} />
    <TextField id="email"
      className={childClass('email')}
      label="Manager Email"
      fullWidth
      margin="normal"
      variant={inputVariant}
      value={employeeForm.fields.email || ''}
      onChange={employeeForm.registerInputChangeHandler('email')}
    />
    </>
    : <Autocomplete
    id="manager-select"
    options={props.possibleManagers}
    value={selectedManager}
    onChange={(event, newValue) => {
      if (typeof newValue !== 'string') {
        setSelectedManager(newValue as Employee);
      }
    }}
    autoHighlight
    getOptionLabel={option => `${option.fullName} - ${option.email}`}
    renderInput={params => <TextField {...params} label="Select Team Manager" margin="normal" variant="outlined" fullWidth inputProps={{
      ...params.inputProps,
      autoComplete: 'new-password', // disable autocomplete and autofill
    }} />}
  />}

    {employeeForm.error && <p className={childClass('error-message')}>{employeeForm.error}</p>}
    <div className={childClass('buttons')}>
      <Button size="large" onClick={() => props.onCancel()}>Cancel</Button>
      <Button variant="contained" size="large" color="primary" type="submit" disabled={!managerSelectionValid}>{props.oldManager ? 'Update' : 'Add'}</Button>
    </div>
  </form>
}

export default SetTeamManagerForm;