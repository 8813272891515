import * as React from 'react';
import './UserAccountsTable.scss';
import { Link, navigate } from 'gatsby';
import { childClassClosure, baseClassAnd, ClassableProps } from '@jcharlesworthuk/your-mum-core/dist/functions';
import { Employee } from '@Models/Employee';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { useContext, useState } from 'react';
import { EmployeeManagerContext, EmployeeManagerActionType, LoggedInContext, OrganisationManagerContext } from '@Contexts';
import PersonIcon from '@material-ui/icons/Person';
//import { Link } from 'gatsby-plugin-material-ui';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { Button, Menu, MenuItem, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Drawer } from '@material-ui/core';
import { PrivateApiClient } from '../../ApiClient';
import { EmployeeStatus } from '@Models/EmployeeStatus';
import InitialCircle from '../indicators/InitialCircle';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import UpsertEmployeeForm from '../forms/UpsertEmployeeForm';
import { useDeleteEmployee } from '@Hooks';


const baseClass = 'user-accounts-table';
const childClass = childClassClosure(baseClass);


type Props = ClassableProps & {
};

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
});

type DialogFlags = {
    editUser: null | Employee,
};

type ContextMenuFlags = {
    userRowDots: null | { anchorEl: HTMLElement, user: Employee },
};


const emptyDialogFlags: DialogFlags = {
    editUser: null,
};

const emptyContextMenuFlags: ContextMenuFlags = {
    userRowDots: null
}


const UserAccountsTable: React.FunctionComponent<Props> = props => {
    const { employees, dispatch: dispatchEmployee } = useContext(EmployeeManagerContext);
    const { organisation, dispatch: dispatchOrganisation } = useContext(OrganisationManagerContext);
    const { currentUser } = useContext(LoggedInContext);
    const classes = useStyles();
    const deleteEmployee = useDeleteEmployee();
    const [requestingDeleteUser, setRequestingDeleteUser] = useState<null | Employee>(null);
    const [requestingDeleteUserNameLatch, setRequestingDeleteUserNameLatch] = useState<string>('');
    const [contextMenus, setContextMenus] = useState<ContextMenuFlags>(emptyContextMenuFlags);
    const [dialogs, setDialogs] = useState<DialogFlags>(emptyDialogFlags)

    const openUserContextMenu = (user: Employee) => {
        return (event: React.MouseEvent<HTMLButtonElement>) => {
            setContextMenus({
                ...emptyContextMenuFlags,
                userRowDots: {
                    user,
                    anchorEl: event.currentTarget
                }
            });
        };
    }

    const closeAllContextMenus = () => {
        setContextMenus(emptyContextMenuFlags);
    }


    const closeAllDialogs = () => {
        setDialogs(emptyDialogFlags);
    }


    const handleEditUserMenuClick = () => {
        if (!contextMenus.userRowDots || !contextMenus.userRowDots.user) return;
        if (contextMenus.userRowDots.user.email === currentUser.email) {
            navigate("/office/profile");
        } else {
            setDialogs({
                ...emptyDialogFlags,
                editUser: contextMenus.userRowDots.user
            })
        }
        closeAllContextMenus();
    }


    const handleDeleteUserMenuClick = () => {
        if (!contextMenus.userRowDots || !contextMenus.userRowDots.user) return;
        setRequestingDeleteUserNameLatch(contextMenus.userRowDots.user.fullName);
        setRequestingDeleteUser(contextMenus.userRowDots.user);
        closeAllContextMenus();
    }




    const handleDeleteUserConfirmClick = () => {
        if (!requestingDeleteUser) return;
        deleteEmployee.performDelete(requestingDeleteUser);
        setRequestingDeleteUser(null);
    }


    const employeesWithAccount = employees.filter(x => x.sub);

    return <TableContainer component={Paper} className={props.className}>
        <Table className={classes.table + ' ' + baseClass} aria-label="simple table">
            <TableHead>
                <TableRow>
                    <TableCell>&nbsp;</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell>Managing Teams</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell align="right">&nbsp;</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {employeesWithAccount.map(user => <TableRow key={user.sub}>
                    <TableCell component="th" scope="row">
                        <InitialCircle user={user} />
                    </TableCell>
                    <TableCell className='pii'>{user.fullName}</TableCell>
                    <TableCell className='pii'>{user.email}</TableCell>
                    <TableCell className='pii'>{organisation.teamsManagedBy(user.sub).map(teamId => organisation.teams[teamId].name).join(', ')}</TableCell>
                    <TableCell className='pii'>{EmployeeStatus[user.status]}</TableCell>
                    <TableCell align="right">
                        <Button aria-controls="simple-menu" aria-haspopup="true" onClick={openUserContextMenu(user)}>
                            <MoreHorizIcon />
                        </Button>
                    </TableCell>
                </TableRow>
                )}
            </TableBody>
        </Table>
        <Menu
            id="user-edit-menu"
            anchorEl={contextMenus && contextMenus.userRowDots ? contextMenus.userRowDots.anchorEl : null}
            keepMounted
            open={Boolean(contextMenus.userRowDots)}
            onClose={closeAllContextMenus}
            classes={{
                list: childClass('context-menu')
            }}
        >
            <MenuItem onClick={handleEditUserMenuClick}><EditIcon /> Edit</MenuItem>
            {(contextMenus && contextMenus.userRowDots && contextMenus.userRowDots.user && contextMenus.userRowDots.user.email !== currentUser.email) && <MenuItem onClick={handleDeleteUserMenuClick}><DeleteIcon /> Delete User</MenuItem>}
        </Menu>
        <Dialog
            open={Boolean(requestingDeleteUser)}
            onClose={closeAllContextMenus}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{"Delete User"}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Are you sure you want to delete the user '{requestingDeleteUserNameLatch}'?
          </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setRequestingDeleteUser(null)} autoFocus>
                    Cancel
          </Button>
                <Button onClick={handleDeleteUserConfirmClick} color="secondary">
                    Delete
          </Button>
            </DialogActions>
        </Dialog>
        <Drawer anchor="right" open={Boolean(dialogs.editUser)} onClose={closeAllDialogs} PaperProps={{ className: childClass('draw-paper') }}>
            <UpsertEmployeeForm
                onCancel={closeAllDialogs}
                onConfirmed={closeAllDialogs}
                employee={dialogs.editUser}
                teamId={null} />
        </Drawer>
    </TableContainer>
}

export default UserAccountsTable;
