import * as React from 'react';
import './CognitoSignupForm.scss';
import { useState } from 'react';
import { ButtonState } from "@jcharlesworthuk/your-mum-frontend/models";
import NiceTextField from "@jcharlesworthuk/your-mum-frontend/components/forms/NiceTextField";
import TermsAndConditionsField from '@jcharlesworthuk/your-mum-frontend/components/forms/TermsAndConditionsField';
import ActionButton from "@jcharlesworthuk/your-mum-frontend/components/buttons/ActionButton";
import { childClassClosure, ClassableProps, isNullOrEmpty, baseClassAnd } from '@jcharlesworthuk/your-mum-core/dist/functions';
import { Link } from 'gatsby';
import { createInputChangeHandler } from '@jcharlesworthuk/your-mum-frontend/helpers/InputDelegates';
import * as Authentication from '@Services/Authentication';
import * as Sentry from '@sentry/browser';
import { LoginCredentials } from '@Models/LoginCredentials';
import { FrontDoorSteps } from '@Models/FrontDoorSteps';

const baseClass = 'signup-form';
const childClass = childClassClosure(baseClass);

type Props = ClassableProps & {
    title: string;
    onComplete: (request: CognitoSignupRequest) => void;
    redirect: (step: FrontDoorSteps) => void;
};

function validateCognitoSignupRequest(request: CognitoSignupRequest, termsAccepted: boolean) {
    return termsAccepted &&
        !isNullOrEmpty(request.email) &&
        !isNullOrEmpty(request.password) &&
        !isNullOrEmpty(request.displayName)
}

export type CognitoSignupRequest = LoginCredentials & {
    displayName: string;
};


const CognitoSignupForm: React.FunctionComponent<Props> = props => {
    const [registerInputs, setInputs] = useState<CognitoSignupRequest>({ email: '', displayName: '', password: '' });
    const [termsAccepted, setTermsAccepted] = useState(false);
    const [error, setError] = useState<string | undefined>(undefined);
    const [buttonState, setButtonState] = useState<ButtonState>(ButtonState.Disabled);

    const registerInputChangeHandler = createInputChangeHandler<CognitoSignupRequest>(setInputs, newInputs => {
        const isValid = validateCognitoSignupRequest(newInputs, termsAccepted);
        setButtonState(isValid ? ButtonState.Enabled : ButtonState.Disabled);
    });
    const termsClick = (value: boolean) => {
        setTermsAccepted(value)
        const isValid = validateCognitoSignupRequest(registerInputs, value);
        setButtonState(isValid ? ButtonState.Enabled : ButtonState.Disabled);
    }

    const handleError = e => {
        console.error(e);
        Sentry.captureException(e);
        if (e.name == 'UsernameExistsException') {
            setError('Account already exists, try to log in instead');
        } else {
            setError(e.message);
        }
    }

    const handleSubmitCognitoSignupRequest = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const action = async () => {
            setButtonState(ButtonState.Working);
            try {
                await Authentication.registerWithCognito(registerInputs);
                props.onComplete(registerInputs);
            } catch (e) {
                handleError(e);
            }
        };
        action();
    }

    return <form onSubmit={handleSubmitCognitoSignupRequest} className={baseClassAnd(baseClass, props)}>
        <h2 className={childClass('title')}>{props.title}</h2>
        <NiceTextField
            className={childClass('text-field')}
            type='text'
            label="Your Name"
            value={registerInputs.displayName || ''}
            onChange={registerInputChangeHandler('displayName', registerInputs)} />
        <NiceTextField
            className={childClass('text-field')}
            type='email'
            label="Email Address"
            value={registerInputs.email || ''}
            onChange={registerInputChangeHandler('email', registerInputs)} />
        <NiceTextField
            className={childClass('text-field')}
            type='password'
            label="Choose A Password"
            value={registerInputs.password || ''}
            onChange={registerInputChangeHandler('password', registerInputs)} />
        <TermsAndConditionsField
            className={childClass('terms-field')}
            linkComponent={Link}
            name='termsAccepted'
            onChange={(_, v) => termsClick(v)}
            value={termsAccepted} />

        {error && <p className={childClass('error-message')}>{error}</p>}
        <div className={childClass('button-container')}>
            <ActionButton
                type='submit'
                size='large'
                state={buttonState}
                label='Continue'
            />
        </div>
        <div className={childClass('grow-space')}>&nbsp; </div>
        
        <div className={childClass('navigate-elsewhere')}>
        <span className={childClass('navigate-elsewhere-text')}>Already have an account? <Link to={'/account/login'} onClick={() => props.redirect(FrontDoorSteps.LogIn)}>Log in</Link></span>
        </div>


    </form>
}


export default CognitoSignupForm;