import * as React from 'react';
import './PasswordResetRequestForm.scss';
import { useState } from 'react';
import { ButtonState } from "@jcharlesworthuk/your-mum-frontend/models";
import NiceTextField from "@jcharlesworthuk/your-mum-frontend/components/forms/NiceTextField";
import TermsAndConditionsField from '@jcharlesworthuk/your-mum-frontend/components/forms/TermsAndConditionsField';
import ActionButton from "@jcharlesworthuk/your-mum-frontend/components/buttons/ActionButton";
import { childClassClosure, ClassableProps, isNullOrEmpty, baseClassAnd } from '@jcharlesworthuk/your-mum-core/dist/functions';
import { Link } from '@reach/router';
import { createInputChangeHandler, InputChangeDelegate } from '@jcharlesworthuk/your-mum-frontend/helpers/InputDelegates';
import * as Authentication from '@Services/Authentication';
import * as Sentry from '@sentry/browser';
import { LoginCredentials } from '@Models/LoginCredentials';
import { CurrentUser } from '@Models/CurrentUser';
import { FrontDoorSteps } from '@Models/FrontDoorSteps';

const baseClass = 'password-reset-request-form';
const childClass = childClassClosure(baseClass);

type Props = ClassableProps & {
    onComplete: (email: string) => void;
    redirect: (step: FrontDoorSteps) => void;
};

const PasswordResetRequestForm: React.FunctionComponent<Props> = props => {
    const [email, setEmail] = useState('');
    const [error, setError] = useState<string | undefined>(undefined);
    const [buttonState, setButtonState] = useState<ButtonState>(ButtonState.Disabled);

    const onChange: InputChangeDelegate = (e) => {
        setEmail(e.currentTarget.value);
        setButtonState(isNullOrEmpty(e.currentTarget.value) ? ButtonState.Disabled : ButtonState.Enabled);
    }

    const handleError = e => {
        console.error(e);
        Sentry.captureException(e);
        setError(e.message);
    }

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const action = async () => {
            setButtonState(ButtonState.Working);
            try {
                await Authentication.requestPasswordResetCode(email);
                props.onComplete(email);
            } catch (e) {
                handleError(e);
            }
        };
        action();
    }

    return <form onSubmit={handleSubmit} className={baseClassAnd(baseClass, props)}>
        <h2 className={childClass('title')}>Password Reset</h2>
        <p className={childClass('message')}>Enter your email to reset your password</p>
        <NiceTextField
            className={childClass('text-field')}
            type='email'
            label="Email Address"
            value={email || ''}
            onChange={onChange} />

        <p className={childClass('error-message')}>{error}</p>
        <div className={childClass('button-container')}>
            <ActionButton
                type='submit'
                size='large'
                state={buttonState}
                label='Continue'
            />
        </div>
        <div className={childClass('grow-space')}>&nbsp; </div>
        
        <div className={childClass('navigate-elsewhere')}>
        <span className={childClass('navigate-elsewhere-text')}><Link to={'/account/login'} onClick={() => props.redirect(FrontDoorSteps.LogIn)}>Back to login</Link></span>
        </div>

    </form>
}


export default PasswordResetRequestForm;