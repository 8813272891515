import * as React from 'react';
import './ScoreTimeGraphs.scss';
import { ClassableProps, childClassClosure, baseClassAnd } from "@jcharlesworthuk/your-mum-core/dist/functions";
import { Paper } from '@material-ui/core';
import ScoreTrendGraph from '../graphs/ScoreTrendGraph';
import { HistoricalDataAnalysisContext } from '@Contexts';
import { useContext } from 'react';
import ScoreCircle from '../indicators/ScoreCircle';
import { Metric } from '@Models/Metric';
import { Team } from '@Models/Team';

const baseClass = 'score-time-graph';
const childClass = childClassClosure(baseClass);

type CommonProps = ClassableProps & {
  paperClass?: string;
  titleClass?: string;
  subtitleClass?: string;
  subtitle: string;
  height?: number;
};


const MetricScoreTimeGraph: React.FunctionComponent<CommonProps & { metric: Metric }> = props => {
  const { historicalData, dispatch } = useContext(HistoricalDataAnalysisContext);

  if (!historicalData.latestSurveyWithMetric(props.metric.id)) return <div></div>

  const surveysWithThisMetric = [...historicalData.surveys.filter(survey => survey.metrics[props.metric.id])];
  const scores: { date: Date, value: number }[] = surveysWithThisMetric
    .map(survey => ({ date: survey.date, value: survey.metrics[props.metric.id].score }));

  // If there is only one survey we need two data points to make a straight line
  if (surveysWithThisMetric.length === 1) {
    scores.push({ date: surveysWithThisMetric[0].date, value: surveysWithThisMetric[0].metrics[props.metric.id].score })
  }

  return <div className={baseClassAnd(baseClass, props)}>
    <Paper className={props.paperClass} elevation={1}>
      <h3 className={props.titleClass}>{props.metric.name}</h3>
      <p className={[props.subtitleClass, childClass('subtitle')].join(' ')}>{props.subtitle}</p>
      <ScoreTrendGraph className={childClass('score-chart')} scores={scores} height={props.height} />
      <div className={childClass('score-indicator')}><ScoreCircle value={historicalData.latestSurveyWithMetric(props.metric.id).score} /></div>
    </Paper>
  </div>
}

const AllTeamsScoreTimeGraph: React.FunctionComponent<CommonProps> = props => {
  const { historicalData, dispatch } = useContext(HistoricalDataAnalysisContext);

  if (!historicalData.latestSurvey) return <div></div>

  const scores: { date: Date, value: number }[] = historicalData.surveys.map(survey => ({ date: survey.date, value: survey.averageScoreAcrossAllTeams }));
  console.log('scores', scores);

  return <div className={baseClassAnd(baseClass, props)}>
    <Paper className={props.paperClass} elevation={1}>
      <h3 className={props.titleClass}>Average Feedback Score</h3>
      <p className={[props.subtitleClass, childClass('subtitle')].join(' ')}>{props.subtitle}</p>
      <ScoreTrendGraph className={childClass('score-chart')} scores={scores} height={props.height} />
      <div className={childClass('score-indicator')}><ScoreCircle value={historicalData.latestSurvey.averageScoreAcrossAllTeams} /></div>
    </Paper>
  </div>
}


const TeamScoreTimeGraph: React.FunctionComponent<CommonProps & { team: Team }> = props => {
  const { historicalData, dispatch } = useContext(HistoricalDataAnalysisContext);

  if (!historicalData.latestSurveyWithTeam(props.team.id)) return <div></div>

  const surveysWithThisTeam = [...historicalData.surveys.filter(x => x.teamScores[props.team.id])];
  const scores: { date: Date, value: number }[] = surveysWithThisTeam
    .map(survey => ({ date: survey.date, value: survey.teamScores[props.team.id].score }));

  // If there is only one survey we need two data points to make a straight line
  if (surveysWithThisTeam.length === 1) {
    scores.push({ date: surveysWithThisTeam[0].date, value: surveysWithThisTeam[0].teamScores[props.team.id].score })
  }

  return <div className={baseClassAnd(baseClass, props)}>
    <Paper className={props.paperClass} elevation={1}>
      <h3 className={props.titleClass}>{props.team.name}</h3>
      <p className={[props.subtitleClass, childClass('subtitle')].join(' ')}>{props.subtitle}</p>
      <ScoreTrendGraph className={childClass('score-chart')} scores={scores} height={props.height} />
      <div className={childClass('score-indicator')}><ScoreCircle value={historicalData.latestSurveyWithTeam(props.team.id).score} /></div>
    </Paper>
  </div>;
}


export {
  TeamScoreTimeGraph,
  AllTeamsScoreTimeGraph,
  MetricScoreTimeGraph
}