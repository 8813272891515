import { Metric } from "./Metric";
import { LikertMetricResultDto, TeamScoresResultDto } from "@Dtos";
import { DiscreteLikertResult } from "./DiscreteLikertResult";
import { LikertMetricResult } from "./LikertMetricResult";
import { LikertResult } from "./LikertResult";

export class TeamScoresResult extends LikertResult {
    constructor(counts: { [score: number]: number }, public comments: string[]) {
        super(counts);
    }
    
    static fromDto(dto: TeamScoresResultDto) {
        return new TeamScoresResult(dto.counts, dto.comments);
    }
}
