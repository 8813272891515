import React, { useEffect, useState } from "react"
import "./_CommonShared.scss";
import "./_FullHeightModifiers.scss";
import "./BackOfficeAppPage.scss";
import { childClassClosure } from "@jcharlesworthuk/your-mum-core/dist/functions";
import { LoggedInContextProvider, HelpIndicatorsContextProvider, OrganisationManagerContextProvider, SurveyFrequencySettingsContextProvider, QuestionsAndMetricsContextProvider, EmployeeManagerContextProvider, LatestSurveyResultContextProvider, SurveyManagerContextProvider } from "@Contexts";
import * as Authentication from "@Services/Authentication"
import { Redirect, RouteComponentProps, Router } from "@reach/router";
import { navigate } from "gatsby";
import BackOfficeHeader from "../components/sitewide/BackOfficeHeader";
import OfficeDashboardMenu from "../components/menus/OfficeDashboardMenu";
import PeopleSetupWindow from "../components/backoffice/PeopleSetupWindow";
import AnalysisWindow from "../components/backoffice/AnalysisWindow";
import DashboardWindow from "../components/backoffice/DashboardWindow";
import { ThemeProvider } from "@material-ui/core/styles";
import { createMuiTheme } from '@material-ui/core/styles';
import themeOptions from '../material-theme';
import SurveyOverviewWindow from "../components/backoffice/SurveyOverviewWindow";
import OrganisationSettingsWindow from "../components/backoffice/OrganisationSettingsWindow";
import MyProfileWindow from "../components/backoffice/MyProfileWindow";
import { CurrentUser } from "@Models/CurrentUser";
import PageMeta from "@StaticQueryComponents/PageMeta";
import { HistoricalSurveyResultsSet } from "@Models/HistoricalSurveyResultsSet";
import { Organisation } from "@Models/Organisation";
import { SurveyFrequencySettings } from "@Models/SurveyFrequencySettings";
import { PrivateApiClient, staticDataApi } from '../ApiClient';
import Spinner from "@jcharlesworthuk/your-mum-frontend/components/indicators/Spinner";
import { QuestionTemplate } from "@Models/QuestionTemplate";
import { Metric } from "@Models/Metric";
import { Employee } from "@Models/Employee";
import { SurveyResults } from "@Models/SurveyResults";
import { LatestSurveyResult } from "@Models/LatestSurveyResult";
import { SurveyTemplate } from "@Models/SurveyTemplate";
import { SaveState } from "@Models/SaveState";

const baseClass = 'backoffice-app-page';
const childClass = childClassClosure(baseClass);

type Props = {
  pageContext: {
  };
}

const RouterPage = (
  props: { pageComponent: JSX.Element } & RouteComponentProps
) => props.pageComponent;


const theme = createMuiTheme(themeOptions);

const BackOfficeAppPage: React.FunctionComponent<Props> = (props: Props) => {
  const [currentUser, setCurrentUser] = useState<CurrentUser | null | 'none'>(null);
  const [initialState, setInitialState] = useState<{
    latestResult: LatestSurveyResult | null,
    organisation: Organisation,
    frequencySettings: SurveyFrequencySettings,
    surveyTemplate: SurveyTemplate,
    questions: {
      [id: string]: QuestionTemplate
    },
    metrics: {
      [id: string]: Metric
    },
    employees: Employee[]
  } | null>(null);


  useEffect(() => {
    async function loadUser() {
      const user = await Authentication.loadUserFromStorage();
      setCurrentUser(user || 'none');
      if (user) {
        await loadOrgData(user);
      }
    }
    async function loadOrgData(user: CurrentUser) {
      const apiClient = new PrivateApiClient().withUser(user);
      const organisation = await apiClient.getOrganisation();
      const { questions, metrics } = await staticDataApi.getQuestionsAndMetrics(organisation.name);
      const latestResultData = await apiClient.getLatestSurveyResult(metrics);
      let latestResult: LatestSurveyResult | null = null;
      if (latestResultData) {
        const latestSurveyToAnswer = await staticDataApi.getSurveyToAnswer(organisation.id, latestResultData.id);
        latestResult = new LatestSurveyResult(latestResultData, latestSurveyToAnswer);
      }
      const frequencySettings = await apiClient.getSurveyTemplateFrequencySettings();
      const employees = await apiClient.getEmployees();
      const surveyTemplate = await apiClient.getSurveyTemplate(metrics, questions);

      setInitialState({
        latestResult,
        frequencySettings,
        organisation,
        questions,
        metrics,
        surveyTemplate,
        employees
      })
    }
    loadUser();
  }, [])

  if (currentUser === 'none') {
    if (typeof window !== 'undefined') {
      navigate('/account/login', {
        replace: true
      });
    }
    // NOTE: it is ok for them to log in and view their dashboard even if the subscription has expired.
    // We just won't trigger surveys, they can still log in and see their data and we can prompt them to re-subscribe
    // } else if (user.subscription === 'none') {
    //   navigate('/renew', {
    //     replace: true
    //   });
  }

  if (!currentUser || !initialState) {
    return <div className={baseClass}>
      Loading...
      <Spinner />
    </div>
  }





  return (
    <div className={baseClass}>
      <PageMeta
        title='Acorde'
        route='/office'>
        <meta name="robots" content="noindex" />
      </PageMeta>
      <ThemeProvider theme={theme}>
        <LoggedInContextProvider initialState={{ currentUser: currentUser as CurrentUser }}>
          <OrganisationManagerContextProvider initialState={{ organisation: initialState.organisation }}>
            <SurveyFrequencySettingsContextProvider initialState={{ frequencySettings: initialState.frequencySettings }}>
              <QuestionsAndMetricsContextProvider initialState={{ questions: initialState.questions, metrics: initialState.metrics }}>
                <EmployeeManagerContextProvider initialState={{ employees: initialState.employees }}>
                  <LatestSurveyResultContextProvider initialState={{ latestResult: initialState.latestResult }}>
                    <HelpIndicatorsContextProvider initialState={{}}>
                      <SurveyManagerContextProvider initialState={{ template: initialState.surveyTemplate, saveState: SaveState.Unchanged }}>
                        <BackOfficeHeader />
                        <div className={childClass('content-and-menu')}>
                          <OfficeDashboardMenu className={childClass('menu')} />
                          <Router className={childClass('main')}>
                            <RouterPage path="/office/profile" pageComponent={<MyProfileWindow />} />
                            <RouterPage path="/office/dashboard" pageComponent={<DashboardWindow />} />
                            <RouterPage path="/office/analysis" pageComponent={<AnalysisWindow />} />
                            <RouterPage path="/office/surveys" pageComponent={<SurveyOverviewWindow />} />
                            <RouterPage path="/office/people" pageComponent={<PeopleSetupWindow />} />
                            <RouterPage path="/office/settings" pageComponent={<OrganisationSettingsWindow />} />
                          </Router>
                        </div>
                      </SurveyManagerContextProvider>
                    </HelpIndicatorsContextProvider>
                  </LatestSurveyResultContextProvider>
                </EmployeeManagerContextProvider>
              </QuestionsAndMetricsContextProvider>
            </SurveyFrequencySettingsContextProvider>
          </OrganisationManagerContextProvider>
        </LoggedInContextProvider>
      </ThemeProvider>
    </div>
  )
}

export default BackOfficeAppPage;
