import * as React from 'react';
import { baseClassAnd, ClassableProps } from '@jcharlesworthuk/your-mum-core/dist/functions';
import { useRef, useEffect } from 'react';

const baseClass = 'screen-demo';

type Props = ClassableProps & {
    animate: boolean;
};

const SurveyCompletionScreenDemo: React.FunctionComponent<Props> = props => {
    const objectRef = useRef<HTMLObjectElement>(null);

    useEffect(() => {
        if (props.animate && objectRef.current && objectRef.current.contentDocument && isLoaded(objectRef.current.contentDocument)) {
            animateDomElement(objectRef.current.contentDocument);
        } 
    }, [props.animate]);

    return <object width="100%" 
                className={baseClassAnd(baseClass, props)} 
                id="complete-app-svg" 
                data="/screen-demos/complete-app.svg" 
                ref={objectRef} />
}

export default SurveyCompletionScreenDemo;

function isLoaded(svg: Document) {
    return !!svg.getElementById("slidey-group");
}

export function animateDomElement(svg: Document) {
    const buttonToSelectA = svg.getElementById("button-to-select-1");
    const buttonToSelectB = svg.getElementById("button-to-select-2");
    const slideGroup = svg.getElementById("slidey-group");
    const mask = svg.getElementById("slide-mask").getElementsByTagName('rect')[0];

    buttonToSelectA.style.transition = 'fill 500ms cubic-bezier(0.34, 1.56, 0.64, 1)';
    buttonToSelectA.style.fill = '#ffffff';
    buttonToSelectB.style.transition = 'fill 500ms cubic-bezier(0.34, 1.56, 0.64, 1)';
    buttonToSelectB.style.fill = '#ffffff';

    slideGroup.style.transform = 'translate(0px, 0px)';
    slideGroup.style.transition = 'transform 500ms ease';
    mask.style.transform = 'translate(0px, 0px)';
    mask.style.transition = 'transform 500ms ease';

    function selectOptionA() {
        buttonToSelectA.style.fill = '#00bfbf';
        setTimeout(slideContentA, 500);
    }
    function selectOptionB() {
        buttonToSelectB.style.fill = '#00bfbf';
        setTimeout(slideContentB, 500);
    }

    function slideContentA() {
        mask.style.transform = 'translate(70px, 0px)';
        slideGroup.style.transform = 'translate(-70px, 0px)';
        setTimeout(selectOptionB, 1000)
    }

    function slideContentB() {
        mask.style.transform = 'translate(138px, 0px)';
        slideGroup.style.transform = 'translate(-138px, 0px)';
    }
    selectOptionA();
}